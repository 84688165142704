import { Switch, Route, Link, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import { RouteAdminUser } from "../../routes";
import NotFoundContainer from "../NotFoundContainer";

const UserDetail = () => {
    let { /* path, */ url } = useRouteMatch();
    const location = useLocation()
    const history = useHistory()

    const getRoutes = () => {
		return RouteAdminUser.map((data, key) => {
            return (
                <Route path={data.layout + data.path} component={data.component} exact={data.exact} key={key} />
            );
		});
	}

    const activePath = (path) => {
        return url + path === location.pathname? true : false
    }

    const handleChangeDropdown = (e) => {
        console.log(e.target.value)
        history.push(url + e.target.value)
    }

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>USER DETAIL</h1>
                <h2 className='text-base font-medium'>User<span className='text-xs'> / </span><span className='text-sm'>User Detail</span></h2>
            </div>
            <div className='w-full bg-white rounded-2xl shadow-2xl p-5 md:px-20 mb-5'>
                <div className='hidden sm:block'>
                    <ul className='flex items-center justify-between'>
                    {RouteAdminUser.map(({path, name}, key) => 
                        <li key={key} className='text-center font-medium' >
                            <Link to={url + path}
                                className={`${activePath(path)? 'border-lightcayn text-lightcayn': 'border-transparent'}  px-2 py-1.5 border-b-2 hover:text-lightcayn hover:border-lightcayn`}
                                >
                                <span>{name}</span>
                            </Link>
                        </li>
                    )}
                    </ul>
                </div>
                <div className=' inline-flex items-center sm:hidden'>
                    <label htmlFor='routeUser'>Showing :</label>
                    <select id='routeUser' onChange={handleChangeDropdown} className='py-2 px-3 outline-none bg-transparent border-1 border-gray rounded-lg ml-5 cursor-pointer'>
                        {RouteAdminUser.map(({path, name}, key) => 
                            <option key={key} value={path} >{name}</option>
                        )}
                    </select>
                    <ul className='bg-soft'>
                    </ul>
                </div>
            </div>
            <div className='w-full bg-white rounded-2xl shadow-2xl p-5'>
                <Switch>
                    {getRoutes()}
                    <Route path='/admin/user-detail/*' component={NotFoundContainer} />
                </Switch>
            </div>
        </div>
    )
}

export default UserDetail