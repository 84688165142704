// import Help from "./headers/Help"
// import Notifications from "./headers/Notification"
// import SearchModal from "./headers/SearchModal"
import UserMenu from "./headers/UserMenu"

const Header = () => {
    return (
        <div className='flex h-full float-right items-center z-40'>
            {/* <SearchModal /> */}
            {/* <Notifications /> */}
            {/* <Help /> */}
            {/*  Divider */}
            <hr className="w-px h-6 bg-gray-200 mx-3" />
            <UserMenu />
        </div>
    )
}

export default Header