import axios from 'axios'
import { baseURL } from './common-api'

export const getCommodityListByUser = async (userID) => {
    try {
        const data = await axios.get(baseURL + '/api/commodity/get-list-user?user_id_int=' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewCommodity = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/commodity/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateCommodity = async (commodityID, payload) => {
    try {
        const data = await axios.patch(baseURL + '/api/commodity/update/' + commodityID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteCommodity = async (commodityID) => {
    try {
        const data = await axios.delete(baseURL + '/api/commodity/delete/' + commodityID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const disableCommodity = async (commodityID) => {
    try {
        const data = await axios.delete(baseURL + '/api/commodity/disable/' + commodityID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


//#region COMMODITY-REF
export const getAllCommodityRef = async () => {
    try {
        const data = await axios.get(baseURL + '/api/commodity-ref/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneCommodityRef = async (commodityName) => {
    try {
        const data = await axios.get(baseURL + '/api/commodity-ref/get-one?commodity_name_var=' + commodityName, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewCommodityRef = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/commodity-ref/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateCommodityRef = async (commodityRefID, payload) => {
    try {
        const data = await axios.patch(baseURL + '/api/commodity-ref/update/' + commodityRefID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteCommodityRef = async (commodityRefID) => {
    try {
        const data = await axios.delete(baseURL + '/api/commodity-ref/delete/' + commodityRefID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
//#endregion