import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { connect } from "react-redux"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { createCustomerPhoto, deleteCustomerPhoto,  getOneCustomerPhoto } from "../../../api/customerphoto-api"

import { ButtonAdd, CancelButton, SaveButton } from "../../../components/button/CustomButton";
import Loader from "../../../components/modal/Loader";
import ModalMessage from "../../../components/modal/ModalMessage"
import ModalTwoButton from "../../../components/modal/ModalTwoButton"


const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3  mt-1'

const CustomerPhoto = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')
    const [modalDel, showModalDel] = useState(false)
    const [disableForm, setDisableForm] = useState(true)
    const [emptyData, showEmptyData] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [data, setData] = useState(null)

    const {id_seq} = useParams()

    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await getOneCustomerPhoto(id_seq)

        console.log(res)
        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                setDisableForm(true)
                showEmptyData(false)
                setData(res.data.data)
            }else{
                showEmptyData(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [id_seq])


    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleAddNew = () => {
        showEmptyData(false)
        setDisableForm(false)
        setIsUpdate(true)
    }


    const appendImage = async (payload) => {
        const formData = new FormData()

        await Promise.all(Object.keys(payload).map(async key => {
            if(payload[key].files){
                if(payload[key].files.length>0){
                    console.log(payload[key].name, payload[key].files)
                    for (let i = 0; i < payload[key].files.length; i++) {
                        await formData.append(payload[key].name, payload[key].files[i])
                    }
                }
            }
        }))

        return formData
    }

    const handleSubmitForm = async (e) => {
        e.preventDefault()
        showLoader(true)

        const newFormData = await appendImage(e.target)
        newFormData.append('user_id_int', id_seq)
        newFormData.append('created_by_var', user.fullname_var)

        const res = await createCustomerPhoto(newFormData)

        console.log('Create/Update Photo :', res)
        showLoader(false)
        setIsUpdate(false)
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else{
                fetchData()
                showModalErr(true)
                setMessageErr(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteData = async (e) => {
        e.preventDefault()
        showLoader(true)
        showModalDel(false)

        const res = await deleteCustomerPhoto(id_seq)
        
        console.log('Delete data Photo :', res)
        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else{
                showModalErr(true)
                setMessageErr(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }


    return (
        <div>
            <div className='flex justify-between items-center mb-6'>
                <h1 className='font-semibold text-sm'>Dokumen Foto Petani</h1>
                {!emptyData && data &&
                <span className='text-lg text-red-400 hover:text-red-900 cursor-pointer' onClick={() => showModalDel(true)}>
                    <FontAwesomeIcon icon={faTrash} />
                </span>
                }
            </div>

            {/* FORM */}
            {!emptyData? 
            <form onSubmit={handleSubmitForm} className='bg-white rounded-2xl shadow-2xl mt-5 p-6'>

                {/* Emergency Contact */}
                <div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Foto KTP</label>
                            {isUpdate?
                                <input type='file' name='ktp_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-1 mb-3'>
                                    <LazyLoadImage src={data?.ktp_var} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                </div>
                            }
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Foto KTP Pasangan</label>
                            {isUpdate?
                                <input type='file' name='spouse_ktp_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-1 mb-3'>
                                    <LazyLoadImage src={data?.spouse_ktp_var} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Foto Pribadi</label>
                            {isUpdate?
                                <input type='file' name='photos_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-1 mb-3'>
                                    <LazyLoadImage src={data?.photos_var} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                </div>
                            }
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Foto Pasangan</label>
                            {isUpdate?
                                <input type='file' name='spouse_photos_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-1 mb-3'>
                                    <LazyLoadImage src={data?.spouse_photos_var} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Foto Kartu Tani</label>
                            {isUpdate?
                                <input type='file' name='farmer_photos_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-1 mb-3'>
                                    <LazyLoadImage src={data?.farmer_photos_var} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                </div>
                            }
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Foto Kartu Keluarga</label>
                            {isUpdate?
                                <input type='file' name='kk_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-1 mb-3'>
                                    <LazyLoadImage src={data?.kk_var} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                </div>
                            }
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Foto Rumah</label>
                            {isUpdate?
                                <input type='file' multiple name='house_photos_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-2 mb-3'>
                                {data?.house_photos_var.map((image, key) => 
                                    <LazyLoadImage key={key} src={image} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                )}
                                </div>
                            }
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Foto Dokumen lainnya</label>
                            {isUpdate?
                                <input type='file' multiple name='other_doc_photos_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-2 mb-3'>
                                {data?.other_doc_photos_var.map((image, key) => 
                                    <LazyLoadImage key={key} src={image} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                )}
                                </div>    
                            }
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Foto Lahan</label>
                            {isUpdate?
                                <input type='file' multiple name='land_photos_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-2 mb-3'>
                                {data?.land_photos_var.map((image, key) => 
                                    <LazyLoadImage key={key} src={image} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                )}
                                </div>    
                            }
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Foto Komoditas</label>
                            {isUpdate?
                                <input type='file' multiple name='comodity_photo_var' className={inputText} />
                            :
                                <div className='flex flex-wrap justify-center items-center mt-2 mb-3'>
                                {data?.comodity_photo_var.map((image, key) => 
                                    <LazyLoadImage key={key} src={image} effect='blur' className='w-30 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                )}
                                </div>   
                            }
                        </div>
                    </div>
                </div>
                

                {/* END Emergency Contact */}

                {!disableForm &&
                <div className='flex justify-end mt-5'>
                    <CancelButton onClick={fetchData} type='reset' />
                    <SaveButton type='submit' />
                </div>
                }
            </form>
            
            :
            <div className='flex justify-center items-center' style={{minHeight: '50vh'}}>
                <div className='flex flex-col items-center justify-center' style={{minHeight: '50vh'}}>
                    <p className='font-medium mb-3'>Belum ada data</p>
                    <ButtonAdd onClick={handleAddNew} />
                </div>
            </div>
            }


            {loader && <Loader />}
            {modalDel && <ModalTwoButton message='Are you sure to delete this data?' onOK={handleDeleteData} onCancel={() => showModalDel(false)} />}
            {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(CustomerPhoto)