import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import { createNewProbe, disableProbe, getAllProbe, getAllProbeType, updateProbe } from "../../api/probe-api"

import { ButtonAdd } from "../../components/button/CustomButton"
import { DropdownLand } from "../../components/dropdown/SimpleDropdown"
import TableUser from "../../components/tables/Table"

import moment from "moment"
import Loader from "../../components/modal/Loader"
import ModalFormProbe from "../../components/modal/ModalFormProbe"
import ModalMessage from "../../components/modal/ModalMessage"

const ProobeManagement = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [dataProbe, setDataProbe] = useState([])
    const [dataProbeType, setDataProbeType] = useState([])
    const [filterData, setFilterData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const history = useHistory()

    const fetchData = useCallback(async () => {
        showLoader(true)

        const res = await getAllProbe()
        
        console.log('Get Probe :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataProbe(res.data.data)
                setFilterData(res.data.data)
            }else if(res.data.code === 99){
                showLoader(true)
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history])

    const fetchProbeType = useCallback(async () => {
        const res = await getAllProbeType()
        
        console.log('Get ProbeType :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataProbeType(res.data.data)
            }else if(res.data.code === 99){
                showLoader(true)
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history])

    useEffect(() => {
        fetchData()
        fetchProbeType()
    }, [fetchData, fetchProbeType])

    const handleEdit = (selectedData) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (payload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            payload.created_by_var = user.fullname_var
            res = await createNewProbe(payload)
        }else{
            payload.updated_by_var = user.fullname_var
            res = await updateProbe(selectedData.id_seq, payload)
        }

        console.log('Create/Update Probe :', res)
        
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }


    const handleDeleteData = async (id_seq) => {
        showLoader(true)

        const res = await disableProbe(id_seq)

        console.log('Delete Probe :', res)
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else if(res.data.code === 99){
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showLoader(false)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        showLoader(false)
        setIsUpdate(false)
        showModalForm(false)
        setSelectedData(null)
    }

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataProbe]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.probe_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.probe_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    dataProbeType.find(data => data.id_int === item.probe_type_id_int)?.probe_type_description_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.ip_address_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.mac_address_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.land_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    [{id: 1, status: 'Active'}, {id: -5, status: 'Inactive'}].find(data => data.id === item.status_int)?.status?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataProbe)
        }
    }

    const columns = [
        {
            Header: () => <div className='p-4 text-left'>Code</div>,
            Footer: 'Code',
            accessor: 'probe_code_var',
            Cell: ({ value }) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'probe_name_var'
        },
        {
            Header: 'Type',
            Footer: 'Type',
            accessor: 'probe_type_id_int',
            Cell: ({value}) => (
                <div>{dataProbeType.find(data => data.id_int === value)?.probe_type_description_var}</div>
            )
        },
        {
            Header: 'IP Adress',
            Footer: 'IP Adress',
            accessor: 'ip_address_var'
        },
        {
            Header: 'MAC Address',
            Footer: 'MAC Address',
            accessor: 'mac_address_var'
        },
        {
            Header: 'Land Code',
            Footer: 'Land Code',
            accessor: 'land_code_var'
        },
        {
            Header: 'Start Date',
            Footer: 'Start Date',
            accessor: 'start_on_dtm',
            Cell: ({value}) => value? moment(value, moment(value).creationData().format).format('YYYY-MM-DD HH:mm:ss') : null
        },
        {
            Header: 'End Date',
            Footer: 'End Date',
            accessor: 'end_on_dtm',
            Cell: ({value}) => value? moment(value, moment(value).creationData().format).format('YYYY-MM-DD HH:mm:ss') : null
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}) => (
                parseInt(value)===1? 
                <div className='bg-green_sidebar text-green-800 float-left px-2 py-1 text-center rounded-lg font-medium'>Active</div>
                :
                <div className='bg-red-100 text-red-800 float-left px-2 py-1 text-center rounded-lg font-medium'>Inactive</div>
            )
        },
        {
            Header: 'Antares',
            Footer: 'Antares',
            Cell: ({ row }) => {
              const data = row.original
              return (
                data?.antares_hierarchical_uri && data?.antares_access_key?  
                <div className='bg-green_sidebar text-green-800 float-left px-2 py-1 text-center rounded-lg font-medium'>Active</div>
                :
                <div className='bg-red-100 text-red-800 float-left px-2 py-1 text-center rounded-lg font-medium'>Inactive</div>
            )}
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEdit(data)} onDelete={handleDeleteData} />
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-semibold'>PROBE MANAGEMENT</h1>
                <h2 className='text-base font-medium text-right'>Admin<span className='text-xs'> / </span><span className='text-sm'>Probe Management</span></h2>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-6 mt-6'>
                <div className='mb-5'>
                    <h1 className='font-semibold'>List Probe</h1>
                </div>
                <div className='flex justify-between items-center mb-3'>
                    <ButtonAdd onClick={() => showModalForm(true)} />
                    <input type='search' onChange={handleSearch} placeholder='Cari Probe' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalFormProbe data={selectedData} probeType={dataProbeType} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (ProobeManagement)