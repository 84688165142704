import { useCallback, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import moment from "moment"
import { connect } from "react-redux"
import { useForm } from "react-hook-form";

import { createNewCommodity, deleteCommodity, getAllCommodityRef, getCommodityListByUser, updateCommodity } from "../../../api/commodity-api"
import { getLandListByUser } from "../../../api/landarea-api"

import ErrorField from "../../../components/ErrorField"
import { ButtonAdd, CancelButton, SaveButton } from "../../../components/button/CustomButton";
import { DropdownLand } from "../../../components/dropdown/SimpleDropdown";

import ModalMessage from "../../../components/modal/ModalMessage"
import Loader from "../../../components/modal/Loader";

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3  mt-1'

const Commodity = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')
    
    const [isUpdate, setIsUpdate] = useState(false)
    const [formAdd, showFormAdd] = useState(false)

    const [dataLand, setDataLand] = useState([])
    const [dataCommodity, setDataCommodity] = useState([])
    const [selectedData, setSelectedData] = useState({})
    const [dataCommodityRef, setDataCommodityRef] = useState([1])

    const history = useHistory()
    const {id_seq} = useParams()

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        reset
    } = useForm({ criteriaMode: "all" });

    const fetchData = useCallback(async () => {
        showLoader(false)
        const res = await getCommodityListByUser(id_seq)

        console.log(res.data)
        resetContainer()
        if(res.data){
            if(res.data.code === 0){
                setDataCommodity(res.data.data)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                history.replace('/admin/user')
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [id_seq, history])

    const fetchCommodityStandard = useCallback(async ()=> {
        const res = await getAllCommodityRef()

        console.log(res.data)
        if(res.data){
            if(res.data.code === 0){
                setDataCommodityRef(res.data.data)
                fetchData()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                history.replace('/admin/user')
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history, fetchData])

    const fetchLand = useCallback(async ()=> {
        const res = await getLandListByUser(id_seq)

        if(res.data){
            if(res.data.code === 0){
                setDataLand(res.data.data)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                history.replace('/admin/user')
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history, id_seq])


    useEffect(() => {
        fetchCommodityStandard()
        fetchLand()
    }, [fetchCommodityStandard, fetchLand])


    const handleNewData = () => {
        setIsUpdate(false)
        setSelectedData(false)
        showFormAdd(true)
        reset()
    }

    const handleEditData = async (data) => {
        await Promise.all(Object.keys(data).map(async key => {
            if(key === 'start_planting_dtm'){
                return setValue(key, moment(data[key]).format('YYYY-MM-DD'))
            } 
            return setValue(key, data[key], { shouldValidate: true })
        }))

        setSelectedData(data)
        setIsUpdate(true)
        showFormAdd(true)
    }

    
    const onValidForm = (dataForm) => {
        showLoader(true)
        const {
            land_code_var, commodity_name_var, commodity_type_var, start_planting_dtm, period_plant_int, harvest_prediction_int, pest_potential_var, disease_potential_var
        } = dataForm

        const payload = {
            land_code_var, commodity_name_var, commodity_type_var, start_planting_dtm, period_plant_int, harvest_prediction_int, pest_potential_var, disease_potential_var
        }

        submitDataToAPI(payload)
    }
    
    const submitDataToAPI = async (payload) => {
        let res = null
        payload.user_id_int = id_seq
        if(!isUpdate){
            payload.created_by_var = user.fullname_var
            res = await createNewCommodity(payload)
        }else{
            payload.updated_by_var = user.fullname_var
            res = await updateCommodity(selectedData.id_seq, payload)
        }

        console.log('Create/Update Commo :', res)
        if(res.data){
            if(res.data.code === 0){
                // fetchData()
                if(isUpdate) window.location.reload()
                else fetchCommodityStandard()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    
    const handleDeleteData = async (id_seq) => {
        showLoader(true)
        const res = await deleteCommodity(id_seq)
        
        console.log("Delete Commodity :", res)
        resetContainer()
        if(res.data){
            if(res.data.code === 0){
                showLoader(true)
                window.location.reload()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetContainer = () => {
        setIsUpdate(false)
        showLoader(false)
        showFormAdd(false)
    }

    return (
        <div>
            <div className='flex justify-between items-center mb-6'>
                <h1 className='font-semibold text-sm'>Data Komoditas</h1>
                {dataCommodity.length > 0 && !isUpdate && !formAdd &&
                    <ButtonAdd onClick={handleNewData} />
                }
            </div>

            {/* FORM */}
            {!formAdd ?
            dataCommodity.length >0?
                dataCommodity.map((data, key) => (
                <div className='p-6 bg-white rounded-2xl shadow-2xl mb-5' key={key}>
                    <div className='flex justify-between items-center mb-4 pb-1 border-b-1 border-gray-300'>
                        <h1 className='font-semibold'>Detail Komoditas {key + 1}</h1>
                        <DropdownLand data={data} onEdit={() => handleEditData(data)} onDelete={handleDeleteData} />
                    </div>
                    <form>
                        <fieldset disabled={true}>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Lahan</label>
                                    <input type='text' name='land_code_var' defaultValue={`[${data.land_code_var}] ${dataLand.find(({land_code_var}) => land_code_var===data.land_code_var)?.land_name_var}`} className={inputText} />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Nama Komoditas</label>
                                    <input type='text' name='commodity_name_var' defaultValue={dataCommodityRef.find(dataRef => dataRef.commodity_name_var === data.commodity_name_var)?.commodity_name_var} className={inputText} />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Jenis Varietas</label>
                                    <input type='text' name='commodity_type_var' defaultValue={data.commodity_type_var} className={inputText} />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Mulai Tanam</label>
                                    <input type='date' name='start_planting_dtm' defaultValue={moment(data.start_planting_dtm).format('YYYY-MM-DD')} className={inputText}  />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Periode Tanam</label>
                                    <input type='number' name='period_plant_int' defaultValue={data.period_plant_int} className={inputText} />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Perhitungan Prediksi Panen (Bulan)</label>
                                    <input type='number' name='harvest_prediction_int' defaultValue={data.harvest_prediction_int} className={inputText} />
                                </div>
                            </div> 
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Potensi Hama</label>
                                    <input type='text' name='pest_potential_var' defaultValue={data.pest_potential_var} className={inputText} />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Potensi Penyakit</label>
                                    <input type='text' name='disease_potential_var' defaultValue={data.disease_potential_var} className={inputText} />
                                </div>
                            </div>
                        </fieldset>
                    
                    </form>
                </div>
                ))
                :
                <div className='flex flex-col items-center justify-center' style={{minHeight: '50vh'}}>
                    <p className='font-medium mb-3'>Belum ada data komoditas</p>
                    <ButtonAdd onClick={() => showFormAdd(true)} />
                </div>
            :
                <div className='mt-5'>
                    <form onSubmit={handleSubmit(onValidForm)}>
                    <h2 className='font-semibold mb-5'>{isUpdate? 'Ubah Komoditas' : 'Tambah Komoditas Baru'}</h2>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Lahan</label>
                                <select {...register("land_code_var", { required: "This field is required."})} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                    <option value=''>- Pilih Lahan -</option>
                                    {dataLand?.map((data, key) => (
                                        <option key={key} value={data.land_code_var}>[{data.land_code_var}] {data.land_name_var}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='land_code_var' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Nama Komoditas</label>
                                <select {...register("commodity_name_var", { required: "This field is required."})} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                    <option value=''>- Pilih Komoditas Standard -</option>
                                    {dataCommodityRef.map((data, key) => (
                                        <option key={key} value={data.commodity_name_var}>{data.commodity_name_var}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='commodity_name_var' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Jenis Varietas</label>
                                <input type='text' className={inputText} {...register("commodity_type_var", { required: 'This field is required'})} placeholder='C2 (Citrona 2 Agribun...' />
                                <ErrorField errors={errors} name='commodity_type_var' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Mulai Tanam</label>
                                <input type='date' className={inputText} {...register("start_planting_dtm", { required: 'This field is required'})} />
                                <ErrorField errors={errors} name='start_planting_dtm' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Periode Tanam</label>
                                <input type='number' className={inputText} {...register("period_plant_int", { required: 'This field is required'})} placeholder='' />
                                <ErrorField errors={errors} name='period_plant_int' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Perhitungan Prediksi Panen (Bulan)</label>
                                <input type='number' className={inputText} {...register("harvest_prediction_int", { required: 'This field is required'})} placeholder='6' />
                                <ErrorField errors={errors} name='harvest_prediction_int' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Potensi Hama</label>
                                <input type='text' name='pest_potential_var' className={inputText} placeholder='Potensi Hama' {...register("pest_potential_var")} />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Potensi Penyakit</label>
                                <input type='text' name='disease_potential_var' className={inputText} placeholder='Potensi Penyakit' {...register("disease_potential_var")} />
                            </div>
                        </div> 
                
                    <div className='flex justify-end mt-8'>
                        <CancelButton type='reset' onClick={resetContainer} />
                        <SaveButton type='submit' />
                    </div>
                </form>    
            </div>
            }

            {loader && <Loader />}
            {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
            {/* {dataCommodityRef.length===0 && <ModalMessage message='Data komoditas standar kosong, isi data komoditas standar terlebih dahulu' onClose={() => history.replace('/admin/commodity-standard')} />} */}
        </div>
    )
}


const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(Commodity)