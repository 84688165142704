import axios from 'axios'
import { baseURL } from './common-api'


export const createEditCustomerDetail = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/profile/update', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getAllCustomerDetail = async () => {
    try {
        const data = await axios.get(baseURL + '/api/profile/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneCustomerDetail = async (userID) => {
    try {
        const data = await axios.get(baseURL + '/api/profile/get-one?user_id_int=' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteCustomerDetail = async (userID) => {
    try {
        const data = await axios.delete(baseURL + '/api/profile/delete?user_id_int=' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}