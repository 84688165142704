import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { useForm } from "react-hook-form";
import { faEdit, faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LazyLoadImage } from "react-lazy-load-image-component"

import {setUserData} from '../reduxs/action/actions'
import { updateUser } from "../api/user-api"
import Helper from "../helpers/helper"

import ErrorField from "../components/ErrorField"
import { CancelButton, SaveButton } from "../components/button/CustomButton"
import ModalMessage from "../components/modal/ModalMessage"
import ModalTwoButton from "../components/modal/ModalTwoButton"
import ModalFormChangePassword from "../components/modal/ModalFormChangePassword"
import Loader from "../components/modal/Loader";

const BG_AVATAR = ['152e4d', '0891b2', '2E8B57', '8B4513', '4B0082', '999']

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3  mt-1'


//create your forceUpdate hook
function useForceUpdate(){
    // eslint-disable-next-line
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update the state to force render
}


const Profile = ({user, setUserData}) => {
    const [isUpdate, setIsUpdate] = useState(false)
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessagerErr] = useState(null)
    const [modalLogout, showModalLogout] = useState(false)
    const [modalPassword, showModalPassword] = useState(false)

    // eslint-disable-next-line
    const forceUpdate = useForceUpdate();

    const history = useHistory()

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        clearErrors 
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback ((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if(user){
            setdefaultValue(user)
        }
    }, [user, setdefaultValue])

    
    const onValid = async (payload) => {
        showLoader(true)

        const data = await Helper.validateForm(payload, true)

        data.updated_by_var = user.fullname_var
        const res = await updateUser(user.id_seq, data)
        
        console.log('Update User :', res)
        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                setIsUpdate(false)
                setMessagerErr(null)
                setUserData(res.data.data)
            }else{
                setMessagerErr(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleReset = (e) => {
        setIsUpdate(false)
        setMessagerErr(null)
        setdefaultValue(user)
        clearErrors()
    }

    const handleSubmitChangePassword = async (payload) => {
        showLoader(true)

        payload.updated_by_var = user.fullname_var
        const res = await updateUser(user.id_seq, payload)
        
        console.log('Update password :', res)
        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                showModalPassword(false)
                setUserData(res.data.data)
            }else{
                setMessagerErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleGoToAgroobotPage = () => {
        window.open('https://agroobot.com','_blank')
    }

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>PROFILE</h1>
            </div>

            <div>
                <div className='flex flex-col md:flex-row justify-between items-center'>
                    <div className='flex flex-col md:flex-row items-center mb-1'>
                        <span className='w-24 sm:w-30'>
                            <LazyLoadImage src={`https://ui-avatars.com/api/?name=${user.fullname_var}&background=${BG_AVATAR[Math.floor(Math.random() * BG_AVATAR.length)]}&color=fff&rounded=true&size=128`} effect='blur' />
                        </span>
                        <div className='md:ml-5 flex flex-col items-center md:items-start text-center md:text-left'>
                            <h1 className='font-bold text-xl sm:text-2xl'>{user.fullname_var}</h1>
                            <h2 className='text-sm'>{user.address_var}</h2>
                        </div>
                    </div>
                    <div className='inline-flex text-sm'>
                        <span><FontAwesomeIcon icon={faUser} /></span>
                        <p className='ml-2'>{Helper.getUserRole(user.user_group_id_int)}</p>
                    </div>
                </div>

                <div className='bg-white rounded-2xl shadow-2xl p-6 mt-6'>
                    <div className='flex justify-between items-center mb-6'>
                        <h1 className='font-semibold'>Detail Akun</h1>
                        <span className={`${isUpdate && 'text-lightcayn'} hover:text-lightcayn cursor-pointer`} onClick={() => setIsUpdate(!isUpdate)}><FontAwesomeIcon icon={faEdit} /></span>
                    </div>
                    <form onSubmit={handleSubmit(onValid)} onReset={handleReset} id='form-user'>
                        <fieldset disabled={!isUpdate}>

                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Nama Lengkap</label>
                                <input type='text' className={inputText} 
                                    {...register("fullname_var", {
                                        required: "Fullname is required."
                                    })}
                                />
                                <ErrorField errors={errors} name='fullname_var' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Alamat</label>
                                <textarea name='address_var' className={`${inputText} w-full bg-transparent`} 
                                    {...register("address_var")}
                                />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>No Handphone</label>
                                <input type='tel' className={inputText} 
                                    {...register("phone_number_int", {
                                        required: "Phone number is required.",
                                        pattern: { value: /^\d+$/, message: "Phone number is number only." },
                                        minLength: { value: 10, message: "Phone number must exceed 9 characters." },
                                        maxLength: { value: 14, message: "Phone number too long." }
                                    })}
                                />
                                <ErrorField errors={errors} name='phone_number_int' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Email</label>
                                <input type='email' name='email_var' className={`${inputText} w-full bg-transparent`} 
                                    {...register("email_var", {
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address."
                                        }
                                    })}
                                />
                                <ErrorField errors={errors} name='email_var' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row sm:pr-10 mb-3'>
                            <div className={containerInput}>
                                <label>No KTP</label>
                                <input type='number' className={`${inputText} w-full bg-transparent`} 
                                    {...register("ktp_var", {
                                        pattern: { value: /\d+/, message: "NIK is number only." },
                                        minLength: { value: 16, message: "NIK length must 16 characters." },
                                        maxLength: { value: 16, message: "NIK length must 16 characters." }
                                    })}
                                />
                                <ErrorField errors={errors} name='ktp_var' />
                            </div>
                        </div>

                        {isUpdate && messageErr && <p className='text-red-500 mt-3 mb-3'>* {messageErr}</p>}


                        {isUpdate &&
                        <div className='flex justify-end mt-5 md:mt-3'>
                            <CancelButton type='reset' onClick={handleReset} />
                            <SaveButton type='submit' />
                        </div>
                        }

                        </fieldset>
                    </form>

                </div>

                <div className='bg-white rounded-2xl shadow-2xl p-6 mt-6'>
                    <h1 className='font-semibold mb-6'>Bantuan</h1>
                    <div className='flex flex-col sm:flex-row justify-between items-start sm:items-center'>
                        <button className='hover:bg-lightcayn hover:text-white rounded-2xl px-4 py-2 font-medium' onClick={()=>showModalPassword(true)}>Ubah Password</button>
                        <button className='hover:bg-lightcayn hover:text-white rounded-2xl px-4 py-2 font-medium' onClick={handleGoToAgroobotPage}>Hubungi Kami</button>
                        <button className='hover:bg-lightcayn hover:text-white rounded-2xl px-4 py-2 font-medium' onClick={handleGoToAgroobotPage}>FAQ</button>
                        <button className='hover:bg-lightcayn hover:text-white rounded-2xl px-4 py-2 font-medium' onClick={handleGoToAgroobotPage}>Informasi</button>
                        <button className='hover:bg-lightcayn hover:text-white rounded-2xl px-4 py-2 font-medium' onClick={() => showModalLogout(true)}>Logout</button>
                    </div>
                </div>

                <div className='flex justify-center mt-8 mb-3'>Agroobot v.2.0.0</div>
            </div>

            {modalPassword && <ModalFormChangePassword onCancel={() => showModalPassword(false)} onSubmit={handleSubmitChangePassword} />}
            {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
            {loader && <Loader />}
            {modalLogout && <ModalTwoButton message='Are you sure to logout?' onOK={() => history.replace('/auth')} onCancel={() => showModalLogout(false)} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}


const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({setUserData}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)