import { useEffect, useCallback, useState } from "react";

import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { getOneUser } from "../../api/user-api";

import UserDetail from "../../containers/admin/UserDetail";
import MonitoringDetail from "../../containers/petani/MonitoringDetail";

import Header from "../Header";
import Sidebar from "../Sidebar";

import { setUserData } from "../../reduxs/action/actions";
import Helper from "../../helpers/helper";
import Loader from "../modal/Loader";
import ModalMessage from "../modal/ModalMessage";
import NotFoundContainer from "../../containers/NotFoundContainer";
import RecommendationDetail from "../../containers/petani/RecommendationDetail";
import LandDetail from "../../containers/petani/LandDetail";

function Home({setUserData}) {
	const [loader, showLoader] = useState(true)
	const [modalErr, showModalErr] = useState(false)
	const [messageErr, setMessageErr] = useState(false)
	const [dataUser, setData] = useState(null)
	const history = useHistory()

	const getUser = useCallback(async (token, phoneNumber) => {
		showLoader(true)
		const res = await getOneUser(token, phoneNumber)

		console.log(res)
		showLoader(false)
		if(res.data){
			if(res.data.code === 0){
				localStorage.setItem('agroo-user', JSON.stringify(res.data.data))
				setUserData(res.data.data)
				setData(res.data.data)
			}else if(res.data.code === 99 || res.data.code === 102){
				showLoader(true)
				history.replace('/auth')	
			}else{
				setMessageErr(res.data.message)
				showModalErr(true)
			}
		}else{
			getUser(phoneNumber)
		}
	}, [history, setUserData])

	useEffect(() => {
		showLoader(true)
        const token = localStorage.getItem('agroo-token')
		if(!token){
			history.replace('/auth')
		}else{
			const decode = jwtDecode(token)
			// console.log(decode)
            const currentTime = Date.now() / 1000;
			if(decode.exp < currentTime){
				localStorage.clear()
                history.replace('/auth')
			}else{
				getUser(token, decode.phone_number_int)
            }
		}

	}, [getUser, history])


    // MAIN ROUTE
	const getRoutes = () => {
		return Helper.getMainRoutes(dataUser?.user_group_id_int).map(data => {
			return data.routes.map(({layout, path, component, exact}, key) => {
				return <Route path={layout + path} component={component} exact={exact} key={key} />
			})
		})
	}


	return (
		<div className='flex flex-col md:flex-row h-screen overflow-hidden font-poppins text-xs'>
			<Sidebar />
			<div className='bg-soft w-full overflow-auto h-screen flex flex-col'>
				
				{/* HEADER */}
				<div className='hidden md:block h-12 bg-white py-8 px-4 sm:px-6 lg:px-8 shadow sticky top-0 z-10'>
					<Header />
				</div>
				
				{/* DASHBOARD CONTENT */}
                <Switch>
                    {getRoutes()}
					
					{ dataUser && dataUser.user_group_id_int === 1 && 
						<Route path='/admin/user-detail/:id_seq' component={UserDetail} />
					}
					{ dataUser && dataUser.user_group_id_int === 2 && 
						<Route path='/admin/user-detail/:id_seq' component={UserDetail} />
					}

					{dataUser && (dataUser.user_group_id_int === 4 || dataUser.user_group_id_int === 1) &&
						<Route path='/admin/lahan-monitoring/:land_code_var' component={MonitoringDetail} />
					}
					{dataUser && dataUser.user_group_id_int === 4 &&
						<Route path='/admin/lahan-recommendation/:land_code_var' component={RecommendationDetail} />
					}
					{dataUser && dataUser.user_group_id_int === 4 &&
						<Route path='/admin/lahan-detail/:land_code_var' component={LandDetail} />
					}

                    <Route path='*' component={NotFoundContainer} /> 
                    <Redirect from="*" to="/admin/page-not-found" />
                </Switch>
				
				{/* FOOTER */}
				<div className='flex items-center justify-between bg-white p-5 sm:px-6 lg:px-8 border-t-2 border-l-2 border-r-2 border-gray-200 rounded-t-2xl text-xss md:text-xs'>
					<p>Design & Develop by Agroobot Dev Team</p>
					<p>{new Date().getFullYear()} © Agroobot v2.0</p>
				</div>

			</div>
			
			{loader && <Loader />}
			{modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
		</div>
	);
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({setUserData}, dispatch)
}

export default connect(null, mapDispatchToProps)(Home);