import { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useForm } from "react-hook-form";

import { authRegister } from "../../api/common-api"

import ErrorField from "../../components/ErrorField"
import Helper from "../../helpers/helper"
import Loader from "../../components/modal/Loader";
import logo from '../../assets/img/logo.svg'
import profile from '../../assets/img/profile-img.png'

const Register = () => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')

    const history = useHistory()

    const {
        register,
        formState: { errors },
        handleSubmit
    } = useForm({ criteriaMode: "all" });
    
    const onValid = (payload) => {
        const {phone_number_int, password_var, retype_password} = payload
        
        if(password_var !== retype_password){
            setMessageErr('Password not match')
            showModalErr(true)
            return
        }

        showLoader(true)

        delete payload['retype_password']
        payload['phone_number_int'] = Helper.IDPhoneNumber(phone_number_int)
        
        postRegister(payload)
    }
    
    const postRegister = async (payload) => {
        const res = await authRegister(payload)

        console.log(res)
        showLoader(false)

        if(res.data){
            if(res.data.code === 0){
                // await localStorage.setItem('agroo-regist', JSON.stringify(payload))
                // await localStorage.setItem('agroo-otpid', res.data.data?.otp?.data?.id)
                await Helper.asyncLocalStorage.setItem('agroo-regist', JSON.stringify(payload))
                await Helper.asyncLocalStorage.setItem('agroo-otpid', res.data.data?.otp?.data?.id)
                history.push('/auth/otp-validate')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            setMessageErr('Connection timeout')
            showModalErr(true)
        }
    }

    return (
        <div className='w-11/12 md:w-1/3 2xl:w-1/4 py-10'>
            <div className='bg-white rounded shadow-xl'>
                <div className='bg-lightcayn bg-opacity-20 flex justify-between rounded-t w-full'>
                    <div className='p-5 rounded-t-2xl text-lightcayn mb-5'>
                        <h2 className='mb-1 font-medium'>Free Register</h2>
                        <p className='text-sms'>Get your free Agroobot account now.</p>
                    </div>
                    <div className='flex justify-end'>
                        <LazyLoadImage effect='blur' src={profile} className='w-44' />
                    </div>
                </div>
                <div className='bg-darkcayn rounded-full p-3 w-17 h-17 -mt-7 ml-5'>
                    <LazyLoadImage effect='blur' src={logo} />
                </div>

                {modalErr &&<div className='px-5 py-4 rounded mx-6 bg-red-100 text-red-800 text-left text-sms mt-6 -mb-4'>{messageErr}</div>}

                <form onSubmit={handleSubmit(onValid)} className='p-6 flex flex-col items-center text-sms font-medium text-gray-600'>
                    <div className='w-full flex flex-col mb-4 mt-2'>
                        <label>Full Name</label>
                        <input type='text' placeholder='Full Name' className='mt-1.5 p-2.5 outline-none border-1 border-gray-300 rounded' 
                            {...register("fullname_var", {
                                required: "Fullname is required."
                            })}
                        />
                        <ErrorField errors={errors} name='fullname_var' />
                    </div>
                    <div className='w-full flex flex-col mb-4'>
                        <label>Phone Number</label>
                        <input type='tel' placeholder='081234567890' className='mt-1.5 p-2.5 outline-none border-1 border-gray-300 rounded' 
                            {...register("phone_number_int", {
                                required: "Phone number is required.",
                                pattern: { value: /^\d+$/, message: "Phone number is number only." },
                                minLength: { value: 10, message: "Phone number must exceed 9 characters." },
                                maxLength: { value: 14, message: "Phone number too long." }
                            })}
                        />
                        <ErrorField errors={errors} name='phone_number_int' />
                    </div>
                    <div className='w-full flex flex-col mb-4'>
                        <label>Password</label>
                        <input type='password' placeholder='xxxxx' className='mt-1.5 p-2.5 outline-none border-1 border-gray-300 rounded' 
                            {...register("password_var", {
                                required: "Password is required.",
                                minLength: { value: 5, message: "Password must exceed 4 characters."}
                            })}
                        />
                        <ErrorField errors={errors} name='password_var' />
                    </div>
                    <div className='w-full flex flex-col mb-6'>
                        <label>Re-type Password</label>
                        <input type='password' placeholder='xxxxx' className='mt-1.5 p-2.5 outline-none border-1 border-gray-300 rounded' 
                            {...register("retype_password", {
                                required: "Re-type password is required."
                            })}
                        />
                        <ErrorField errors={errors} name='retype_password' />
                    </div>
                    <button type='submit' className='bg-lightcayn hover:bg-darkcayn text-white w-full py-2.5 rounded mb-6 text-sms'>
                        Register
                    </button>
                    <p className='cursor-pointer text-gray-600 font-normal mb-1 text-center'>By registering you agree to the Agroobot {' '}<span className='text-lightcayn hover:text-darkcayn'>Terms of Use</span></p>
                </form>
            </div>
            <div className='text-sms text-gray-600 flex flex-col items-center mt-7'>
                <p>Already have an account ? {' '}
                    <span className='text-lightcayn hover:text-darkcayn font-medium'>
                        <Link to='/auth/sign-in'>
                            Sign In
                        </Link>
                    </span>
                </p>
                <p className='mt-3'>© {new Date().getFullYear()} Agroobot v2.0</p>
            </div>
            
            {loader && <Loader />}
        </div>
        // <div className='w-full md:w-9/12 md:mt-0 flex flex-col md:flex-row justify-between items-center'>
        //     <div className='hidden md:flex items-center justify-center w-36 h-36 md:w-96 md:h-96'>
        //         Apps Logo
        //     </div>
        //     <form onSubmit={handleSubmit} className='w-11/12 md:w-96 p-6 rounded-2xl shadow-2xl flex flex-col items-center text-sm md:text-md'>
        //         <h1 className='font-bold text-darkcayn text-xl md:text-2xl md:mb-1'>Sign Up</h1>
        //         <p className='text-center'>Already have an account?{' '}
        //             <span className='text-lightcayn'>
        //                 <Link to='/auth/sign-in'>
        //                     Sign In
        //                 </Link>
        //             </span>
        //         </p>
        //         <div className='w-full flex flex-col mt-5'>
        //             <label>Full Name</label>
        //             <input type='text' name='fullname_var' placeholder='User Fullname' required className='p-2 outline-none border-1 border-gray-200 rounded-md' />
        //         </div>
        //         <div className='w-full flex flex-col my-5'>
        //             <label>Phone Number</label>
        //             <input type='text' name='phone_number_int' placeholder='081234567890' required className='p-2 outline-none border-1 border-gray-200 rounded-md' />
        //         </div>
        //         <div className='w-full flex flex-col mb-5'>
        //             <label>Password</label>
        //             <input type='password' name='password_var' placeholder='xxxxx' required className='p-2 outline-none border-1 border-gray-200 rounded-md' />
        //         </div>
        //         <div className='w-full flex flex-col mb-8'>
        //             <label>Re-type Password</label>
        //             <input type='password' name='retype_password' placeholder='xxxxx' required className='p-2 outline-none border-1 border-gray-200 rounded-md' />
        //         </div>
        //         <button type='submit' className='bg-lightcayn text-md md:text-base text-white w-full py-2 rounded-md mb-2'>
        //             Register
        //         </button>
        //     </form>
        // </div>
    )
}

export default Register