import { faCalendarAlt, faExpandArrowsAlt, faMapMarkerAlt, faPlus, faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { MapContainer, Marker, Polygon, Popup, TileLayer } from 'react-leaflet';
import { useHistory } from "react-router-dom";


import { createLandCommodity, disableLand, getDetailLandListByUser, getOneLandCommodity, updateLandCommodity } from "../../api/landarea-api";
import Helper from "../../helpers/helper";

import logo from '../../assets/img/logo.svg';
import { DropdownLahan } from "../../components/dropdown/DropdownLahan";
import Loader from "../../components/modal/Loader";
import ModalFormLand from "../../components/modal/ModalFormLand";
import ModalMessage from "../../components/modal/ModalMessage";

const LandFarmer = () => {
	const [loader, showLoader] = useState(true)
	const [modalErr, showModalErr] = useState(false)
	const [messageErr, setMessageErr] = useState('')
	const [modalAdd, showModalAdd] = useState(false)
	const [dataLand, setDataLand] = useState([])
	const [selectedLand, setSelectedLand] = useState(null)
	const [isUpdate, setIsUpdate] = useState(false)

	const history = useHistory()
	const user = JSON.parse(localStorage.getItem('agroo-user'))

	useEffect(() => {
		async function fetchLand(){
			const res = await getDetailLandListByUser(user.id_seq)

			console.log('get detail land list :', res)
			if(res.data){
				if(res.data.code === 0){
					setDataLand(res.data.data)
					showLoader(false)
				}else{
					setMessageErr(res.data.message)
					showModalErr(true)
					showLoader(false)
				}
			}else{
				alert('Connection timeout...')
				fetchLand()
			}
		}

		fetchLand()
	}, [user.id_seq])


	const handleEditData = async (data) => {
		const res = await getOneLandCommodity(data.land_code_var)
		
		console.log('get one land commodity :', res)
		if(res.data){
			if(res.data.code === 0){
				setSelectedLand(res.data.data)
				setIsUpdate(true)
				showModalAdd(true)
				showLoader(false)
			}else{
				setMessageErr(res.data.message)
				showModalErr(true)
				showLoader(false)
			}
		}else{
			alert('Connection timeout...')
		}
	}

	const handleSubmitDataForm = async (payload) => {
		showLoader(true)
		console.log(payload)
		payload.project_code_var = '01'
		payload.user_id_int = user.id_seq
		
		let res = null
		if(!isUpdate){
			payload.created_by_var = user.fullname_var
			res = await createLandCommodity(payload)
		}else{
			payload.updated_by_var = user.fullname_var
			res = await updateLandCommodity(selectedLand.land_code_var, payload)
		}

		console.log('create/update land commodity :', res)
		if(res.data){
			if(res.data.code === 0){
				window.location.reload()
			}else{
				setMessageErr(res.data.message)
				showModalErr(true)
				showLoader(false)
			}
		}else{
			alert('Connection timeout...')
		}
	}

	const handleDeleteData = async (data) => {
		console.log(data)
		const res = await disableLand(data.id_seq)

		console.log('disable land :', res)
		if(res.data){
			if(res.data.code === 0){
				window.location.reload()
			}else{
				setMessageErr(res.data.message)
				showModalErr(true)
				showLoader(false)
			}
		}else{
			alert('Connection timeout...')
		}
	}

	const getZoom = (areaWidth) => {
		const width = parseFloat(areaWidth)
		if(width < 3){
			return 17
		}else if(width < 15){
			return 16
		}else if(width < 30){
			return 15
		}else if(width < 60){
			return 14
		}else if(width < 153){
			return 13
		}else if(width < 750){
			return 13
		}else if(width < 5000){
			return 11
		}else if(width < 25000){
			return 10
		}else if(width < 250000){
			return 9
		}else if(width < 1500000){
			return 8
		}else{
			return 7
		}
	}

    return (
        <div className='flex item-center flex-col mb-auto px-4 py-6 md:p-6'>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>LAHAN</h1>
                <h2 className='text-base font-medium'>Menu<span className='text-xs'> / </span><span className='text-sm'>Lahan</span></h2>
            </div>

            <h1 className='font-medium mb-3 text-center text-sm'>Pilih Lahan</h1>

			{dataLand.length > 0 &&
			<button onClick={()=>showModalAdd(true)} className='flex items-center font-medium border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-1 hover:bg-green-700 bg-white hover:text-white hover:border-transparent fixed bottom-16 md:bottom-20 right-6 z-10'>
				<p className='mr-3'>Tambah Lahan</p>
				<span className='text-white bg-green-700 hover:bg-green-400 rounded-full p-1 w-6 h-6 flex items-center justify-center cursor-pointer text-base'>
					<FontAwesomeIcon icon={faPlus} />
				</span>
			</button>
			}
			
			{dataLand.length > 0?
            <div className='md:grid lg:grid-cols-3 2xl:grid-cols-4'>

            {dataLand.map((data, key) => 
                <div className={`mb-5 bg-white rounded-2xl shadow-xl w-full lg:w-80`} key={key}>
                    <div className='flex items-center justify-between w-full relative'>
						<div className='absolute top-2 right-2' style={{zIndex: 1}}>
							<DropdownLahan 
								data={data} 
								onDetail={()=>history.push(`/admin/lahan-detail/${data.land_code_var}`)} 
								onEdit={()=>handleEditData(data)} onDelete={handleDeleteData} 
							/>
						</div>
						<MapContainer 
							center={Helper.getCenterMapFromStr(data.land_coordinates_txt)} 
							zoom={getZoom(data.area_var)} 
              				dragging={false}
							scrollWheelZoom={false} className='w-full rounded-t-2xl h-36 z-0'>
							<TileLayer
								attribution='by Agroobot'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								// maxZoom={20} id='' tileSize={512} zoomOffset={-1}
								// url='https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA'
							/>
							<Marker position={Helper.getCenterMapFromStr(data.land_coordinates_txt)} icon={Helper.getMapIcon()}>				{/* kiri atas */}
								<Popup>
									{data.land_name_var}
								</Popup>
							</Marker>

							<Polygon pathOptions={{color: 'lime'}} positions={Helper.generatelatLngFromStr(data.land_coordinates_txt)} />
						</MapContainer>
                    </div>
					<div className='p-5'>
						<div className='flex border-t-2 border-gray-300 pt-4 relative'>
							<div className='bg-header rounded-full p-2 w-17 h-17 absolute'>
								<LazyLoadImage effect='blur' src={logo} />
							</div>
							<div className='ml-20'>
								<h2 className='font-semibold'>{data.land_name_var} ({data.project_name_var})</h2>
								<h3 className='font-medium my-1'><span className='mr-1'><FontAwesomeIcon icon={faMapMarkerAlt} /></span> {data?.address_land_txt}</h3>
								<div className='flex text-tag font-semibold mb-5 mt-2'>
									<p className='mr-3 bg-gray-200 rounded-md px-2 py-1'><span><FontAwesomeIcon icon={faCalendarAlt} /></span> {data.land_code_var}</p>
									<p className='bg-gray-200 rounded-md px-2 py-1'><span><FontAwesomeIcon icon={faExpandArrowsAlt} /></span> {data.area_var} Ha</p>
								</div>
								<div className='-ml-5 -mr-10'>
									<button className='bg-green-600 hover:bg-green-400 text-white py-2 px-3 rounded-2xl' onClick={()=>history.push(`/admin/lahan-monitoring/${data.land_code_var}`)}>
										Monitoring <span><FontAwesomeIcon icon={faStreetView} /></span>
									</button>
									{/* <button className='ml-2.5 bg-green-900 hover:bg-green-400 text-white py-2 px-3 rounded-2xl' onClick={()=>history.push(`/admin/lahan-recommendation/${data.land_code_var}`)}>
										Rekomendasi <span><FontAwesomeIcon icon={faDiagnoses} /></span>
									</button> */}
								</div>
							</div>
						</div>
					</div>

                </div>
            )}
            
            </div>
			:
			<div className='w-full h-100 flex flex-col justify-center items-center text-center'>
				<p>Ups... Nampaknya kamu belum memiliki lahan yang terdaftar,</p>
				<p>silakan daftarkan lahanmu pada Aplikasi Agroobot terlebih dahulu !</p>
				<button className='mt-2 border-1 border-lightcayn rounded-2xl px-3 py-2 text-lightcayn hover:bg-darkcayn hover:text-white hover:border-transparent font-medium' 
				onClick={()=>window.open('https://agroobot.com','_blank')}>
					Install Apps
				</button>
				<button className='mt-2 border-1 border-lightcayn rounded-2xl px-3 py-2 text-lightcayn hover:bg-darkcayn hover:text-white hover:border-transparent font-medium' 
				onClick={()=>showModalAdd(true)}>
					Tambah Lahan Sekarang
				</button>
			</div>	
			}

			{loader && <Loader />}
			{modalAdd && <ModalFormLand onCancel={()=>showModalAdd(false)} data={selectedLand} onSubmit={handleSubmitDataForm} />}
			{modalErr && <ModalMessage message={messageErr} onClose={()=>window.location.reload()} />}
        </div>
    )
}

export default LandFarmer