import axios from 'axios'
import { baseURL } from './common-api'

export const getAllProbeType = async () => {
    try {
        const data = await axios.get(baseURL + '/api/probe/get-probe-type', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


//#region MTR PROBE
export const getAllProbe = async () => {
    try {
        const data = await axios.get(baseURL + '/api/probe/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewProbe = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/probe/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateProbe = async (probeID, payload) => {
    try {
        const data = await axios.patch(baseURL + '/api/probe/update/' + probeID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteProbe = async (probeID) => {
    try {
        const data = await axios.delete(baseURL + '/api/probe/delete/' + probeID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const disableProbe = async (probeID) => {
    try {
        const data = await axios.delete(baseURL + '/api/probe/disable-byaction-web/' + probeID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
//#endregion



//#region PROBE DISTRICT
export const getAllProbeDistrict = async () => {
    try {
        const data = await axios.get(baseURL + '/api/probe-district/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewProbeDistrict = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/probe-district/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateProbeDistrict = async (probeID, payload) => {
    try {
        const data = await axios.patch(baseURL + '/api/probe-district/update/' + probeID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteProbeDistrict = async (probeID) => {
    try {
        const data = await axios.delete(baseURL + '/api/probe-district/delete/' + probeID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
//#endregion