import { useCallback, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"
import { useForm } from "react-hook-form";
import { faEdit } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { getOneUserByID } from "../../../api/user-api"
import { createEditCustomerDetail, getOneCustomerDetail } from "../../../api/customerdetail-api"

import Helper from "../../../helpers/helper"
import Location from "../../../helpers/location"

import { ButtonAdd, CancelButton, SaveButton } from "../../../components/button/CustomButton";
import ErrorField from "../../../components/ErrorField"
import Loader from "../../../components/modal/Loader";
import ModalMessage from "../../../components/modal/ModalMessage"

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3  mt-1'
const selectStyle = 'border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'


const CustomerDetail = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')
    const [disableForm, setDisableForm] = useState(true)
    const [emptyData, showEmptyData] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [data, setData] = useState(null)

    const [province, setProvince] = useState([])
    const [provID, setProvID] = useState('')
    const [cities, setCities] = useState([])
    const [cityID, setCityID] = useState('')
    const [districts, setDistricts] = useState([])
    const [districtID, setDistrictID] = useState('')

    const {id_seq} = useParams()
    const history = useHistory()

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue, getValues
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback ((obj) => {
        Object.keys(obj).map(key => {
            if(key === 'date_of_birth_dtm' || key==='spouse_date_of_birth_dtm'){
                return setValue(key, moment(obj[key]).format('YYYY-MM-DD'))
            }
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    const fetchData = useCallback( async () => {
        showLoader(true)
        const res = await getOneCustomerDetail(id_seq)

        console.log(res)
        if(res.data){
            if(res.data.code === 0){
                setDisableForm(true)
                showEmptyData(false)
                setData(res.data.data)
                setdefaultValue(res.data.data)
            }else{
                showEmptyData(true)
            }
            showLoader(false)
        }else{
            alert(`${res.config?.url} ${res.message}`)
            window.location.reload()
        }
    }, [id_seq, setdefaultValue])

    const fetchDataUser = useCallback( async () => {
        showLoader(true)
        const res = await getOneUserByID(localStorage.getItem('agroo-token'), id_seq)

        console.log(res)
        if(res.data){
            if(res.data.code === 0){
                setdefaultValue(res.data.data)
                showLoader(false)
            }else{
                history.replace('/admin/user')
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
            window.location.reload()
        }
    }, [id_seq, history, setdefaultValue])


    useEffect(() => {
        fetchData()
        fetchDataUser()
    }, [fetchData, fetchDataUser])

    useEffect(() => {
        async function fetchLocation(){
            const prov = await Location.getProvinces()
            setProvince(prov)

            const city = await Location.allCity()
            setCities(city)

            const districts = await Location.allDistricts()
            setDistricts(districts)
        }
        fetchLocation()
    }, [])


    const handleAddNew = () => {
        showEmptyData(false)
        setDisableForm(false)
    }

    const handleEditData = () => {
        setIsUpdate(true)
        setDisableForm(!disableForm)
        setProvID(getValues('province_var')); setCityID(getValues('city_var')); setDistrictID(getValues('district_var'))
    }

    const onValid = async (dataForm) => {
        showLoader(true)

        console.log(dataForm)

        const payload = await Helper.validateForm(dataForm, false)

        if(payload.phone_number_int) payload.phone_number_int = Helper.IDPhoneNumber(payload.phone_number_int)
        if(payload.date_of_birth_dtm) payload.date_of_birth_dtm = moment(payload.date_of_birth_dtm).format('YYYY-MM-DD')
        if(payload.spouse_date_of_birth_dtm) payload.spouse_date_of_birth_dtm = moment(payload.spouse_date_of_birth_dtm).format('YYYY-MM-DD')
        if(payload.emergency_contact_number_int) payload.emergency_contact_number_int = Helper.IDPhoneNumber(payload.emergency_contact_number_int)

        if(payload.address_var) delete payload.address_var
        if(payload.ktp_var) delete payload.ktp_var

        payload.user_id_int = id_seq
        if(!isUpdate){
            payload.created_by_var = user.fullname_var
        }else{
            payload.updated_by_var = user.fullname_var
        }

        const res = await createEditCustomerDetail(payload)

        console.log("Create/Update Customer :", res)
        if(res.data){
            if(res.data.code === 0){
                window.location.reload()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
            showLoader(false)
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    return (
        <div>
            <div className='flex justify-between items-center mb-6'>
                <h1 className='font-semibold text-sm'>Data Petani</h1>
                {!emptyData && data &&
                <span className='text-lg text-darkcayn hover:text-lightcayn cursor-pointer' onClick={handleEditData}>
                    <FontAwesomeIcon icon={faEdit} />
                </span>
                }
            </div>

            {!emptyData? 
            <form onSubmit={handleSubmit(onValid)} className='bg-white rounded-2xl shadow-2xl mt-5 p-6'>
                <fieldset disabled={disableForm}>

                {/* PROFILE */}
                <div>
                    <div className='border-b-1 border-gray-300 pb-2 mb-4'>
                        <h2 className='font-medium'>Profile Pribadi</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Nama Lengkap</label>
                            <input type='text' className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' 
                                {...register("fullname_var", { required: "Fullname is required."})}
                            />
                            <ErrorField errors={errors} name='fullname_var' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Jenis Kelamin</label>
                            <div className='flex items-center py-2 px-3 sm:p-3'>
                                <input type='radio' id='cowo' value='Laki-laki' className='mr-1 cursor-pointer' {...register("gender_var")} />
                                <label htmlFor='cowo' className='cursor-pointer'>Laki-laki</label>
                                <span className='mr-5'></span>
                                <input type='radio' id='cewe' value='Perempuan' className='mr-1 cursor-pointer' {...register("gender_var")} />
                                <label htmlFor='cewe' className='cursor-pointer'>Perempuan</label>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Tempat Lahir</label>
                            <input type='text' className={inputText} placeholder='Bogor, Jawa Barat' {...register("place_of_birth_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Tanggal Lahir</label>
                            <input type='date' className={`${inputText} w-full bg-transparent`} {...register("date_of_birth_dtm", { required: "Date of birth is required."})} />
                            <ErrorField errors={errors} name='date_of_birth_dtm' />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>N I K</label>
                            <input type='number' className={inputText} placeholder='1234567890123xxx' 
                            {...register("nik_int", {
                                pattern: { value: /\d+/, message: "NIK is number only." },
                                minLength: { value: 16, message: "NIK length must 16 characters." },
                                maxLength: { value: 16, message: "NIK length must 16 characters." }
                            })}/>
                            <ErrorField errors={errors} name='nik_int' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Pendidikan</label>
                            <input type='text' className={inputText} placeholder='SMA/SMK, D1, D2, D3, S1, S2, S3 dll'  {...register("education_var")} />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>No. Handphone</label>
                            <input type='tel' className={inputText} 
                            {...register("phone_number_int", {
                                required: "Phone number is required.",
                                pattern: { value: /^\d+$/, message: "Phone number is number only." },
                                minLength: { value: 10, message: "Phone number must exceed 9 characters." },
                                maxLength: { value: 14, message: "Phone number too long." }
                            })}/>
                            <ErrorField errors={errors} name='phone_number_int' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Email</label>
                            <input type='email' className={inputText} placeholder='user@mail.com' 
                                {...register("email_var", { pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address."} })}
                            />
                            <ErrorField errors={errors} name='email_var' />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Kewarganegaraan</label>
                            <div className='flex items-center py-2 px-3 sm:p-3'>
                                <input type='radio' id='wni' value='WNI' className='mr-1 cursor-pointer' {...register("citizenship_var")}  />
                                <label htmlFor='wni' className='cursor-pointer'>W N I</label>
                                <span className='mr-5'></span>
                                <input type='radio' id='wna' value='WNA' className='mr-1 cursor-pointer' {...register("citizenship_var")} />
                                <label htmlFor='wna' className='cursor-pointer'>W N A</label>
                            </div>
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Negara Domisili</label>
                            <input type='text' className={inputText} placeholder='Indonesia' {...register("nationality_var")} />
                        </div>
                    </div>
                </div>
                {/* END PROFILE */}

                {/* Family */}
                <div className='mt-10'>
                    <div className='border-b-1 border-gray-300 pb-2 mb-4'>
                        <h2 className='font-medium'>Detail Keluarga</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Nama Ibu Kandung</label>
                            <input type='text' className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' placeholder='Ibu Kandung' 
                                {...register("mothers_name_var")}
                            />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Jumlah Tanggungan</label>
                            <input type='number' className={inputText} placeholder='3' {...register("num_family_depend_int")} />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Status Perkawinan</label>
                            <div className='flex py-2 sm:px-3'>
                                <div className='flex items-center'>
                                    <input type='radio' id='belumkawin' value='Belum Kawin' className='mr-1 cursor-pointer' {...register("marital_status_var")} />
                                    <label htmlFor='belumkawin' className='cursor-pointer'>Belum Kawin</label>
                                </div>
                                <div className='flex items-center mx-3'>
                                    <input type='radio' id='kawin' value='Kawin' className='mr-1 cursor-pointer' {...register("marital_status_var")} />
                                    <label htmlFor='kawin' className='cursor-pointer'>Kawin</label>
                                </div>
                                <div className='flex items-center'>
                                    <input type='radio' id='ceraihidup' value='Cerai Hidup' className='mr-1 cursor-pointer' {...register("marital_status_var")} />
                                    <label htmlFor='ceraihidup' className='cursor-pointer'>Cerai Hidup</label>
                                </div>
                                <div className='flex items-center ml-3'>
                                    <input type='radio' id='ceraimati' value='Cerai Mati' className='mr-1 cursor-pointer' {...register("marital_status_var")} />
                                    <label htmlFor='ceraimati' className='cursor-pointer'>Cerai Mati</label>
                                </div>
                            </div>
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                    </div>
                </div>
                {/* END Family */}

                {/* Address */}
                <div className='mt-10'>
                    <div className='flex justify-between items-center border-b-1 border-gray-300 pb-2 mb-4'>
                        <h2 className='font-medium'>Detail Alamat</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Alamat Rumah</label>
                            <textarea className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' placeholder='Jl RT/RW, Kelurahan, Kecamatan' 
                                {...register("home_address_txt")}
                            />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Status Kepemilikan</label>
                            <input type='text' className={inputText} placeholder='Keluarga/Kontrakan/Kost'  {...register("home_ownership_status_var")} />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Provinsi</label>
                            {disableForm?
                            <input type='text' defaultValue={province.find(({prov_id}) => parseInt(prov_id)===parseInt(data?.province_var))?.prov_name} className={inputText} placeholder='Jawa Barat'/>
                            :
                            <select {...register("province_var")} value={provID} onChange={(e)=>setProvID(e.target.value)} className={selectStyle}>
                                <option value=''>- Pilih Provinsi -</option>
                                {province?.map((data, key) => (
                                    <option key={key} value={data.prov_id}>{data.prov_name}</option>
                                ))}
                            </select>
                            }
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Kabupaten / Kota</label>
                            {disableForm?
                            <input type='text' defaultValue={cities.find(({city_id}) => parseInt(city_id)===parseInt(data?.city_var))?.city_name} className={inputText} placeholder='Bogor' />
                            :
                            <select {...register("city_var")} value={cityID} onChange={(e)=>setCityID(e.target.value)} className={selectStyle}>
                                <option value=''>- Pilih Kabupaten/Kota -</option>
                                {cities?.filter(({prov_id}) => parseInt(prov_id)=== parseInt(provID))?.map((data, key) => (
                                    <option key={key} value={data.city_id}>{data.city_name}</option>
                                ))}
                            </select>
                            }
                        </div>
                    </div> 
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Kecamatan</label>
                            {disableForm?
                            <input type='text' defaultValue={districts.find(({dis_id}) => parseInt(dis_id)===parseInt(data?.district_var))?.dis_name} className={inputText} placeholder='Cikeas' />
                            :
                            <select {...register("district_var")} value={districtID} onChange={(e)=>setDistrictID(e.target.value)} className={selectStyle}>
                                <option value=''>- Pilih Kecamatan -</option>
                                {districts?.filter(({city_id}) => parseInt(city_id)===parseInt(cityID)).map((data, key) => (
                                    <option key={key} value={data.dis_id}>{data.dis_name}</option>
                                ))}
                            </select>
                            }
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Kode Pos</label>
                            <input type='number' className={inputText} placeholder='27266' 
                                {...register("postal_code_int", {
                                    minLength: {value: 4, message: 'Postal code invalid'},
                                    maxLength: {value: 8, message: 'Postal code too long'}
                                })} />
                            <ErrorField errors={errors} name='postal_code_int' />
                        </div>
                    </div> 
                </div>
                {/* END Address */}

                {/* Bank */}
                <div className='mt-10'>
                    <div className='flex justify-between items-center border-b-1 border-gray-300 pb-2 mb-4'>
                        <h2 className='font-medium'>Detail Bank</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Nama Bank</label>
                            <input type='text' className={inputText} placeholder='BNI/BRI/BCA/Mandiri' {...register("bank_name_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Nomor Rekening</label>
                            <input type='number' className={inputText} placeholder='1234567890xxxx' {...register("account_number_var")} />
                        </div>
                    </div>
                </div>
                {/* END Bank */}

                {/* Occupation */}
                <div className='mt-10'>
                    <div className='flex justify-between items-center border-b-1 border-gray-300 pb-2 mb-4'>
                        <h2 className='font-medium'>Detail Pekerjaan</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Jenis Pekerjaan</label>
                            <input type='text' className={inputText} placeholder='Buruh' {...register("profesion_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Alamat Pekerjaan</label>
                            <textarea className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' placeholder='Jl ....' {...register("work_address_txt")} />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Lama Bekerja (Tahun)</label>
                            <input type='number' className={inputText} placeholder='5' {...register("length_of_work_int")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Pendapatan</label>
                            <input type='number' className={inputText} placeholder='4500000' {...register("salary_int")} />
                        </div>
                    </div> 
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>N P W P</label>
                            <input type='text' className={inputText} placeholder='80.900.304.7-001-000' {...register("npwp_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Poktan</label>
                            <input type='text' className={inputText} placeholder='Poktan' {...register("poktan_name_var")} />
                        </div>
                    </div> 
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Nomor Kartu Tani</label>
                            <input type='number' className={inputText} placeholder='1945801470630104' {...register("farmer_name_card_int")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Nomor Surat Legal</label>
                            <input type='text' className={inputText} placeholder='Nomor Surat Legal' {...register("legal_letter_var")} />
                        </div>
                    </div> 
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Modal Usaha</label>
                            <input type='number' className={inputText} {...register("fund_business_int")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Biaya Total</label>
                            <input type='number' className={inputText} {...register("total_cost_int")} />
                        </div>
                    </div>  
                    <div className='flex flex-col sm:flex-row sm:pr-10 mb-3'>
                        <div className={containerInput}>
                            <label>Jumlah Pekerja (Orang)</label>
                            <input type='number' className={inputText} placeholder='20' {...register("employee_int")} />
                        </div>
                    </div> 
                </div>
                {/* END Occupation */}

                {/* Spouse */}
                <div className='mt-10'>
                    <div className='flex justify-between items-center border-b-1 border-gray-300 pb-2 mb-4'>
                        <h2 className='font-medium'>Detail Pasangan</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Nama Pasangan</label>
                            <input type='text' className={inputText} placeholder='Nama Istri/Suami' {...register("spouse_name_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Jenis Kelamin</label>
                            <div className='flex items-center py-2 px-3 sm:p-3'>
                                <input type='radio' id='pasangancowo' value='Laki-laki' className='mr-1 cursor-pointer' {...register("spouse_gender_var")}  />
                                <label htmlFor='pasangancowo' className='cursor-pointer'>Laki-laki</label>
                                <span className='mr-5'></span>
                                <input type='radio' id='pasangancewe' value='Perempuan' className='mr-1 cursor-pointer' {...register("spouse_gender_var")} />
                                <label htmlFor='pasangancewe' className='cursor-pointer'>Perempuan</label>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Tempat Lahir</label>
                            <input type='text' className={inputText} placeholder='Bogor, Jawa Barat' {...register("spouse_place_of_birth_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Tanggal Lahir</label>
                            <input type='date' className={`${inputText} w-full bg-transparent`} {...register("spouse_date_of_birth_dtm")} />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>N I K</label>
                            <input type='number' className={inputText} placeholder='1234567890123xxx' 
                            {...register("spouse_nik_int", {
                                pattern: { value: /\d+/, message: "NIK is number only." },
                                minLength: { value: 16, message: "NIK length must 16 characters." },
                                maxLength: { value: 16, message: "NIK length must 16 characters." }
                            })}/>
                            <ErrorField errors={errors} name='spouse_nik_int' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Pekerjaan</label>
                            <input type='text' className={inputText} placeholder='PNS/ASN' {...register("spouse_profesion_var")} />
                        </div>
                    </div> 
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Pendapatan/Bulan</label>
                            <input type='number' className={inputText} placeholder='2500000' {...register("spouse_salary_int")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Email</label>
                            <input type='email' className={inputText} placeholder='spouse@mail.com' 
                                {...register("spouse_email_var", { pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Invalid email address."} })}
                            />
                            <ErrorField errors={errors} name='spouse_email_var' />
                        </div>
                    </div>
                </div>
                {/* END Spouse */}

                {/* Emergency Contact */}
                <div className='mt-10'>
                    <div className='flex justify-between items-center border-b-1 border-gray-300 pb-2 mb-4'>
                        <h2 className='font-medium'>Detail No Darurat</h2>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Nama Kontak Darurat</label>
                            <input type='text' className={inputText} placeholder='Nama Lengkap' {...register("emergency_contact_name_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Hubungan</label>
                            <input type='text' className={inputText} placeholder='Suami/Istri/Saudara' {...register("relation_var")} />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row sm:pr-10 mb-3'>
                        <div className={containerInput}>
                            <label>No. Handphone</label>
                            <input type='tel' name='emergency_contact_number_int' defaultValue={data?.emergency_contact_number_int} className={inputText} placeholder='081234567890' 
                            {...register("emergency_contact_number_int", {
                                pattern: { value: /^\d+$/, message: "Phone number is number only." },
                                minLength: { value: 10, message: "Phone number must exceed 9 characters." },
                                maxLength: { value: 14, message: "Phone number too long." }
                            })}/>
                            <ErrorField errors={errors} name='emergency_contact_number_int' />
                        </div>
                    </div> 
                </div>
                {/* END Emergency Contact */}

                </fieldset>

                {!disableForm &&
                <div className='flex justify-end mt-5'>
                    <CancelButton onClick={fetchData} type='reset' />
                    <SaveButton type='submit' />
                </div>
                }
            </form>
            
            :
            <div className='flex justify-center items-center' style={{minHeight: '50vh'}}>
                <div className='flex flex-col items-center justify-center' style={{minHeight: '50vh'}}>
                    <p className='font-medium mb-3'>Belum ada data</p>
                    <ButtonAdd onClick={handleAddNew} />
                </div>
            </div>
            }
        
        {loader && <Loader />}
        {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(CustomerDetail)