import { useEffect, useState } from "react"
import { connect } from "react-redux"

import TableUser from "../../components/tables/Table"

import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage"
import { getAllAsTransaction, getDetailRecommendation } from "../../api/recommendation-api";
import moment from "moment";
import ModalDetailAnalysis from "../../components/modal/ModalDetailAnalysis";
import { faCalendarCheck, faCalendarWeek, faHourglassStart, faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const TransactionAnalysis = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalDetail, showModalDetail] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState(false)
    const [dataTable, setDataTable] = useState([])
    const [filterData, setFilterData] = useState([])
    const [detailAnalysis, setDetailAnalysis] = useState(null)

    useEffect(() => {
        async function fetchAnalysis() {
            showLoader(true)
            const res = await getAllAsTransaction(user.id_seq)

            console.log('Get Analysis AsTransaction :', res)
            if(res.data){
                if(res.data.code === 0){
                    setDataTable(res.data.data)
                    setFilterData(res.data.data)
                }else{
                    setMessageErr(res.data.message)
                    showModalErr(true)
                }
                showLoader(false)
            }else{
                alert('Connection timeout..')
                fetchAnalysis()
            }
        }

        fetchAnalysis()
    }, [user.id_seq])


    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataTable]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.land_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.land_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.commodity_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.analyst_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.area_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    [{id: 1, status: 'Telah Dianalisa'}, {id: -10, status: 'Sedang Dianalisa'}, {id: -5, status: 'Belum Dianalisa'}].find(data => data.id === item.status_int)?.status?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataTable)
        }
    }

    const handleCheckAnlyze = async (data) => {
        showLoader(true)

        const res = await getDetailRecommendation(data.id_seq)
        console.log('Get Detail Analyst :', res)
        if(res.data){
            if(res.data.code === 0){
                setDetailAnalysis(res.data.data)
                showModalDetail(true)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
            showLoader(false)
        }else{
            alert('Connection timeout..')
        }
    }

    const columns = [
        {
            Header: () => <div className='p-4 text-left'>Lahan</div>,
            Footer: 'Lahan',
            accessor: 'land_code_var',
            Cell: ({ value, row }) => {
                const data = row.original
                return <div className='text-left pl-2'>[{value}] {data?.land_name_var}</div>
            }  
        },
        {
            Header: 'Komoditas',
            Footer: 'Komoditas',
            accessor: 'commodity_name_var'
        },
        {
            Header: 'Luas (Ha)',
            Footer: 'Luas (Ha)',
            accessor: 'area_var'
        },
        {
            Header: 'Data',
            Footer: 'Data',
            accessor: 'data_taking_int'
        },
        {
            Header: 'Analis',
            Footer: 'Analis',
            accessor: 'analyst_name'
        },
        {
            Header: 'Waktu Mulai',
            Footer: 'Waktu Mulai',
            accessor: 'start_on_dtm',
            Cell: ({value}) => value? moment(value, moment(value).creationData().format).format('YYYY-MM-DD HH:mm:ss') : null
        },
        {
            Header: 'Waktu Selesai',
            Footer: 'Waktu Selesai',
            accessor: 'end_on_dtm',
            Cell: ({value}) => value? moment(value, moment(value).creationData().format).format('YYYY-MM-DD HH:mm:ss') : null
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}) => (
                parseInt(value)===1? 
                <div className='bg-green_sidebar text-green-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Telah Dianalisa</div>
                :
                parseInt(value)===-5? 
                <div className='bg-red-100 text-red-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Belum Dianalisa</div>
                :
                parseInt(value)===-10? 
                <div className='bg-yellow-100 text-yellow-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Sedang Dianalisa</div>
                :
                <div className='bg-indigo-100 text-indigo-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Unknown Status</div>
            )
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                if(parseInt(data.status_int) === 1){
                    return <button className='bg-lightcayn text-white px-2 py-1.5 rounded-2xl' onClick={()=>handleCheckAnlyze(data)}>
                            Lihat Analisa
                        </button>
                }
                return <span></span>
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-semibold'>Analysis</h1>
                <h2 className='text-base font-medium text-right'>Transaction<span className='text-xs'> / </span><span className='text-sm'>Analysis</span></h2>
            </div>

            <div className='flex flex-col md:flex-row items-center justify-between my-6'>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faThList} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataTable?.length}</h1>
                        <p>Total Analysis</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faCalendarCheck} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataTable?.filter(data => parseInt(data.status_int) === 1).length}</h1>
                        <p>Completed</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faHourglassStart} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataTable?.filter(data => parseInt(data.status_int)===-10 || parseInt(data.status_int)===-5).length}</h1>
                        <p>Uncompleted</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faCalendarWeek} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataTable?.filter(data => moment(data.start_on_dtm).format('MM') === moment().format('MM') || moment(data.end_on_dtm).format('MM') === moment().format('MM') ).length}</h1>
                        <p>This Month</p>
                    </div>
                </div>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-6 mt-1'>
                <div className='mb-5'>
                    <h1 className='font-semibold'>List Analisis</h1>
                </div>
                <div className='flex justify-between items-center mb-3'>
                    <input type='search' onChange={handleSearch} placeholder='Cari Analisa' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {loader && <Loader />}
            {modalDetail && <ModalDetailAnalysis onCancel={()=>showModalDetail(false)} data={detailAnalysis} />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (TransactionAnalysis)