import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ButtonAdd = ({onClick, title}) => {
    return (
        <button onClick={onClick} className='flex items-center font-medium border-1 border-gray-300 rounded-full px-2 sm:px-3 py-1 hover:bg-green_btn hover:text-white hover:border-transparent'>
            <p className='mr-3 hidden md:block'>{title || 'Tambah Data'}</p>
            <span className='text-white bg-green_btn hover:bg-dark_green rounded-full p-1 w-6 h-6 flex items-center justify-center cursor-pointer text-base'>
                <FontAwesomeIcon icon={faPlus} />
            </span>
        </button>
    )
}

export const CancelButton = ({type, onClick, title}) => (
    <button type={type || 'reset'} onClick={onClick} className='bg-gray-500 hover:bg-black text-white px-5 py-2.5 rounded-full'>
        {title || 'Batal'}
    </button>
)

export const SaveButton = ({type, onClick, title}) => (
    <button type={type || 'submit'} onClick={onClick} className='bg-green_btn hover:bg-dark_green text-white px-3.5 py-2.5 rounded-full ml-2 sm:ml-3.5'>
        {title || 'Simpan'}
    </button>
)