import { useCallback, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import moment from "moment"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useForm } from "react-hook-form";

import { createNewCultivation, getCultivationListByUser, updateCultivation, deleteCultivation } from "../../../api/cultivation-api"
import { getLandListByUser } from "../../../api/landarea-api"

import { ButtonAdd, CancelButton, SaveButton } from "../../../components/button/CustomButton";
import { DropdownLand } from "../../../components/dropdown/SimpleDropdown";
import Loader from "../../../components/modal/Loader";
import ErrorField from "../../../components/ErrorField"
import ModalMessage from "../../../components/modal/ModalMessage"

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3  mt-1'

const Cultivation = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')
    
    const [isUpdate, setIsUpdate] = useState(false)
    const [formAdd, showFormAdd] = useState(false)
    const [keyUpdate, setKeyUpdate] = useState(null)

    const [dataLand, setDataLand] = useState([])
    const [landCode, setLandCode] = useState('')
    const [dataCultivation, setDataCultivation] = useState([])
    const [selectedData, setSelectedData] = useState({})

    const history = useHistory()
    const {id_seq} = useParams()

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
        setValue
    } = useForm({ criteriaMode: "all" });

    const fetchData = useCallback(async () => {
        showLoader(false)
        const res = await getCultivationListByUser(id_seq)

        console.log(res.data)
        resetContainer()
        if(res.data){
            if(res.data.code === 0){
                setDataCultivation(res.data.data)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                history.replace('/admin/user')
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [id_seq, history])

    const fetchLand = useCallback(async ()=> {
        const res = await getLandListByUser(id_seq)

        if(res.data){
            if(res.data.code === 0){
                setDataLand(res.data.data)
                fetchData()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                history.replace('/admin/user')
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history, id_seq, fetchData])


    useEffect(() => {
        // fetchData()
        fetchLand()
    }, [fetchLand])

    const formActive = (key) => {
        if(key === keyUpdate){
            return true
        }
        return false
    }

    const handleNewData = () => {
        setIsUpdate(false)
        setSelectedData(false)
        showFormAdd(true)
        reset()
    }

    const onChangeFile = (e) => {
        setValue(e.target.name, e.target.files)
    }

    const onValidCreateForm = async (dataForm) => {
        showLoader(true)

        const {land_code_var, activity_order_var, activity_date_dtm, activity_needs_var, activity_photo_var} = dataForm

        console.log(dataForm)

        const formData = new FormData()

        formData.append("land_code_var", land_code_var)
        formData.append("activity_order_var", activity_order_var)
        formData.append("activity_date_dtm", activity_date_dtm)
        formData.append("activity_needs_var", activity_needs_var || '')

        const files = activity_photo_var;
        if(files){
            for (let i = 0; i < files.length; i++) {
                await formData.append('images', files[i])
            }
        }

        submitDataToAPI(formData)
    }

    const handleEditData = (key, data) => {
        setSelectedData(data)
        setLandCode(data.land_code_var)
        setKeyUpdate(key)
        setIsUpdate(true)
    }

    const handleSubmitUpdate = async (e) => {
        e.preventDefault()
        showLoader(true)

        const {land_code_var, activity_order_var, activity_date_dtm, activity_needs_var, activity_photo_var} = e.target

        const formData = new FormData()

        formData.append("land_code_var", land_code_var.value)
        formData.append("activity_order_var", activity_order_var.value)
        formData.append("activity_date_dtm", activity_date_dtm.value)
        formData.append("activity_needs_var", activity_needs_var.value || '')

        const files = activity_photo_var.files;
        if(files){
            for (let i = 0; i < files.length; i++) {
                await formData.append('images', files[i])
            }
        }

        submitDataToAPI(formData)
    }

    const submitDataToAPI = async (formData) => {
        let res = null
        
        formData.append("user_id_int", id_seq)
        if(!isUpdate){
            formData.append('created_by_var', user.fullname_var)
            res = await createNewCultivation(formData)
        }else{
            formData.append('updated_by_var', user.fullname_var)
            res = await updateCultivation(selectedData.id_seq, formData)
        }

        console.log('Create/Update Cultivation :', res)
        resetContainer()
        if(res.data){
            if(res.data.code === 0){
                // fetchData()
                if(isUpdate) window.location.reload()
                else fetchLand()
            }else{
                showModalErr(true)
                setMessageErr(res.data.message)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }
    
    const handleDeleteData = async (id_seq) => {
        showLoader(true)
        const res = await deleteCultivation(id_seq)
        
        console.log("Delete Cultivation :", res)
        resetContainer()
        if(res.data){
            if(res.data.code === 0){
                showLoader(true)
                window.location.reload()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetContainer = () => {
        setLandCode('')
        setIsUpdate(false)
        setKeyUpdate(null)
        showLoader(false)
        showFormAdd(false)
    }

    const handleCancelAddNew = () => {
        setLandCode('')
        showFormAdd(false)
        reset()
    }

    return (
        <div>
             <div className='flex justify-between items-center mb-6'>
                <h1 className='font-semibold text-sm'>Data Budidaya</h1>
                {dataCultivation.length > 0 &&
                    <ButtonAdd onClick={handleNewData} />
                }
            </div>

            {/* FORM */}
            {!formAdd ?
            dataCultivation.length >0?
                dataCultivation.map((data, key) => (
                <div className='p-6 bg-white rounded-2xl shadow-2xl mb-5' key={key}>
                    <div className='flex justify-between items-center mb-4 pb-1 border-b-1 border-gray-300'>
                        <h1 className='font-semibold'>Detail Budidaya {key + 1}</h1>
                        <DropdownLand data={data} onEdit={() => handleEditData(key,data)} onDelete={handleDeleteData} />
                    </div>
                    <form onSubmit={handleSubmitUpdate}>
                        <fieldset disabled={!formActive(key)}>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Lahan</label>
                                    {/* <input type='text' name='land_code_var' defaultValue={`[${dataLand.find(({land_code_var}) => land_code_var===data.land_code_var)?.land_code_var}] ${dataLand.find(({land_code_var}) => land_code_var===data.land_code_var)?.land_name_var}`} className={inputText} /> */}
                                    {!formActive(key) ?
                                    <input type='text' name='land_code_var' defaultValue={`[${data.land_code_var}] ${data && dataLand?.filter(item => item.land_code_var===data.land_code_var)[0]?.land_name_var}`} className={inputText} />
                                    :
                                    <select name='land_code_var' value={landCode} onChange={(e)=>setLandCode(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                        {dataLand?.map((data, key) => (
                                            <option key={key} value={data.land_code_var}>[{data.land_code_var}] {data.land_name_var}</option>
                                        ))}
                                    </select>
                                    }
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Tanggal Aktivitas</label>
                                    <input type='date' name='activity_date_dtm' defaultValue={data && moment(data.activity_date_dtm).format('YYYY-MM-DD')} className={`${inputText} w-full bg-transparent`} required />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Urutan Aktivitas</label>
                                    <input type='text' name='activity_order_var' defaultValue={data?.activity_order_var} className={inputText} required />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Kebutuhan Aktivitas</label>
                                    <input type='text' name='activity_needs_var' defaultValue={data?.activity_needs_var} className={inputText} />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row sm:pr-10 mb-3'>
                                {isUpdate?
                                <div className={containerInput}>
                                    <label>Update Foto Aktivitas</label>
                                    <input type='file' name='activity_photo_var' multiple className={inputText} />
                                </div>
                                :
                                <div className={containerInput}>
                                    <label>Foto Aktivitas</label>
                                    <div className='flex flex-wrap justify-center items-center'>
                                    {data?.activity_photo_var.map((image, key) => 
                                        <LazyLoadImage key={key} src={image} effect='blur' className='w-30 mt-1 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                    )}
                                    </div>
                                </div>
                                }
                            </div>
                        </fieldset>
                    
                        {isUpdate && formActive(key) &&
                        <div className='flex justify-end mt-3'>
                            <CancelButton type='reset' onClick={fetchData} />
                            <SaveButton type='submit' />
                        </div>
                        }
                    </form>
                </div>
                ))
                :
                <div className='flex flex-col items-center justify-center' style={{minHeight: '50vh'}}>
                    <p className='font-medium mb-3'>Belum ada data budidaya</p>
                    <ButtonAdd onClick={() => showFormAdd(true)} />
                </div>
            :
                <div className='mt-5'>
                    <form onSubmit={handleSubmit(onValidCreateForm)}>
                        <h2 className='font-semibold mb-5'>Tambah Budidaya Baru</h2>
                        
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Lahan</label>
                                <select {...register("land_code_var")} value={landCode} onChange={(e)=>setLandCode(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                    {dataLand?.map((data, key) => (
                                        <option key={key} value={data.land_code_var}>[{data.land_code_var}] {data.land_name_var}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='land_code_var' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Tanggal Aktivitas</label>
                                <input type='date' className={`${inputText} w-full bg-transparent`} {...register("activity_date_dtm", { required: 'This field is required'})} />
                                <ErrorField errors={errors} name='activity_date_dtm' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Urutan Aktivitas</label>
                                <input type='text' className={inputText} {...register("activity_order_var", { required: 'This field is required'})} />
                                <ErrorField errors={errors} name='activity_order_var' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Kebutuhan Aktivitas</label>
                                <input type='text' className={inputText} {...register("activity_needs_var")} />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row sm:pr-10 mb-3'>
                            <div className={containerInput}>
                                <label>Foto Aktivitas</label>
                                <input type='file' multiple className={inputText} onChange={onChangeFile} name='activity_photo_var'/>
                            </div>
                        </div>
                    
                        <div className='flex justify-end mt-8'>
                            <CancelButton type='reset' onClick={handleCancelAddNew} />
                            <SaveButton type='submit' />
                        </div>
                    </form>    
                </div>
            }


            {loader && <Loader />}
            {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(Cultivation)