import { faDownload, faSeedling, faThermometer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { LetterIcon } from "../../../components/icon/CustomIcon";



const LandTreatment = ({data, sampling, landCode, dataTaking}) => {
  const onDownload = useCallback(() => {
    // getThreatmentPDF(landCode, dataTaking)
  }, [landCode, dataTaking])
 
    return (
        <div className='bg-white px-3 py-5 md:p-5 rounded-2xl shadow-lg'>
            <div className='flex justify-between items-center'>
                <div className='flex'>
                    <span className='bg-green_sidebar text-green-600 rounded-full flex justify-center items-center p-2 w-10 h-10 text-base'>
                        <FontAwesomeIcon icon={faSeedling} />
                    </span>
                    <div className='text-left ml-2.5'>
                        <p className='font-semibold'>Komoditas</p>
                        <p>{data?.commodity_name_var || '-'}</p>
                    </div>
                </div>
                <button className='border-2 border-green-700 text-green-700 bg-green_sidebar rounded-lg px-2 py-1.5 flex items-center -mr-2' onClick={onDownload}>
                    <span><FontAwesomeIcon icon={faDownload} /></span>
                    <p className='ml-1.5'>Download Laporan</p>
                </button>
            </div>
            
            <div className='my-5 bg-gray-200' style={{height: 1}}></div>
            <div className="text-center mb-3">
              <b>Jenis Tanah:</b> <br />
              {data?.land_type || 'Belum di konfigurasi'} <br />
              {data?.land_type && (
                <>
                  Bulk Density: {
                    data?.density_top === data?.density_bottom
                      ? Intl.NumberFormat("id-ID").format(data?.density_bottom)
                      : `${Intl.NumberFormat("id-ID").format(data?.density_top)} - ${Intl.NumberFormat("id-ID").format(data?.density_bottom)}`
                  }
                </>
              )}
            </div>

            <div className='bg-green_sidebar rounded-2xl px-2 py-5 font-medium'>
                <div className='grid grid-cols-5 text-center mb-5 text-xsm md:text-xs'>
                    <h1 className='font-semibold'>Sampling-{sampling}</h1>
                    <h1>Nilai Alat</h1>
                    <h1>Acuan</h1>
                    <h1>
                      Kebutuhan<br />
                      (Bulk Density * Nilai Alat)
                    </h1>
                    <h1>Pupuk</h1>
                </div>
                <div className='grid grid-cols-5 text-center'>
                    <div className='pt-0.5 grid grid-rows-4 gap-3 text-left'>
                        <div className='flex items-center'>
                            <LetterIcon letter='N' />
                            <p className='ml-2 md:ml-1.5 text-xsm md:text-xsm'>N (Urea)</p>
                        </div>
                        <div className='flex items-center'>
                            <LetterIcon letter='P' />
                            <p className='ml-2 text-xsm md:text-xsm'>P (TSP)</p>
                        </div>
                        <div className='flex items-center'>
                            <LetterIcon letter='K' />
                            <p className='ml-2 text-xsm md:text-xsm'>K (KCI)</p>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                            <p className='ml-4'>PH</p>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                            <p className='ml-4'>EC</p>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                            <p className='ml-4'>Humidity</p>
                        </div>
                        <div className='flex items-center'>
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                            <p className='ml-4'>Suhu Tanah</p>
                        </div>
                    </div>
                    <div className='py-2 grid grid-rows-4 gap-4 text-center font-bold'>
                        <h1 className={`${parseFloat(data?.n_value_var) - parseFloat(data?.n_value_flo)>0? 'text-red-400': 'text-green-500'}`}>{data?.n_value_flo || '00'}</h1>
                        <h1 className={`${parseFloat(data?.p_value_var) - parseFloat(data?.p_value_flo)>0? 'text-red-400': 'text-green-500'}`}>{data?.p_value_flo || '00'}</h1>
                        <h1 className={`${parseFloat(data?.k_value_var) - parseFloat(data?.k_value_flo)>0? 'text-red-400': 'text-green-500'}`}>{data?.k_value_flo || '00'}</h1>
                        <h1 className={`${parseFloat(data?.ph_value_var) - parseFloat(data?.ph_flo)>0? 'text-red-400': 'text-green-500'}`}>{data?.ph_flo || '00'}</h1>
                        <h1 className={`${parseFloat(data?.ph_value_var) - parseFloat(data?.ph_flo)>0? 'text-red-400': 'text-green-500'}`}>{data?.ec_flo || '00'}</h1>
                        <h1 className={`${parseFloat(data?.ph_value_var) - parseFloat(data?.ph_flo)>0? 'text-red-400': 'text-green-500'}`}>{data?.soil_humidity_flo || '00'}</h1>
                        <h1 className={`${parseFloat(data?.ph_value_var) - parseFloat(data?.ph_flo)>0? 'text-red-400': 'text-green-500'}`}>{data?.soil_temperature_flo || '00'}</h1>
                    </div>
                    <div className='py-2 grid grid-rows-4 gap-4 text-center'>
                        <h1>{data?.n_value_var || '00'}</h1>
                        <h1>{data?.p_value_var || '00'}</h1>
                        <h1>{data?.k_value_var || '00'}</h1>
                        <h1>{data?.ph_value_var || '00'}</h1>
                        <h1>{data?.ec_value_var || '00'}</h1>
                        <h1>{data?.humidity_var || '00'}</h1>
                        <h1>{data?.temperature_var || '00'}</h1>
                    </div>
                    <div className='py-2 grid grid-rows-4 gap-4 text-center rounded-xl bg-green-600 text-white font-bold'>
                        <h1>{ data?.needs_n }</h1>
                        <h1>{ data?.needs_p }</h1>
                        <h1>{ data?.needs_k }</h1>
                        <h1>{ data?.needs_ph }</h1>
                        <h1>{ data?.needs_ec }</h1>
                        <h1>{ data?.needs_soil_humidity }</h1>
                        <h1>{ data?.needs_soil_temperature }</h1>
                    </div>
                    <div className='py-2 grid grid-rows-4 gap-4 text-center  font-bold'>
                        <h1>{ data?.fertilizerN ? data?.fertilizerN + " kg/ha \n (46%)" : "-" }</h1>
                        <h1>{ data?.fertilizerP ? data?.fertilizerP + " kg/ha \n (15%)" : "-" }</h1>
                        <h1>{ data?.fertilizerK ? data?.fertilizerK + " kg/ha \n (15%)" : "-" }</h1>
                        <h1>&nbsp;</h1>
                        <h1>&nbsp;</h1>
                        <h1>&nbsp;</h1>
                        <h1>&nbsp;</h1>
                    </div>
                </div>

                {/* SKEMA */}
                {/* <div className='mt-8'>
                    <div className='bg-white rounded-xl border-1 border-gray-200 mt-5 -mx-1'>
                        <div className=' bg-green-900 text-white rounded-t-xl px-5 py-2.5 md:py-3 font-medium' >
                            <p>Skema 1</p>
                        </div>

                        <div className='flex items-center justify-between p-3.5 md:p-5'>
                            <div className='text-left'>
                                <p className='text-xsm'>Krista MKP</p>
                                <h1 className='text-xs md:text-sm font-semibold' >{111.647} kg</h1>
                            </div>
                            <div className='text-left'>
                                <p className='text-xsm'>OilPam EFB</p>
                                <h1 className='text-xs md:text-sm font-semibold' >{558.824} kg</h1>
                            </div>
                            <div className='text-left'>
                                <p className='text-xsm'>YaraLiva Calcinit</p>
                                <h1 className='text-xs md:text-sm font-semibold' >{680.147} kg</h1>
                            </div>
                        </div>

                        <div className='mx-5 bg-gray-200' style={{height: 1}}></div>

                        <div className='flex justify-between items-center m-3.5 md:m-5 text-green-600'>
                            <p className='text-xsm'>Estimasi Harga Pupuk</p>
                            <p className='font-semibold text-sms md:text-base'>Rp10.009.191,00</p>
                        </div>
                    </div>

                    <div className='bg-white rounded-xl border-1 border-gray-200 mt-5 -mx-1'>
                        <div className=' bg-green-900 text-white rounded-xl px-5 py-2.5 md:py-3 font-medium' >
                            <p>Skema 2</p>
                        </div>
                    </div>
                </div> */}

            </div>

        </div>
    )
}

export default LandTreatment