import axios from 'axios'
import moment from 'moment'
import { baseURL } from './common-api'

export const getDataMonitoringProbe = async (queryStr) => {
    try {
        const data = await axios.get(baseURL + '/api/trxprobe/monit?' + queryStr, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getDataTaking = async (landCode) => {
    try {
        const payload = {
            land_code_var: landCode
        }

        const data = await axios.post(baseURL + '/api/trxprobe/dropdown', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const activateProbe = async (landCode, probeCode, userName) => {
    try {
        const payload = {
            "probe_code_var": probeCode,
            "localtime": moment().format('YYYY-MM-DD HH:mm:ss'),
            "land_code_var" : landCode,
            "created_by_var": userName || 'system'
        }

        const data = await axios.post(baseURL + '/api/probe/get-config', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const deleteTrxProbe = async (id) => {
  try {
      const data = await axios.delete(baseURL + '/api/trxprobe/delete/' + id, {
          headers: {
              authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
              Accept: "application/json",
              "Content-Type": "application/json"
          }
      })
      return data
  } catch (error) {
      if(error.response) return error.response
      else return JSON.parse(JSON.stringify(error))  
  }
}

