import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, Polygon, ZoomControl  } from 'react-leaflet'
import { faArrowLeft,  } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LazyLoadImage } from "react-lazy-load-image-component"


import { getDetailLand } from "../../api/landarea-api";
import Helper, { isLatitude, isLongitude } from "../../helpers/helper";

import pointLand from '../../assets/img/green-dot.ico'
import logo from '../../assets/img/logo.svg'
import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage";
import moment from "moment";

const icon = new L.Icon({
    iconUrl: pointLand,
    iconRetinaUrl: pointLand,
    iconSize: new L.Point(20, 20),
    // className: 'leaflet-div-icon'
});

const containerInput = 'flex flex-col w-full md:w-1/2'

const LandDetail = () => {
	const [loader, showLoader] = useState(true)
	const [modalErr, showModalErr] = useState(false)
	const [messageErr, setMessageErr] = useState('')
	const [dataLand, setDataLand] = useState(null)
    const [dataCommodity, setDataCommodity] = useState(null)
    const [dataCulti, setDataCulti] = useState([])

    const [center, setCenter] = useState([-6.175372, 106.827194])
    const [map, setMap] = useState(null);

	const user = JSON.parse(localStorage.getItem('agroo-user'))
	const history = useHistory()
    const {land_code_var} = useParams()

	useEffect(() => {
		async function fetchLand(){
			const res = await getDetailLand(user.id_seq, land_code_var)

			console.log('get detail land :', res)
			if(res.data){
				if(res.data.code === 0){
					setDataLand(res.data.data)
                    setDataCommodity(res.data.data.commodities[0])
                    setDataCulti(res.data.data.culties)
					showLoader(false)
				}else{
					setMessageErr(res.data.message)
					showModalErr(true)
					showLoader(false)
				}
			}else{
				alert('Connection timeout...')
				fetchLand()
			}
		}

		fetchLand()
	}, [user.id_seq, land_code_var])

    useEffect(() => {
        if(dataLand){
            const centerMap = Helper.getCenterMapFromStr(dataLand.land_coordinates_txt)
            setCenter(centerMap)
            
            setTimeout(() => {
                map?.flyTo( 
                    [centerMap.lat, centerMap.lng], 
                    getZoom(dataLand.area_var), 
                    { animate: true }
                );
            }, 500);
        }
    }, [map, dataLand])

    const getZoom = (areaWidth) => {
		const width = parseFloat(areaWidth)
		if(width < 3){
			return 18
		}else if(width < 20){
			return 17
		}else if(width < 60){
			return 16
		}else if(width < 210){
			return 15
		}else if(width < 750){
			return 14
		}else if(width < 2000){
			return 13
		}else if(width < 10000){
			return 12
		}else if(width < 75000){
            return 11
        }else if(width < 500000){
            return 10
        }else if(width < 1000000){
            return 9
        }else{
			return 8
		}
	}

    return (
        <div className='flex item-center flex-col mb-auto px-4 py-6 md:p-6'>

            <div className={`bg-white rounded-2xl shadow-xl w-full relative`} >
                <button className='absolute top-3 left-3 w-10 h-10 rounded-full flex justify-center items-center text-lg bg-white text-gray-800 hover:bg-opacity-100 hover:text-green-800' style={{zIndex: 1}} onClick={()=>history.goBack()}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </button>
                {dataLand &&
                    <MapContainer 
                        center={center} whenCreated={setMap}
                        scrollWheelZoom={true} zoom={14} zoomAnimation={true} zoomControl={false}
                        className='w-full h-50vh rounded-t-2xl z-0'>
                            <ZoomControl position='topright' />
                            <TileLayer
                                attribution='by Agroobot'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                // maxZoom={20} id='' tileSize={512} zoomOffset={-1}
                                // url='https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA'
                            />
                            <Marker position={Helper.getCenterMapFromStr(dataLand?.land_coordinates_txt || '00')} icon={Helper.getMapIcon()}>
                                <Popup>
                                    {dataLand.land_name_var}
                                </Popup>
                            </Marker>

                            {dataLand?.land_coordinates_txt?.split(';').map((koor, key) => (
                                <Marker icon={icon} key={key}
                                    position={[isLatitude(koor.split(',')[0])? koor.split(',')[0] : 0,  isLongitude(koor.split(',')[1])? koor.split(',')[1] : 0]}>
                                </Marker>
                            ))}

                            <Polygon pathOptions={{color: 'lime'}} positions={Helper.generatelatLngFromStr(dataLand.land_coordinates_txt)} />
                    </MapContainer>
                }
                <div className='p-6'>
                    <div className='border-t-2 border-gray-300 pt-0'>
                    <h1 className='font-semibold mb-6 mt-3 text-center'>Detail Lahan</h1>
                    <div className='flex items-center md:items-start flex-col md:flex-row w-full'>
                        <div className='bg-header rounded-full p-2 w-17 h-17 md:ml-20'>
                            <LazyLoadImage effect='blur' src={logo} />
                        </div>
                        <div className='md:ml-17 mt-7 md:mt-0 md:w-9/12'>

                            <div className='flex flex-row mb-5'>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Kode Projek</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataLand?.project_code_var}</p>
                                </div>
                                <span className='mx-3'></span>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Nama Projek</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataLand?.project_name_var}</p>
                                </div>
                            </div>

                            <div className='flex flex-row'>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Kode Lahan</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataLand?.land_code_var}</p>
                                </div>
                                <span className='mx-3'></span>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Nama Lahan</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataLand?.land_name_var}</p>
                                </div>
                            </div>

                            <div className='flex flex-row'>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Luas Lahan</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataLand?.area_var} (Ha)</p>
                                </div>
                                <span className='mx-3'></span>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Alamat</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataLand?.address_land_txt}</p>
                                </div>
                            </div>

                            <div className='flex flex-row mb-5'>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Koordinat Lahan</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{Helper.getCenterMapFromStr(dataLand?.land_coordinates_txt).lat || '0'}, {Helper.getCenterMapFromStr(dataLand?.land_coordinates_txt).lng || '0'}</p>
                                </div>
                                <span className='mx-3'></span>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Kondisi Umum Lahan</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataLand?.general_condition_txt}</p>
                                </div>
                            </div>


                            <div className='flex flex-row'>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Komoditas</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>{dataCommodity?.commodity_name_var}</p>
                                </div>
                            </div>

                        {dataCulti.length > 0 && <h1 className='font-semibold'>Budidaya</h1>} 
                        {dataCulti.length > 0?
                            dataCulti.map((data, key) => 
                            <div className='mt-5' key={key}>
                                <div className='flex flex-row'>
                                    <div className={containerInput}>
                                        <label className='font-semibold'>Tanggal Aktivitas</label>
                                        <p className='ml-1.5 mt-0.5 mb-3'>{moment(data.activity_date_dtm).format('DD-MM-YYYY HH:mm:ss')}</p>
                                    </div>
                                    <span className='mx-3'></span>
                                    <div className={containerInput}>
                                        <label className='font-semibold'>Urutan Aktivitas</label>
                                        <p className='ml-1.5 mt-0.5 mb-3'>{data?.activity_order_var}</p>
                                    </div>
                                </div>

                                <div className='flex flex-row'>
                                    <div className={containerInput}>
                                        <label className='font-semibold'>Kebutuhan Aktivitas</label>
                                        <p className='ml-1.5 mt-0.5 mb-3'>{data.activity_needs_var}</p>
                                    </div>
                                    <span className='mx-3'></span>
                                    <div className={containerInput}>
                                        <label className='font-semibold'>Foto Aktivitas</label>
                                        {data?.activity_photo_var.split(', ').map((image, key) => 
                                            <LazyLoadImage key={key} src={image} effect='blur' className='w-30 mt-1 mb-2 border-1 border-gray-300 mr-5 rounded-lg' />
                                        )}
                                    </div>
                                </div>
                            </div>
                            )
                            :
                            <div className='flex flex-row mb-5'>
                                <div className={containerInput}>
                                    <label className='font-semibold'>Budidaya</label>
                                    <p className='ml-1.5 mt-0.5 mb-3'>-</p>
                                </div>
                            </div>
                        }


                        </div>
                    </div>
                    </div>
                </div>

            </div>
            

			{loader && <Loader />}
			{modalErr && <ModalMessage message={messageErr} onClose={()=>window.location.reload()} />}
        </div>
    )
}

export default LandDetail