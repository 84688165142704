import axios from 'axios'
import { baseURL } from './common-api'


export const getCultivationListByUser = async (userID) => {
    try {
        const data = await axios.get(baseURL + '/api/cultivation/get-list-user?user_id_int=' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewCultivation = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/cultivation/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateCultivation = async (commodityID, payload) => {
    try {
        const data = await axios.patch(baseURL + '/api/cultivation/update/' + commodityID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteCultivation = async (commodityID) => {
    try {
        const data = await axios.delete(baseURL + '/api/cultivation/delete/' + commodityID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const disableCultivation = async (commodityID) => {
    try {
        const data = await axios.delete(baseURL + '/api/cultivation/disable/' + commodityID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}