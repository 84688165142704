import { useEffect, useState, useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import moment from "moment"
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, Polygon  } from 'react-leaflet'
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faCloudRain, faExpand, faLock, faMapMarkerAlt, faSeedling, faSun, faThermometer, faThermometerHalf, faWind } from "@fortawesome/free-solid-svg-icons"

import { getDataMonitoringProbe } from "../../api/trxprobe-api"
import { getOneCommodityRef } from "../../api/commodity-api";
import Helper, { isLatitude, isLongitude } from "../../helpers/helper"

import pointLand from '../../assets/img/green-dot.ico'
import pointSampling from '../../assets/img/maps-agroobot.png'
import pointCurrent from '../../assets/img/marker-red.png'
import { SaveButton } from "../../components/button/CustomButton"
import Loader from "../../components/modal/Loader"
import ModalMessage from "../../components/modal/ModalMessage"
import { LetterIcon } from "../../components/icon/CustomIcon";
import UserMenu from "../../components/headers/UserMenu";
import ErrorField from "../../components/ErrorField";
import { getOneRecommendation, saveAnalyze } from "../../api/recommendation-api";


const icon = new L.Icon({
    iconUrl: pointLand,
    iconRetinaUrl: pointLand,
    iconSize: new L.Point(20, 20),
    // className: 'leaflet-div-icon'
});

const iconSampling = new L.Icon({
    iconUrl: pointSampling,
    iconRetinaUrl: pointSampling,
    iconSize: new L.Point(25, 30),
    // className: 'leaflet-div-icon'
});

const iconCurrent = new L.Icon({
    iconUrl: pointCurrent,
    iconRetinaUrl: pointCurrent,
    iconSize: new L.Point(25, 31),
    // className: 'leaflet-div-icon'
});

const DetailAnalytics = () => {
    const [loader, showLoader] = useState(true)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')
    const [dataStatic, setDataStatic] = useState(null)
    const [dataMobile, setDataMobile] = useState([])
    const [currentDataMobile, setCurrentDataMobile] = useState(null)
    const [dataLand, setDataLand] = useState(null)
    const [dataCommodity, setDataCommodity] = useState(null)
    const [isMontoring, setIsMonitoring] = useState(true)
    const [sampling, setSampling] = useState(1)
    const [probeCode, setProbeCode] = useState('')
    const [dataTaking, setDataTaking] = useState(0)

    const [center, setCenter] = useState([-6.175372, 106.827194])
    const [map, setMap] = useState(null);

    const user = JSON.parse(localStorage.getItem('agroo-user'))
	const analystID = user?.id_seq || 0
	const role = user?.user_group_id_int || 0

    const history = useHistory()
    const {land_code_var, recommendation_id} = useParams()

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue
    } = useForm({ criteriaMode: "all" });

    useEffect(() => {
        if(role !== 3){
            history.replace('/admin')
        }
    }, [history, role])


    const fetchCommodityStandard = useCallback(async (commodityName) => {
        const res = await getOneCommodityRef(commodityName)

        console.log("Get One Commodity Ref :", res)
        if(res.data){
            if(res.data.code === 0){
                setDataCommodity(res.data.data)
                showLoader(false)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert('Connection timeout...')
            fetchCommodityStandard()
        }
    }, [])


    const fetchDataMonitoring = useCallback (async (dataTaking) => {
        const res = await getDataMonitoringProbe(`land_code_var=${land_code_var}&data_taking_int=${dataTaking}`)

        console.log("MONITORING :", res)
        if(res.data){
            if(res.data.code === 0){
                fetchCommodityStandard(res.data.data?.land?.commodity_name_var)
                
                setDataStatic(res.data.data.static_probe[0])
                setCurrentDataMobile(res.data.data.mobile_probe[0])
                // setDataMobile(res.data.data.mobile_probe)
                setDataMobile(res.data.data.mobile_probe?.sort((a, b) => parseInt(a.id_seq)-parseInt(b.id_seq)))
                setSampling(res.data.data.mobile_probe?.length)
                
                setDataLand(res.data.data.land)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert('Connection timeout...')
            fetchDataMonitoring()
        }

    }, [land_code_var, fetchCommodityStandard])

    useEffect(() => {
        async function fetchRecommendation(){
            const res = await getOneRecommendation(recommendation_id)

            console.log('Get one recommendation :', res)
            if(res.data){
                if(res.data.code === 0){
                    setValue('analyst_notes_txt', res.data.data.analyst_notes_txt)
                    setValue('virus_control_txt', res.data.data.virus_control_txt)
                    setProbeCode(res.data.data.probe_code_var)
                    setDataTaking(res.data.data.data_taking_int)
                    fetchDataMonitoring(res.data.data.data_taking_int)
                }else{
                    setMessageErr(res.data.message)
                    showModalErr(true)
                }
            }else{
                alert('Connection timeout...')
                fetchRecommendation()
            }
        }

        fetchRecommendation()
    }, [recommendation_id, setValue, fetchDataMonitoring])


    useEffect(() => {
        if(dataLand){
            const centerMap = Helper.getCenterMapFromStr(dataLand.land_coordinates_txt)
            setCenter(centerMap)
            
            setTimeout(() => {
                map?.flyTo( 
                    [centerMap.lat, centerMap.lng], 
                    getZoom(dataLand.area_var), 
                    { animate: true }
                );
            }, 500);
        }
    }, [map, dataLand])


    const handleClickMarker = (e, data, sampling) => {
        setCurrentDataMobile(data)
        setSampling(sampling)
    }

    const getZoom = (areaWidth) => {
		const width = parseFloat(areaWidth)
		if(width < 3){
			return 18
		}else if(width < 20){
			return 17
		}else if(width < 60){
			return 16
		}else if(width < 210){
			return 15
		}else if(width < 750){
			return 14
		}else if(width < 2000){
			return 13
		}else if(width < 10000){
			return 12
		}else if(width < 75000){
            return 11
        }else if(width < 500000){
            return 10
        }else if(width < 1000000){
            return 9
        }else{
			return 8
		}
	}

    const onValid = async (dataForm) => {
        showLoader(true)
        const {analyst_notes_txt, virus_control_txt} = dataForm

        const payload = {
            analyst_notes_txt,
            virus_control_txt
        }

        const res = await saveAnalyze(analystID, probeCode, land_code_var, dataTaking, payload)
        
        console.log('Save analysis :', res)
        if(res.data){
            if(res.data.code === 0){
                history.goBack()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert('Connection timeout...')
        }
    }

    return (
        <>
        <div className='flex item-center flex-col bg-soft font-poppins text-xs'>
            <div className='rounded-2xl px-5 md:px-6 py-3.5 md:py-4 md:mx-5 shadow flex justify-between items-center'>
                <button className='text-lg text-gray-800 hover:text-lightcayn' onClick={()=>history.goBack()}>
                    <span><FontAwesomeIcon icon={faArrowLeft} /></span>
                </button>
                <UserMenu />
            </div>

            <div className='flex justify-center mt-5'>
                <h1 className='text-base font-semibold'>Analisa Lahan</h1>
            </div>

            <div className='flex flex-col md:flex-row p-3 md:p-6'>

                {/* MAP SAMPLING */}
                <div className='md:w-1/3'>
                    <div className='bg-white shadow-xl rounded-2xl p-5 font-semibold mb-5'>
                        <h1>Sampling Lahan</h1>
                        {dataLand &&
                        <MapContainer 
                            center={center} scrollWheelZoom={true} 
                            zoom={getZoom(dataLand.area_var)} whenCreated={setMap}
                            className='w-full h-50vh md:h-75vh rounded-xl z-0 mt-3'>
							<TileLayer
								maxZoom={20} zoomOffset={-1} id='' tileSize={512}
								attribution='by Agroobot'
                                subdomains={['mt0','mt1','mt2','mt3']}
								url='https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA'
								// url='http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}'          // street
								// url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'       // hybrid
                                // url='http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}'
								// url='http://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}'         // terrain
                                />

                            {/* area */}
                            <Polygon pathOptions={{color: 'lime'}} positions={Helper.generatelatLngFromStr(dataLand.land_coordinates_txt)} />
                            {dataLand.land_coordinates_txt.split(';').map((koor, key) => (
                                <Marker icon={icon} key={key}
                                    position={[isLatitude(koor.split(',')[0])? koor.split(',')[0] : 0,  isLongitude(koor.split(',')[1])? koor.split(',')[1] : 0]}>
                                </Marker>
                            ))}

                            {/* Sampling marker */}
                            {dataMobile && dataMobile.map((data, key) => (
                                <Marker position={[data?.latitude_flo || 0, data?.longitude_flo || 0]} icon={iconSampling} key={key} eventHandlers={{
                                    click: (e) => {
                                      handleClickMarker(e, data, key+1)
                                    },
                                  }}>
                                    <Popup>Selected</Popup>
                                </Marker>
                            ))}

                            {/* Current marker */}
                            <Marker position={[currentDataMobile?.latitude_flo || '', currentDataMobile?.longitude_flo || '']} icon={iconCurrent}>				{/* kiri atas */}
                                <Popup>Selected</Popup>
                            </Marker>

						</MapContainer>
                        }
                    </div>
                </div>

                <div className='md:w-1/3 md:mx-5'>
                    <div className='flex mb-5'>
                        <button className={`rounded-2xl shadow-xl p-5 w-full font-semibold hover:bg-darkcayn hover:text-white mr-2.5 ${isMontoring && 'bg-lightcayn text-white'}` } onClick={()=>setIsMonitoring(true)}>Data Monitoring</button>
                        <button className={`rounded-2xl shadow-xl p-5 w-full font-semibold hover:bg-darkcayn hover:text-white ml-2.5 ${!isMontoring && 'bg-lightcayn text-white'}` } onClick={()=>setIsMonitoring(false)}>Data Rekomendasi</button>
                    </div>
                    {isMontoring ?
                    
                    <>
                    {/* DATA IKLIM */}
                    <div className='mb-5 bg-white rounded-2xl shadow-xl'>
						<div className='bg-lightcayn text-white rounded-t-2xl px-5 py-2.5 md:py-3 font-medium flex justify-between' >
                            <p>Data Ikilm</p>
							<p>{moment(dataStatic?.time_on_dtm).format('DD-MM-YYYY HH:mm') || moment().format('DD-MM-YYYY HH:mm')}</p>
						</div>

                        <div className='flex justify-between md:justify-around px-2.5 py-5 md:p-5 border-b-1 border-gray-200'>
                            <div className='flex'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-2.5 text-xl'><FontAwesomeIcon icon={faMapMarkerAlt} /></span>
                                <div>
                                    <p>{Helper.capitalEachWord(dataLand?.city_name)}</p>
                                    <h1 className='font-bold text-sm md:text-base leading-tight'>{Helper.capitalEachWord(dataLand?.prov_name)}</h1>
                                </div>
                            </div>
                            <div className='flex'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-2.5 text-xl'><FontAwesomeIcon icon={faExpand} /></span>
                                <div>
                                    <p>Luas Lahan</p>
                                    <p className='font-bold text-sm md:text-base leading-tight'>{dataLand?.area_var} Ha</p>
                                </div>
                            </div>
                        </div>

                        <div className='px-2 py-5 md:p-5 text-center grid grid-cols-4'>
                            <div className='flex justify-center items-center'>
                                <span className='mr-1 text-base text-gray-600'><FontAwesomeIcon icon={faThermometerHalf} /></span>
                                <p className='font-bold text-base md:text-xl'>{dataStatic?.air_temperature_flo || '00'}<span className='text-xs font-normal'>*C</span></p>
                            </div>
                            <div className='flex justify-center items-center'>
                                <span className='mr-1 text-base text-gray-600'><FontAwesomeIcon icon={faSun} /></span>
                                <p className='font-bold text-base md:text-xl'>{dataStatic?.intensity_flo || '00'}<span className='text-xs font-normal'>lux</span></p>
                            </div>
                            <div className='flex justify-center items-center'>
                                <span className='mr-1 text-base text-gray-600'><FontAwesomeIcon icon={faCloudRain} /></span>
                                <p className='font-bold text-base md:text-xl'>{dataStatic?.rainfall_flo || '00'}<span className='text-xs font-normal'>mm</span></p>
                            </div>
                            <div className='flex justify-center items-center'>
                                <span className='mr-1 text-base text-gray-600'><FontAwesomeIcon icon={faWind} /></span>
                                <p className='font-bold text-base md:text-xl'>{dataStatic?.wind_velocity_flo || '00'}<span className='text-xs font-normal'>km/h</span></p>
                            </div>
                        </div>
                    </div>

                    {/* DATA TANAH */}
                    <div className='mb-5 bg-white rounded-2xl shadow-xl'>
						<div className='bg-darkcayn text-white rounded-t-2xl px-5 py-2.5 md:py-3 font-medium flex justify-between' >
                            <p>Data Tanah</p>
							<p>{moment(currentDataMobile?.time_on_dtm).format('DD-MM-YYYY HH:mm') || moment().format('DD-MM-YYYY HH:mm')}</p>
						</div>

                        <div className='ml-5 md:ml-7 py-5 grid grid-cols-3 '>
                            <div className='flex items-center '>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 mr-2 text-xl'>
                                    <LetterIcon letter='N' />
                                </span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-base md:text-lg leading-none'>{currentDataMobile?.n_value_flo || '00'}</h1>
                                    <p>N (Urea)</p>
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 mr-2 text-xl'>
                                    <LetterIcon letter='P' />
                                </span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-base md:text-lg leading-none'>{currentDataMobile?.p_value_flo || '00'}</h1>
                                    <p>P (TSP)</p>
                                </div>
                            </div>
                            <div className='flex items-center '>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 mr-2 text-xl'>
                                    <LetterIcon letter='K' />
                                </span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-base md:text-lg leading-none'>{currentDataMobile?.k_value_flo || '00'}</h1>
                                    <p>K (KCl)</p>
                                </div>
                            </div>
                        </div>
                        
                        <div className='mx-5 bg-gray-200' style={{height: 1}}></div>

                        <div className='ml-5 md:ml-7 py-5 grid grid-cols-3'>
                            <div className='flex items-center'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-1 md:mr-2 text-xl'><FontAwesomeIcon icon={faThermometerHalf} /></span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-base md:text-lg leading-none'>{currentDataMobile?.soil_temperature_flo || '00'}<span className='text-xs font-normal'>*C</span></h1>
                                    <p className='text-xss md:text-xs'>Suhu Tanah</p>
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-2 text-xl'><FontAwesomeIcon icon={faThermometer} /></span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-base md:text-lg leading-none'>{currentDataMobile?.ph_flo || '00'}</h1>
                                    <p>PH</p>
                                </div>
                            </div>
                            <div className='flex items-center'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-2 text-lg'><FontAwesomeIcon icon={faLock} /></span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-sm md:text-base leading-none'>KTK</h1>
                                    <p>Soon</p>
                                </div>
                            </div>
                        </div>

                        <div className='mx-5 bg-gray-200' style={{height: 1}}></div>

                        <div className='ml-5 md:ml-7 py-5 grid grid-cols-3 '>
                            <div className='flex item-center'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-2 text-lg'><FontAwesomeIcon icon={faLock} /></span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-sm md:text-base leading-none'>Ca<span className='text-xs font-normal'> </span></h1>
                                    <p>Soon</p>
                                </div>
                            </div>
                            <div className='flex item-center'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-2 text-lg'><FontAwesomeIcon icon={faLock} /></span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-sm md:text-base leading-none'>Mn<span className='text-xs font-normal'> </span></h1>
                                    <p>Soon</p>
                                </div>
                            </div>
                            <div className='flex item-center'>
                                <span className='bg-green_sidebar text-green-700 flex items-center justify-center rounded-full w-10 h-10 p-2 mr-2 text-lg'><FontAwesomeIcon icon={faLock} /></span>
                                <div className='text-left'>
                                    <h1 className='font-bold text-sm md:text-base leading-none'>Cl<span className='text-xs font-normal'> </span></h1>
                                    <p>Soon</p>
                                </div>
                            </div>
                        </div>

                    </div>

                    {/* INFO */}
                    <div className='bg-white rounded-2xl shadow-xl mb-5 md:mb-0'>
						<div className='bg-lightcayn text-white rounded-t-2xl px-5 py-2.5 md:py-3 font-medium' >
							<p>Info</p>
						</div>

                        <div className='flex items-center justify-between md:justify-around px-5 py-6'>
                            <div className='text-left'>
                                <p>Probe code</p>
                                <h1 className='text-lg font-bold' style={{lineHeight: 1.3}}>{currentDataMobile?.probe_code_var}</h1>
                            </div>
                            <div className='text-left'>
                                <p>Latitude</p>
                                <h1 className='text-lg font-bold' style={{lineHeight: 1.3}}>{currentDataMobile?.latitude_flo}</h1>
                            </div>
                            <div className='text-left'>
                                <p>Longitude</p>
                                <h1 className='text-lg font-bold' style={{lineHeight: 1.3}}>{currentDataMobile?.longitude_flo}</h1>
                            </div>
                        </div>
                    </div>

                    </>
                    :
                    <div className='bg-white p-5 rounded-2xl shadow-xl mb-5 md:mb-0'>
                        <div className='flex'>
                            <span className='bg-green_sidebar text-green-600 rounded-full flex justify-center items-center p-2 w-10 h-10 text-base'>
                                <FontAwesomeIcon icon={faSeedling} />
                            </span>
                            <div className='text-left ml-2.5'>
                                <p className='font-semibold'>Komoditas</p>
                                <p>{dataCommodity?.commodity_name_var || '-'}</p>
                            </div>
                        </div>
                        
                        <div className='my-5 bg-gray-200' style={{height: 1}}></div>

                        <div className='bg-green_sidebar rounded-2xl px-2 py-5 md:p-5'>
                            <div className='grid grid-cols-4 text-center mb-5'>
                                <h1 className='font-semibold'>Sampling-{sampling}</h1>
                                <h1>Acuan</h1>
                                <h1>Real</h1>
                                <h1>Needs</h1>
                            </div>
                            <div className='grid grid-cols-4 text-center'>
                                <div className='pt-0.5 grid grid-rows-4 gap-3 text-left'>
                                    <div className='flex items-center'>
                                        <LetterIcon letter='N' />
                                        <p className='ml-1 md:ml-2 text-xsm md:text-xs'>N (Urea)</p>
                                    </div>
                                    <div className='flex items-center'>
                                        <LetterIcon letter='P' />
                                        <p className='ml-1.5 md:ml-2 text-xsm md:text-xs'>P (TSP)</p>
                                    </div>
                                    <div className='flex items-center'>
                                        <LetterIcon letter='K' />
                                        <p className='ml-1.5 md:ml-2 text-xsm md:text-xs'>K (KCI)</p>
                                    </div>
                                    <div className='flex items-center'>
                                        <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                                        <p className='ml-4'>PH</p>
                                    </div>
                                </div>
                                <div className='pt-2 grid grid-rows-4 gap-4 text-center'>
                                    <h1>{dataCommodity?.n_value_var || '00'}</h1>
                                    <h1>{dataCommodity?.p_value_var || '00'}</h1>
                                    <h1>{dataCommodity?.k_value_var || '00'}</h1>
                                    <h1>{dataCommodity?.ph_value_var || ''}</h1>
                                </div>
                                <div className='pt-2 grid grid-rows-4 gap-4 text-center font-bold'>
                                    <h1 className={`${dataCommodity?.n_value_var - currentDataMobile?.n_value_flo>0? 'text-red-400': 'text-green-500'}`}>{currentDataMobile?.n_value_flo || '00'}</h1>
                                    <h1 className={`${dataCommodity?.p_value_var - currentDataMobile?.p_value_flo>0? 'text-red-400': 'text-green-500'}`}>{currentDataMobile?.p_value_flo || '00'}</h1>
                                    <h1 className={`${dataCommodity?.k_value_var - currentDataMobile?.k_value_flo>0? 'text-red-400': 'text-green-500'}`}>{currentDataMobile?.k_value_flo || '00'}</h1>
                                    <h1 className={`${dataCommodity?.ph_value_var - currentDataMobile?.ph_flo>0? 'text-red-400': 'text-green-500'}`}>{currentDataMobile?.ph_flo || '00'}</h1>
                                </div>
                                <div className='pt-2 grid grid-rows-4 gap-4 text-center rounded-xl bg-green-600 text-white font-bold'>
                                    <h1>{ Helper.getNeeds(dataCommodity?.n_value_var, currentDataMobile?.n_value_flo) }</h1>
                                    <h1>{ Helper.getNeeds(dataCommodity?.p_value_var, currentDataMobile?.p_value_flo) }</h1>
                                    <h1>{ Helper.getNeeds(dataCommodity?.k_value_var, currentDataMobile?.k_value_flo) }</h1>
                                    <h1>{ Helper.getNeedsPH(dataCommodity?.ph_value_var, currentDataMobile?.ph_flo).toFixed(1) }</h1>
                                </div>
                            </div>

                            {/* SKEMA */}
                            {/* <div className='mt-8'>
                                <div className='bg-white rounded-xl border-1 border-gray-200 mt-5 -mx-1 md:-mx-2.5'>
                                    <div className=' bg-green-900 text-white rounded-t-xl px-5 py-2.5 md:py-3 font-medium' >
                                        <p>Skema 1</p>
                                    </div>

                                    <div className='flex items-center justify-between p-5'>
                                        <div className='text-left'>
                                            <p className='text-xsm md:text-xs'>Krista MKP</p>
                                            <h1 className='text-xs md:text-base font-semibold' >{111.647} kg</h1>
                                        </div>
                                        <div className='text-left'>
                                            <p className='text-xsm md:text-xs'>OilPam EFB</p>
                                            <h1 className='text-xs md:text-base font-semibold' >{558.824} kg</h1>
                                        </div>
                                        <div className='text-left'>
                                            <p className='text-xsm md:text-xs'>YaraLiva Calcinit</p>
                                            <h1 className='text-xs md:text-base font-semibold' >{680.147} kg</h1>
                                        </div>
                                    </div>

                                    <div className='mx-5 bg-gray-200' style={{height: 1}}></div>

                                    <div className='flex justify-between items-center m-5 text-green-600'>
                                        <p className='text-xsm md:text-xs'>Estimasi Harga Pupuk</p>
                                        <p className='font-semibold text-sm md:text-base'>Rp10.009.191,00</p>
                                    </div>
                                </div>

                                <div className='bg-white rounded-xl border-1 border-gray-200 mt-5 -mx-1 md:-mx-2.5'>
                                    <div className=' bg-green-900 text-white rounded-xl px-5 py-2.5 md:py-3 font-medium' >
                                        <p>Skema 2</p>
                                    </div>
                                </div>
                            </div> */}

                        </div>

                    </div>
                    }

                    
                </div>


                {/* FORM ANLYST */}
                <form onSubmit={handleSubmit(onValid)} className='md:w-1/3 bg-white shadow-xl rounded-2xl p-5 mb-3 md:mb-0' style={{height: '100%'}}>
                    <h1 className='font-semibold'>Form Input Analisa Lahan</h1>

                    <div className='flex flex-col mt-6'>
                        <label>Kendali Virus</label>
                        <textarea className='outline-none border-2 border-gray-200 rounded-lg px-3 py-2 mt-2 focus:border-lightcayn' rows='10' 
                            {...register('virus_control_txt')} 
                        />
                    </div>
                    <div className='flex flex-col mt-6'>
                        <label>Catatan Analis</label>
                        <textarea className='outline-none border-2 border-gray-200 rounded-lg px-3 py-2 mt-2 focus:border-lightcayn' rows='10' 
                            {...register('analyst_notes_txt', { required: 'This field is required.'})} 
                        />
                        <ErrorField errors={errors} name='analyst_notes_txt' />
                    </div>

                    <div className='flex justify-end mt-6'>
                        <SaveButton type='submit' />
                    </div>
                </form>
            </div>

        <div className='flex items-center justify-between bg-white md:mx-5 p-5 md:p-6 border-t-2 border-l-2 border-r-2 border-gray-200 rounded-2xl text-xss md:text-xs'>
            <p>Design & Develop by Agroobot Dev Team</p>
            <p>{new Date().getFullYear()} © Agroobot v2.0</p>
        </div>
        </div>

        {loader && <Loader />}
        {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
        </>
    )
}

export default DetailAnalytics