import { useCallback, useEffect, useState } from "react";
import { CancelButton, SaveButton } from "../button/CustomButton"
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import ModalDeskripsiFitur from "./ModalDeskripsiFitur";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1'

const ModalFormFeature = ({data, onCancel, onSubmit}) => {
    const [modalTanah, showModalTanah] = useState(false)
    const [modalLahan, showModalLahan] = useState(false)
    const [modalTani, showModalTani] = useState(false)

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback ((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if(data) setdefaultValue(data)
    }, [data, setdefaultValue])

    const onValid = (dataForm) => {
        const {feature_name_var, feature_price_int, soil_data_var, land_data_var, agri_data_var, desc_txt} = dataForm

        const payload = { feature_name_var, feature_price_int, soil_data_var, land_data_var, agri_data_var, desc_txt }

        onSubmit(payload)
    }

    const handleReceiveFormDesc = (fitur) => {
        console.log(fitur)

        if(modalTanah){
            setValue('soil_data_var', fitur)
            showModalTanah(false)
        }else if(modalLahan){
            setValue('land_data_var', fitur)
            showModalLahan(false)
        }else if(modalTani){
            setValue('agri_data_var', fitur)
            showModalTani(false)
        } 
    }
    
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-10/12 md:w-1/2 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8'>{data? 'Form Ubah Fitur': 'Form Tambah Fitur Baru'}</h1>
                <form onSubmit={handleSubmit(onValid)}>
                    <div className='flex flex-col sm:flex-row'>
                        <div className={containerInput}>
                            <label>Nama Fitur</label>
                            <input type='text' className={inputText} placeholder='SUPER' 
                                {...register('feature_name_var', {
                                    required: 'This field is required.'
                                })}
                            />
                            <ErrorField errors={errors} name='feature_name_var' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Harga Fitur</label>
                            <input type='number' className={inputText} placeholder='299000' 
                                {...register('feature_price_int', {
                                    required: 'This field is required.'
                                })} 
                            />
                            <ErrorField errors={errors} name='feature_price_int' />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row my-3'>
                        <div className={containerInput}>
                            <label>Fitur Tanah</label>
                            <textarea className={inputText} placeholder='Unsur makro;pH level;Kelembapan;....' 
                                {...register('soil_data_var')} 
                            />
                            <button type='button' className='border-1 border-gray-500 rounded-lg p-2 mt-1 md:mt-2 hover:text-white hover:bg-lightcayn hover:border-transparent' onClick={() => showModalTanah(true)}>
                                <span className='mr-1'><FontAwesomeIcon icon={faPlusCircle} /></span>
                                <span>Tambah Fitur Tanah</span>
                            </button>
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Fitur Lahan</label>
                            <textarea className={inputText} placeholder='Monitoring lahan;Formulasi Treatment;...' 
                                {...register('land_data_var')} 
                            />
                            <button type='button' className='border-1 border-gray-500 rounded-lg p-2 mt-1 md:mt-2 hover:text-white hover:bg-lightcayn hover:border-transparent' onClick={() => showModalLahan(true)}>
                                <span className='mr-1'><FontAwesomeIcon icon={faPlusCircle} /></span>
                                <span>Tambah Fitur Lahan</span>
                            </button>
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row'>
                        <div className={containerInput}>
                            <label>Fitur Pertanian</label>
                            <textarea className={inputText} placeholder='Luas lahan;Clustering lahan;...' 
                                {...register('agri_data_var')}
                            />
                            <button type='button' className='border-1 border-gray-500 rounded-lg p-2 mt-1 md:mt-2 hover:text-white hover:bg-lightcayn hover:border-transparent' onClick={() => showModalTani(true)}>
                                <span className='mr-1'><FontAwesomeIcon icon={faPlusCircle} /></span>
                                <span>Tambah Fitur Pertanian</span>
                            </button>
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Deskripsi</label>
                            <textarea className={inputText} placeholder='Sistem iot monitoring portable yang sangat user friendly dan....' 
                                {...register('desc_txt')} 
                            />
                        </div>
                    </div>

                    <div className='flex justify-end mt-8'>
                        <CancelButton type='reset' onClick={onCancel} />
                        <SaveButton type='submit' />
                    </div>
                </form>
        
                {/* End Body */}
            </div>
            {/* End Modal Content */}

            {modalTanah && <ModalDeskripsiFitur onCancel={()=>showModalTanah(false)} onSubmit={handleReceiveFormDesc} title='Tanah' data={getValues('soil_data_var')} />}
            {modalLahan && <ModalDeskripsiFitur onCancel={()=>showModalLahan(false)} onSubmit={handleReceiveFormDesc} title='Lahan' data={getValues('land_data_var')} />}
            {modalTani && <ModalDeskripsiFitur onCancel={()=>showModalTani(false)} onSubmit={handleReceiveFormDesc} title='Pertanian' data={getValues('agri_data_var')} />}
        </div>
    )
}

export default ModalFormFeature