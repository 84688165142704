import axios from 'axios'
import { baseURL } from './common-api'

export const getThreatmentPDF = async (landCode, dataTaking) => {
  try {
      const payload = {
          land_code_var: landCode,
          data_taking_int: dataTaking
      }

      const res = await axios.post(baseURL + '/api/recommendation/pdf', payload, {
          headers: {
              authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
          },
          responseType: "blob"
      })
      const blob = new Blob(["\ufeff", res.data])
      const url = URL.createObjectURL(blob)
      const downloadLink = document.createElement("a")
      downloadLink.href = url
      downloadLink.download = `Download.pdf`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      return res
  } catch (error) {
      if(error.response) return error.response
      else return JSON.parse(JSON.stringify(error))  
  }
}

export const getThreatmentPDFDate = async (landCode, date) => {
  try {
      const payload = {
          land_code_var: landCode,
          date: date
      }

      const res = await axios.post(baseURL + '/api/recommendation/pdf', payload, {
          headers: {
              authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
          },
          responseType: "blob"
      })
      const blob = new Blob(["\ufeff", res.data])
      const url = URL.createObjectURL(blob)
      const downloadLink = document.createElement("a")
      downloadLink.href = url
      downloadLink.download = `Download.pdf`
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)

      return res
  } catch (error) {
      if(error.response) return error.response
      else return JSON.parse(JSON.stringify(error))  
  }
}

export const getAllToAnalyze = async () => {
    try {
        const data = await axios.get(baseURL + '/api/recommendation/get-all-analyst', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getAllAsTransaction = async () => {
    try {
        const data = await axios.get(baseURL + '/api/recommendation/get-trx', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getListByAnalyst = async (analystID) => {
    try {
        const data = await axios.get(baseURL + '/api/recommendation/get-list-analyst?analyst_id_int=' + analystID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneRecommendation = async (idSeq) => {
    try {
        const data = await axios.get(baseURL + '/api/recommendation/get-one?id_seq=' + idSeq, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getDetailRecommendation = async (idSeq) => {
    try {
        const data = await axios.get(baseURL + '/api/recommendation/get-detail/' + idSeq, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getThreatment = async (landCode, dataTaking) => {
    try {
        const payload = {
            land_code_var: landCode,
            data_taking_int: dataTaking
        }

        const data = await axios.post(baseURL + '/api/recommendation/get-threatment', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getThreatmentByDate = async (landCode, date) => {
    try {
        const payload = {
            land_code_var: landCode,
            date
        }

        const data = await axios.post(baseURL + '/api/recommendation/get-threatment-by-date', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


export const getAnalystNote = async (landCode, dataTaking) => {
    try {
        const data = await axios.get(baseURL + '/api/recommendation/get-one?land_code_var=' + landCode + '&data_taking_int=' + dataTaking, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}


//#region ANALYZE
export const startAnalyze = async (analystID, probeCode, landCode, dataTaking) => {
    try {
        const payload = {
            analyst: {
                analyst_id_int: analystID,
            },
            land: {
                probe_code_var: probeCode,
                land_code_var: landCode,
                data_taking_int: dataTaking
            }
        }
        const data = await axios.post(baseURL + '/api/analyst/start', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const saveAnalyze = async (analystID, probeCode, landCode, dataTaking, payloadAnalyze) => {
    try {
        const payload = {
            land: {
                probe_code_var: probeCode,
                land_code_var: landCode,
                data_taking_int: dataTaking
            },
            analyze: payloadAnalyze,
            analyst: {
                analyst_id_int: analystID,
            },
        }
        const data = await axios.post(baseURL + '/api/analyst/save', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const finishAnalyze = async (analystID, probeCode, landCode, dataTaking) => {
    try {
        const payload = {
            analyst: {
                analyst_id_int: analystID,
            },
            land: {
                probe_code_var: probeCode,
                land_code_var: landCode,
                data_taking_int: dataTaking
            }
        }
        const data = await axios.post(baseURL + '/api/analyst/finish', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
//#endregion