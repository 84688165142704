import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { faBan, faEdit, faEllipsisH, faTrash, faUserEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalTwoButton from '../modal/ModalTwoButton';
import { useHistory } from "react-router-dom";

export const DropdownActionUser = connect(
    state => ({
        user: state.user
    })
)(({data, roleID, halfLength, position, onDetail, onEdit, onDelete}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [confirmDelete, showConfirmDelete] = useState(false)

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
        if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleDelete = () => {
        showConfirmDelete(false)
        onDelete(data.id_seq)
    }

    return (
        <div className="relative inline-flex justify-center item-center">
            <button onClick={() => setDropdownOpen(!dropdownOpen)} className="relative z-5 block rounded-md focus:outline-none cursor-pointer">
                <span className='text-gray-500 text-lg hover:text-lightcayn'><FontAwesomeIcon icon={faEllipsisH} /></span>
            </button>

            {dropdownOpen &&
            <div
                className={`z-auto absolute right-7 ${position>halfLength? '-top-18': 'top-2'} top-2 min-w-max bg-white border border-gray-200 rounded shadow-lg overflow-hidden`}
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)} >
                    <div className="bg-white rounded-md shadow-xl z-auto">
                        {roleID === 4 &&
                        <span onClick={onDetail} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faEdit} />&nbsp; Lihat Detail
                        </span>
                        }
                        <span onClick={onEdit} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faUserEdit} />&nbsp; Ubah
                        </span>
                        <span onClick={() => showConfirmDelete(true)} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faBan} />&ensp; Hapus
                        </span>
                    </div>
                </div>
            </div>
            }

            {confirmDelete && <ModalTwoButton message='Delete this item?' onOK={handleDelete} onCancel={() => showConfirmDelete(false)} />}
        </div>
    )
})


export const DropdownLand = ({data, onEdit, onDelete, userRoleId = null}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [confirmDelete, showConfirmDelete] = useState(false)
    const history = useHistory()

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
        if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleEdit = () => {
        setDropdownOpen(false)
        onEdit()
    }

    const handleDelete = () => {
        showConfirmDelete(false)
        onDelete(data.id_seq)
    }

    const handleViewMonitoring = () => {
        history.push(`/admin/lahan-monitoring/${data.land_code_var}`);
    }

    return (
        <div className="relative inline-flex justify-center item-center">
            <button onClick={() => setDropdownOpen(!dropdownOpen)} className="relative z-5 block rounded-md focus:outline-none cursor-pointer">
                <span className='text-gray-500 text-lg hover:text-lightcayn'><FontAwesomeIcon icon={faEllipsisH} /></span>
            </button>

            {dropdownOpen &&
            <div
                className={`z-auto absolute right-7 top-1 min-w-max bg-white border border-gray-200 rounded shadow-lg overflow-hidden`}
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)} >
                    <div className="bg-white rounded-md shadow-xl z-auto">
                        <span onClick={handleEdit} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faEdit} />&nbsp; Ubah
                        </span>
                        {
                            userRoleId && userRoleId === 1 &&
                            <span onClick={handleViewMonitoring} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                                <FontAwesomeIcon icon={faEye} />&nbsp; View Monitoring
                            </span>
                        }
                        <span onClick={() => showConfirmDelete(true)} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faTrash} />&ensp;&nbsp;Hapus
                        </span>
                    </div>
                </div>
            </div>
            }

            {confirmDelete && <ModalTwoButton message='Hapus data ini?' onOK={handleDelete} onCancel={() => showConfirmDelete(false)} />}
        </div>
    )
}