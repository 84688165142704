import axios from 'axios'
import { baseURL } from './common-api'

//#region LAND TYPE-REF
export const getAllLandtypeRef = async () => {
    try {
        const data = await axios.get(baseURL + '/api/landtype-ref/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneLandtypeRef = async (landtypeName) => {
    try {
        const data = await axios.get(baseURL + '/api/landtype-ref/get-one?landtype_name_var=' + landtypeName, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const createNewLandtypeRef = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/landtype-ref/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const updateLandtypeRef = async (landtypeRefID, payload) => {
    try {
        const data = await axios.patch(baseURL + '/api/landtype-ref/update/' + landtypeRefID, payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteLandtypeRef = async (landtypeRefID) => {
    try {
        const data = await axios.delete(baseURL + '/api/landtype-ref/delete/' + landtypeRefID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}
//#endregion