import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import { ButtonAdd } from "../../components/button/CustomButton"
import { DropdownLand } from "../../components/dropdown/SimpleDropdown"
import TableUser from "../../components/tables/Table"

import { faBoxes, faCheckCircle, faListAlt, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { createLandCommodity, disableLandCommodity, getAllLandCommodity, getAllLandCommodityByProject, updateLandCommodity } from "../../api/landarea-api"
import Loader from "../../components/modal/Loader"
import ModalFormProjectLandManagement from "../../components/modal/ModalFormProjectLandManagement"
import ModalMessage from "../../components/modal/ModalMessage"


const ProjectLahanManagement = ({user, currentProjectCode}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [dataLahan, setDataLahan] = useState([])
    const [filterData, setFilterData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const history = useHistory()

    const fetchData = useCallback(async (project_code_var) => {
        showLoader(true)
        const res = await getAllLandCommodityByProject(project_code_var)
        
        console.log('Get Lahan :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataLahan(res.data.data)
                setFilterData(res.data.data)
            }else if(res.data.code === 99){
                showLoader(true)
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history])


    useEffect(() => {
        fetchData(currentProjectCode)
    }, [fetchData, currentProjectCode])

    const handleEdit = (selectedData) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (payload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            payload.created_by_var = user.fullname_var
            res = await createLandCommodity(payload)
        }else{
            payload.updated_by_var = user.fullname_var
            res = await updateLandCommodity(selectedData.land_code_var, payload)
        }

        console.log('Create/Update Lahan :', res)
        
        if(res.data){
            if(res.data.code === 0){
                fetchData(currentProjectCode)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }


    const handleDeleteData = async (id_seq) => {
        showLoader(true)

        const res = await disableLandCommodity(id_seq)

        console.log('Delete Lahan :', res)
        if(res.data){
            if(res.data.code === 0){
                fetchData(currentProjectCode)
            }else if(res.data.code === 99){
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showLoader(false)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        showLoader(false)
        setIsUpdate(false)
        showModalForm(false)
        setSelectedData(null)
    }

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataLahan]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.land_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.land_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.commodity_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.area_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.land_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.dis_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.city_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.prov_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.fullname_var?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataLahan)
        }
    }

    const columns = [
        {
            Header: () => <div className='p-4 text-left'>Lahan</div>,
            Footer: 'Lahan',
            accessor: 'land_code_var',
            Cell: ({ value, row }) => {
                const data = row.original
                return (
                  <div className='text-left pl-2'>
                    [{value}]
                    {data?.land_name_var} <br />
                    - {data?.land_type_name}
                  </div>
                )
            }  
        },
        {
            Header: 'Komoditas',
            Footer: 'Komoditas',
            accessor: 'commodity_name_var'
        },
        {
            Header: 'Luas (Ha)',
            Footer: 'Luas (Ha)',
            accessor: 'area_var',
            Cell: ({value}) => (
                <span>{parseFloat(value).toFixed(2)}</span>
            )
        },
        // {
        //     Header: 'Alamat',
        //     Footer: 'Adress',
        //     accessor: 'address_land_txt'
        // },
        {
            Header: 'Petani',
            Footer: 'Petani',
            accessor: 'fullname_var'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}) => (
                parseInt(value)===1? 
                <div className='bg-green_sidebar text-green-800 float-left px-2 py-1 text-center rounded-lg font-medium'>Active</div>
                :
                <div className='bg-red-100 text-red-800 float-left px-2 py-1 text-center rounded-lg font-medium'>Inactive</div>
            )
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEdit(data)} onDelete={() => handleDeleteData(data.land_code_var)} userRoleId={user?.user_group_id_int} />
            }
        }
    ]

    return (
        <div>
            <div className='flex justify-between items-center mb-3'>
                <ButtonAdd onClick={() => showModalForm(true)} />
                <input type='search' onChange={handleSearch} placeholder='Cari Lahan' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
            </div>
            <TableUser dataTable={filterData} columnTable={columns} />

            {loader && <Loader />}
            {modalForm && <ModalFormProjectLandManagement data={selectedData} currentProjectCode={currentProjectCode} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (ProjectLahanManagement)