import axios from 'axios'
import { baseURL } from './common-api'


const headers = {
    authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
    'Content-Type': 'multipart/form-data'
}

export const createCustomerPhoto = async (payload) => {
    try {
        const data = await axios.post(baseURL + '/api/customer-photo/create', payload, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                // 'Content-Type': 'multipart/form-data'
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const editCustomerPhoto = async (payload) => {
    try {
        const data = await axios.patch(baseURL + '/api/customer-photo/update', payload, {headers})
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getAllCustomerPhoto = async () => {
    try {
        const data = await axios.get(baseURL + '/api/customer-photo/get-all', {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const getOneCustomerPhoto = async (userID) => {
    try {
        const data = await axios.get(baseURL + '/api/customer-photo/get-one?user_id_int=' + userID, {
            headers: {
                authorization: `Bearer ${localStorage.getItem("agroo-token")}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}

export const deleteCustomerPhoto = async (userID) => {
    try {
        const data = await axios.delete(baseURL + '/api/customer-photo/delete?user_id_int=' + userID, {headers})
        return data
    } catch (error) {
        if(error.response) return error.response
        else return JSON.parse(JSON.stringify(error))  
    }
}