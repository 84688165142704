import { faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react"
import { CancelButton, SaveButton } from "../button/CustomButton"


const ModalDeskripsiFitur = ({data, onCancel, onSubmit, title}) => {
    const [arrDesc, setArrDesc] = useState([
        {desc: 'desc_', key: 1 + new Date().getTime(), value: ''},
        {desc: 'desc_', key: 2 + new Date().getTime(), value: ''}
    ])

    useEffect(() => {
        if(data){
            const arr = data.split(';')
            const newArr = []
            let count = 0
            arr.forEach(async value => {
                count += 1
                const obj = {desc: 'desc_', value: value, key: count}
                await newArr.push(obj)
            })
            setArrDesc(newArr)
        }
    }, [data])

    const handleAddKordinat = () => {
        setArrDesc([...arrDesc, {desc: 'desc_', value:'', key: new Date().getTime()}])
    }

    const removeItem = (key) => {
        setArrDesc(arrDesc.filter(data => data.key !== key))
    }

    const handleChange = (e, key) => {
        const indexConversation = arrDesc.findIndex((obj => obj.key === key))
        const conversations = [ ...arrDesc ]
        conversations[indexConversation].value = e.target.value
        setArrDesc(conversations)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        let deskripsi = ''
        arrDesc.forEach(({desc, key}) => {
            if(e.target[desc + key].value !== ''){
                deskripsi += `${e.target[desc + key].value};`
            }
        })

        // console.log(deskripsi.substring(0, deskripsi.length -1))
        onSubmit(deskripsi.substring(0, deskripsi.length -1))                                   // hapus last char (;)
    }


    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-50">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-10/12 md:w-1/3 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8 sticky inset-0'>Form Input Deskripsi Fitur {title}</h1>
                
                <form onSubmit={handleSubmit}>
                    {arrDesc.map(({desc, key, value}, index) => (
                    <div className='flex items-center mb-3' key={index}>
                        {/* <h1>{data.key}</h1> */}
                        <div className='flex flex-row w-full'>
                            <input className='outline-none border-1 border-gray-200 rounded-lg py-3 px-3 w-full' onChange={(e) => handleChange(e, key)} value={value} name={desc + key} placeholder='Monitoring lahan' />
                        </div>
                        <span className='w-10 h-10 bg-red-600 hover:bg-red-900 flex items-center justify-center text-white text-base rounded-lg ml-2 cursor-pointer' onClick={() => removeItem(key)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                    </div>
                    ))}

                    <p className='mt-5 text-lightcayn hover:text-darkcayn cursor-pointer' onClick={handleAddKordinat}>+ Tambah Fitur</p>
                    
                    <div className='flex justify-end mt-3'>
                        <CancelButton type='reset' onClick={onCancel} />
                        <SaveButton type='submit' />
                    </div>
                </form>

            </div>
            {/* End Modal Content */}
        </div>
    )
}

export default ModalDeskripsiFitur