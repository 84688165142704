import { useCallback, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import { useForm } from "react-hook-form";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { createNewLand, disableLand, getLandListByUser, updateLand } from "../../../api/landarea-api"
import { getAllProject } from "../../../api/project-api"
import Location from "../../../helpers/location"
import ErrorField from "../../../components/ErrorField"

import { ButtonAdd, CancelButton, SaveButton } from "../../../components/button/CustomButton";
import { DropdownLand } from "../../../components/dropdown/SimpleDropdown";

import Loader from "../../../components/modal/Loader";
import ModalFormKordinat from "../../../components/modal/ModalFormKordinat"
import ModalMessage from "../../../components/modal/ModalMessage"
import ModalOptionKoordinat from "../../../components/modal/ModalOptionLand"
import ModalDrawMaps from "../../../components/modal/ModalDrawMaps"

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1'
const selectStyle = 'border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'

const Land = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')
    const [modalKordinat, showModalKordinat] = useState(false)
    const [modalDraw, showModalDraw] = useState(false)
    const [modalOption, showModalOption] = useState(false)

    const [isUpdate, setIsUpdate] = useState(false)
    const [formAdd, showFormAdd] = useState(false)

    const [dataProject, setDataProject] = useState([])
    const [dataLand, setDataLand] = useState([])
    const [selectedLand, setSelectedLand] = useState({})

    const [province, setProvince] = useState([])
    const [provID, setProvID] = useState('')
    const [cities, setCities] = useState([])
    const [cityID, setCityID] = useState('')
    const [districts, setDistricts] = useState([])
    const [districtID, setDistrictID] = useState('')


    const params = useParams()
    const history = useHistory()

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
        reset
    } = useForm({ criteriaMode: "all" });


    const fetchLandArea = useCallback( async () => {
        const res = await getLandListByUser(params.id_seq)

        console.log(res)
        resetConatiner()
        if(res.data){
            if(res.data.code === 0){
                setDataLand(res.data.data)
            }else{
                showModalErr(true)
                setMessageErr(res.data.message)
                history.replace('/admin/user')
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
            window.location.reload()
        }
    }, [params.id_seq, history])

    const fetchProject = useCallback(async () => {
        showLoader(true)
        const res = await getAllProject()
        
        console.log("Get All Project :", res)
        // resetConatiner()
        if(res.data){
            if(res.data.code === 0){
                setDataProject(res.data.data)
                fetchLandArea()
            }else{
                showModalErr(true)
                setMessageErr(res.data.message)
                history.replace('/admin/user')
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
            window.location.reload()
        }
    }, [history, fetchLandArea])

    useEffect(() => {
        fetchProject()
    }, [fetchProject])


    useEffect(() => {
        async function fetchLocation(){
            const prov = await Location.getProvinces()
            setProvince(prov)

            const city = await Location.allCity()
            setCities(city)

            const districts = await Location.allDistricts()
            setDistricts(districts)
        }
        fetchLocation()
    }, [])


    const handleNewData = () => {
        setIsUpdate(false)
        setSelectedLand(false)
        showFormAdd(true)
        reset()
    }

    const onValid = (dataForm) => {        
        showLoader(true)
        const {
            project_code_var, land_name_var, province_var, city_var, district_var, address_land_txt, postal_code_int, 
            area_var, land_ownership_status_var, land_coordinates_txt, general_condition_txt, managing_farmer_var
        } = dataForm
        
        var payload = {
            project_code_var, land_name_var, province_var, city_var, district_var, address_land_txt, postal_code_int, 
            area_var, land_ownership_status_var, land_coordinates_txt, general_condition_txt, managing_farmer_var
        }

        submitData(payload)
    }


    const handleEditData = async  (selectedData) => {
        await Promise.all(Object.keys(selectedData).map(async key => {
            await setValue(key, selectedData[key], { shouldValidate: true })
        }))
        setSelectedLand(selectedData)
        setProvID(selectedData.province_var); setCityID(selectedData.city_var); setDistrictID(selectedData.district_var)
        showFormAdd(true); setIsUpdate(true)
    }

    const submitData = async (data) => {
        data.user_id_int = params.id_seq

        let res = null
        if(!isUpdate){
            data.created_by_var = user.fullname_var
            res = await createNewLand(data)
        }else{
            data.updated_by_var = user.fullname_var
            res = await updateLand(selectedLand.id_seq, data)
        }
        
        console.log('Create/Update Land:', res)
        if(res.data){
            if(res.data.code === 0){
                // fetchProject()
                if(isUpdate) window.location.reload()
                else fetchProject()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteData = async (landID) => {
        const res = await disableLand(landID)

        console.log("DELETE LAND :", res)
        if(res.data){
            if(res.data.code === 0){
                showLoader(true)
                window.location.reload()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetConatiner = () => {
        setIsUpdate(false)
        showFormAdd(false)
        showLoader(false)
        setProvID(null);setCityID(null);setDistrictID(null)
    }

    const handleReceiveKordinat = (koor, areaLength) => {
        setValue('land_coordinates_txt', koor)
        if(areaLength){
            setValue('area_var', areaLength)
        }
        
        showModalKordinat(false)
        showModalOption(false)
        showModalDraw(false)
    }
    
    return (
        <div>
            <div className='flex justify-between items-center mb-6'>
                <h1 className='font-semibold text-sm'>Data Lahan</h1>
                {dataLand.length > 0 && !isUpdate && !formAdd &&
                <ButtonAdd onClick={handleNewData} />
                }
            </div>

            {/* FORM */}
            {!formAdd?
                dataLand.length > 0 ?
                dataLand.map((data, key) => (
                <div key={key} className='p-6 bg-white rounded-2xl shadow-2xl mb-5'>
                    <div className='flex justify-between items-center mb-4 pb-1 border-b-1 border-gray-300'>
                        <h1 className='font-semibold'>Detail Lahan ({data.land_name_var})</h1>
                        <DropdownLand data={data} onEdit={() => handleEditData(data)} onDelete={handleDeleteData} />
                    </div>
                    <form>
                        <fieldset disabled={true}>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Projek</label>
                                    <input type='text' name='project_code_var' defaultValue={`[${dataProject.find(dataProj => dataProj.project_code_var === data.project_code_var)?.project_code_var}] ${dataProject.find(dataProj => dataProj.project_code_var === data.project_code_var)?.project_name_var}`} className={inputText} required />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Nama Lahan</label>
                                    <input type='text' name='land_name_var' defaultValue={data.land_name_var} className={inputText}/>
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Provinsi</label>
                                    <input type='text' name='province_var' defaultValue={province.find(({prov_id}) => parseInt(prov_id)===parseInt(data.province_var))?.prov_name} className={inputText}  />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Kabupaten/Kota</label>
                                    <input type='text' name='city_var' defaultValue={cities.find(({city_id}) => parseInt(city_id)===parseInt(data.city_var))?.city_name} className={inputText}/>
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Kecamatan</label>
                                    <input type='text' name='district_var' defaultValue={districts.find(({dis_id}) => parseInt(dis_id)===parseInt(data.district_var))?.dis_name} className={inputText} />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Alamat Lahan</label>
                                    <textarea className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' name='address_land_txt' defaultValue={data.address_land_txt} />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Kode Pos</label>
                                    <input type='text' name='postal_code_int' defaultValue={data.postal_code_int} className={inputText} />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Koordinat Lahan</label>
                                    <textarea defaultValue={data.land_coordinates_txt} name='land_coordinates_txt' className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' />
                                </div>
                            </div>
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Luas Lahan (Ha)</label>
                                    <input type='text' className={inputText} defaultValue={data.area_var} name='area_var' />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Status Kepemilikan Tanah</label>
                                    <input type='text' className={inputText} defaultValue={data.land_ownership_status_var} name='land_ownership_status_var' />
                                </div>
                            </div> 
                            <div className='flex flex-col sm:flex-row mb-3'>
                                <div className={containerInput}>
                                    <label>Petani Pengelola</label>
                                    <input type='text' className={inputText} defaultValue={data.managing_farmer_var} name='managing_farmer_var' />
                                </div>
                                <span className='mx-5 mb-3 sm:mb-0'></span>
                                <div className={containerInput}>
                                    <label>Kondisi Umum Lahan</label>
                                    <input type='text' className={inputText} defaultValue={data.general_condition_txt} name='general_condition_txt' />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                ))
                :
                <div className='flex flex-col items-center justify-center' style={{minHeight: '50vh'}}>
                    <p className='font-medium mb-3'>Belum ada data lahan</p>
                    <ButtonAdd onClick={() => showFormAdd(true)} />
                </div>
            :
            <form className='mt-5' onSubmit={handleSubmit(onValid)}>
                <fieldset disabled={false}>
                    <h2 className='font-semibold mb-5'>{isUpdate? 'Ubah Lahan' : 'Tambah Lahan Baru'}</h2>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Projek</label>
                            <select {...register("project_code_var", { required: "Project is required."})} className={selectStyle}>
                                <option value=''>- Pilih Projek -</option>
                                {dataProject?.map((data, key) => (
                                    <option key={key} value={data.project_code_var}>[{data.project_code_var}] {data.project_name_var}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='project_code_var' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        
                        <div className={containerInput}>
                            <label>Nama Lahan</label>
                            <input type='text' className={inputText} placeholder='Lahan 1' {...register("land_name_var", { required: "This field is required."})} />
                            <ErrorField errors={errors} name='land_name_var' />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Provinsi</label>
                            <select {...register("province_var", { required: "This field is required."})} value={provID} onChange={(e)=>setProvID(e.target.value)} className={selectStyle}>
                                <option value=''>- Pilih Provinsi -</option>
                                {province?.map((data, key) => (
                                    <option key={key} value={data.prov_id}>{data.prov_name}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='province_var' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Kabupaten/Kota</label>
                            <select {...register("city_var", { required: "This field is required."})} value={cityID} onChange={(e)=>setCityID(e.target.value)} className={selectStyle}>
                                <option value=''>- Pilih Kabupaten/Kota -</option>
                                {cities?.filter(({prov_id}) => parseInt(prov_id)=== parseInt(provID))?.map((data, key) => (
                                    <option key={key} value={data.city_id}>{data.city_name}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='city_var' />
                        </div>
                        
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Kecamatan</label>
                            <select {...register("district_var", { required: "This field is required."})} value={districtID} onChange={(e)=>setDistrictID(e.target.value)} className={selectStyle}>
                                <option value=''>- Pilih Kecamatan -</option>
                                {districts?.filter(({city_id}) => parseInt(city_id)===parseInt(cityID)).map((data, key) => (
                                    <option key={key} value={data.dis_id}>{data.dis_name}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='district_var' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Alamat Lahan</label>
                            <textarea className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' {...register("address_land_txt", { required: "Address is required."})} placeholder='jl...' />
                            <ErrorField errors={errors} name='address_land_txt' />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Postal Code</label>
                            <input type='number' className={inputText} placeholder='27266' {...register("postal_code_int", {required: 'This field is required'})} />
                            <ErrorField errors={errors} name='postal_code_int' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Koordinat Lahan</label>
                            <textarea {...register("land_coordinates_txt", { required: "Coordinates is required."})} placeholder='106.90906,-3.67875;102.6334,-6.4334' className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' />
                            <ErrorField errors={errors} name='land_coordinates_txt' />
                            <button type='button' className='border-1 border-gray-500 rounded-lg p-2 mt-1 md:mt-2 hover:text-white hover:bg-lightcayn hover:border-transparent' onClick={() => showModalOption(true)}>
                                <span className='mr-1'><FontAwesomeIcon icon={faPlusCircle} /></span>
                                <span>{isUpdate?'Ubah':'Tambah'} Koordinat</span>
                            </button>
                        </div>
                    </div> 
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Luas Lahan (Ha)</label>
                            <input type='text' className={inputText} placeholder='20' {...register("area_var", { required: "Land area is required."})} />
                            <ErrorField errors={errors} name='area_var' />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Status Kepemilikan Tanah</label>
                            <input type='text' className={inputText} placeholder='Pribadi/Sewa' {...register("land_ownership_status_var")} />
                        </div>
                    </div>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Petani Pengelola</label>
                            <input type='text' className={inputText} placeholder='Petani Pengelola' {...register("managing_farmer_var")} />
                        </div>
                        <span className='mx-5 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Kondisi Umum Lahan</label>
                            <input type='text' className={inputText} placeholder='Baik' {...register("general_condition_txt")} />
                        </div>
                    </div>
                </fieldset>
        
                <div className='flex justify-end mt-2'>
                    <CancelButton type='reset' onClick={resetConatiner} />
                    <SaveButton type='submit' />
                </div>
            </form>
            
            }

            {loader && <Loader />}
            {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
            {modalOption && <ModalOptionKoordinat onCancel={()=>showModalOption(false)} onClickManual={()=>showModalKordinat(true)} onClickDraw={()=>showModalDraw(true)} />}
            {modalKordinat && <ModalFormKordinat onCancel={() => showModalKordinat(false)} onSubmit={handleReceiveKordinat} data={getValues('land_coordinates_txt')} />}
            {modalDraw && <ModalDrawMaps onCancel={()=>showModalDraw(false)} onSubmit={handleReceiveKordinat} data={getValues('land_coordinates_txt')} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(Land)