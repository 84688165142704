import { faArrowDown, faArrowUp, faSeedling, faThermometer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { getThreatmentByDate, getThreatmentPDFDate } from "../../../api/recommendation-api";
import { LetterIcon } from "../../../components/icon/CustomIcon";

const LandFertilizer = ({landCode, date}) => {
  const [loader, showLoader] = useState(true);
  const [modalErr, showModalErr] = useState(false);
  const [messageErr, setMessageErr] = useState("");

  const [sampling, setSampling] = useState(0);
  const [active, setActive] = useState(0);

  const [allData, setAllData] = useState([]);
  const [currentDataMobile, setCurrentDataMobile] = useState(null);
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    async function fetchThreatment() {
      const res = await getThreatmentByDate(landCode, date);

      console.log("Get Threatment :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setCurrentDataMobile(
            res.data.data.list_sampling[res.data.data.list_sampling.length - 1]
          );
          setSummary(res.data.data.summary);
          setAllData(res.data.data.list_sampling);
          setSampling(res.data.data.list_sampling.length);
          showLoader(false);
        } else {
          setMessageErr(res.data.message);
          showModalErr(true);
          showLoader(false);
        }
      } else {
        alert("Connection timeout...");
      }
    }
    fetchThreatment()
  }, [landCode, date])

  const onDownload = useCallback(() => {
    // console.log({date})
    getThreatmentPDFDate(landCode, date)
  }, [landCode, date])
 
  return (
      <div className='bg-white px-3 py-5 md:p-5 rounded-2xl shadow-lg'>
          <div className='flex justify-between items-center'>
              <div className='flex'>
                  <span className='bg-green_sidebar text-green-600 rounded-full flex justify-center items-center p-2 w-10 h-10 text-base'>
                      <FontAwesomeIcon icon={faSeedling} />
                  </span>
                  <div className='text-left ml-2.5'>
                      <p className='font-semibold'>Komoditas</p>
                      <p>{summary?.commodity_name_var || '-'}</p>
                  </div>
              </div>
              {/* <button className='border-2 border-green-700 text-green-700 bg-green_sidebar rounded-lg px-2 py-1.5 flex items-center -mr-2'>
                  <span><FontAwesomeIcon icon={faArrowDown} /></span>
                  <p className='ml-1.5'>Download PDF</p>
              </button> */}
          </div>
          
          <div className='my-5 bg-gray-200' style={{height: 1}}></div>
          <div className="text-center mb-3">
            <b>Jenis Tanah:</b> <br />
            {summary?.land_type || 'Belum di konfigurasi'} <br />
            {summary?.land_type && (
              <>
                Bulk Density: {
                  summary?.density_top === summary?.density_bottom
                    ? Intl.NumberFormat("id-ID").format(summary?.density_bottom)
                    : `${Intl.NumberFormat("id-ID").format(summary?.density_top)} - ${Intl.NumberFormat("id-ID").format(summary?.density_bottom)}`
                }
              </>
            )}
          </div>
          <div className='bg-green_sidebar rounded-2xl px-2 py-5 font-medium'>
              <div className='grid grid-cols-5 text-center mb-5 text-xsm md:text-xs'>
                  <h1 className='font-semibold'>Rata rata kebutuhan</h1>
                  <h1>Kondisi tanah</h1>
                  <h1>Acuan</h1>
                  <h1>
                    Kebutuhan
                  </h1>
                  <h1>Pupuk</h1>
              </div>
              <div className='grid grid-cols-5 text-center'>
                  <div className='pt-0.5 grid grid-rows-4 gap-3 text-left'>
                      <div className='flex items-center'>
                          <div className="hidden lg:block">
                            <LetterIcon letter='N' />
                          </div>
                          <p className='ml-2 md:ml-1.5 text-xsm md:text-xsm'>N (Urea)</p>
                      </div>
                      <div className='flex items-center'>
                          <div className="hidden lg:block">
                            <LetterIcon letter='P' />
                          </div>

                          <p className='ml-2 text-xsm md:text-xsm'>P (TSP)</p>
                      </div>
                      <div className='flex items-center'>
                          <div className="hidden lg:block">
                            <LetterIcon letter='K' />
                          </div>
                          <p className='ml-2 text-xsm md:text-xsm'>K (KCI)</p>
                      </div>
                      <div className='flex items-center'>
                          <div className="hidden lg:block">
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                          </div>
                          <p className='ml-4'>PH</p>
                      </div>
                      <div className='flex items-center'>
                          <div className="hidden lg:block">
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                          </div>
                          <p className='ml-4'>EC</p>
                      </div>
                      <div className='flex items-center'>
                          <div className="hidden lg:block">
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                          </div>
                          <p className='ml-4'>Humidity</p>
                      </div>
                      <div className='flex items-center'>
                          <div className="hidden lg:block">
                            <span className='text-green-600 ml-0.5 text-base'><FontAwesomeIcon icon={faThermometer} /></span>
                          </div>
                          <p className='ml-4'>Suhu Tanah</p>
                      </div>
                  </div>
                  <div className='py-2 grid grid-rows-4 gap-4 text-center font-bold'>
                      <h1>{summary?.data_n || "00"} ({summary?.actual_n || '00'})</h1>
                      <h1>{summary?.data_p || "00"} ({summary?.actual_p || '00'})</h1>
                      <h1>{summary?.data_k || "00"} ({summary?.actual_k || '00'})</h1>
                      <h1>{summary?.data_ph || '00'}</h1>
                      <h1>{summary?.data_ec || '00'}</h1>
                      <h1>{summary?.data_soil_humidity || '00'}</h1>
                      <h1>{summary?.data_soil_temperature || '00'}</h1>
                  </div>
                  <div className='py-2 grid grid-rows-4 gap-4 text-center'>
                      <h1>{summary?.var_n || '00'}</h1>
                      <h1>{summary?.var_p || '00'}</h1>
                      <h1>{summary?.var_k || '00'}</h1>
                      <h1>{summary?.var_ph || '00'}</h1>
                      <h1>{summary?.var_ec || '00'}</h1>
                      <h1>{summary?.var_soil_humidity || '00'}</h1>
                      <h1>{summary?.var_soil_temperature || '00'}</h1>
                  </div>
                  <div className='py-2 grid grid-rows-4 gap-4 text-center rounded-xl font-bold'>
                      <h1 className={Number(summary?.needs_n) === 0 ? "text-blue-500" : (Number(summary?.needs_n) > 0 ? "text-green-500" : "text-red-500")}>
                        { summary?.needs_n } &nbsp;
                        {Number(summary?.needs_n) > 0 && <FontAwesomeIcon icon={faArrowUp} />}
                        {Number(summary?.needs_n) < 0 && <FontAwesomeIcon icon={faArrowDown} />}
                      </h1>
                      <h1 className={Number(summary?.needs_p) === 0 ? "text-blue-500" : (Number(summary?.needs_p) > 0 ? "text-green-500" : "text-red-500")}>
                        { summary?.needs_p } &nbsp;
                        {Number(summary?.needs_p) > 0 && <FontAwesomeIcon icon={faArrowUp} />}
                        {Number(summary?.needs_p) < 0 && <FontAwesomeIcon icon={faArrowDown} />}
                      </h1>
                      <h1 className={Number(summary?.needs_k) === 0 ? "text-blue-500" : (Number(summary?.needs_k) > 0 ? "text-green-500" : "text-red-500")}>
                        { summary?.needs_k } &nbsp;
                        {Number(summary?.needs_k) > 0 && <FontAwesomeIcon icon={faArrowUp} />}
                        {Number(summary?.needs_k) < 0 && <FontAwesomeIcon icon={faArrowDown} />}
                      </h1>
                      <h1 className={Number(summary?.needs_ph) === 0 ? "text-blue-500" : (Number(summary?.needs_ph) > 0 ? "text-green-500" : "text-red-500")}>
                        { summary?.needs_ph } &nbsp;
                        {Number(summary?.needs_ph) > 0 && <FontAwesomeIcon icon={faArrowUp} />}
                        {Number(summary?.needs_ph) < 0 && <FontAwesomeIcon icon={faArrowDown} />}
                      </h1>
                      <h1 className={Number(summary?.needs_ec) === 0 ? "text-blue-500" : (Number(summary?.needs_ec) > 0 ? "text-green-500" : "text-red-500")}>
                        { summary?.needs_ec } &nbsp;
                        {Number(summary?.needs_ec) > 0 && <FontAwesomeIcon icon={faArrowUp} />}
                        {Number(summary?.needs_ec) < 0 && <FontAwesomeIcon icon={faArrowDown} />}
                      </h1>
                      <h1 className={Number(summary?.needs_soil_humidity) === 0 ? "text-blue-500" : (Number(summary?.needs_soil_humidity) > 0 ? "text-green-500" : "text-red-500")}>
                        { summary?.needs_soil_humidity } &nbsp;
                        {Number(summary?.needs_soil_humidity) > 0 && <FontAwesomeIcon icon={faArrowUp} />}
                        {Number(summary?.needs_soil_humidity) < 0 && <FontAwesomeIcon icon={faArrowDown} />}
                      </h1>
                      <h1 className={Number(summary?.needs_soil_temperature) === 0 ? "text-blue-500" : (Number(summary?.needs_soil_temperature) > 0 ? "text-green-500" : "text-red-500")}>
                        { summary?.needs_soil_temperature } &nbsp;
                        {Number(summary?.needs_soil_temperature) > 0 && <FontAwesomeIcon icon={faArrowUp} />}
                        {Number(summary?.needs_soil_temperature) < 0 && <FontAwesomeIcon icon={faArrowDown} />}
                      </h1>
                  </div>
                  <div className='py-2 grid grid-rows-4 gap-4 text-center  font-bold'>
                      <h1>{ summary?.fertilizerN ? summary?.fertilizerN + " kg/ha \n (46%)" : "-" }</h1>
                      <h1>{ summary?.fertilizerP ? summary?.fertilizerP + " kg/ha \n (15%)" : "-" }</h1>
                      <h1>{ summary?.fertilizerK ? summary?.fertilizerK + " kg/ha \n (15%)" : "-" }</h1>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                      <h1>&nbsp;</h1>
                  </div>
              </div>

              {/* SKEMA */}
              {/* <div className='mt-8'>
                  <div className='bg-white rounded-xl border-1 border-gray-200 mt-5 -mx-1'>
                      <div className=' bg-green-900 text-white rounded-t-xl px-5 py-2.5 md:py-3 font-medium' >
                          <p>Skema 1</p>
                      </div>

                      <div className='flex items-center justify-between p-3.5 md:p-5'>
                          <div className='text-left'>
                              <p className='text-xsm'>Krista MKP</p>
                              <h1 className='text-xs md:text-sm font-semibold' >{111.647} kg</h1>
                          </div>
                          <div className='text-left'>
                              <p className='text-xsm'>OilPam EFB</p>
                              <h1 className='text-xs md:text-sm font-semibold' >{558.824} kg</h1>
                          </div>
                          <div className='text-left'>
                              <p className='text-xsm'>YaraLiva Calcinit</p>
                              <h1 className='text-xs md:text-sm font-semibold' >{680.147} kg</h1>
                          </div>
                      </div>

                      <div className='mx-5 bg-gray-200' style={{height: 1}}></div>

                      <div className='flex justify-between items-center m-3.5 md:m-5 text-green-600'>
                          <p className='text-xsm'>Estimasi Harga Pupuk</p>
                          <p className='font-semibold text-sms md:text-base'>Rp10.009.191,00</p>
                      </div>
                  </div>

                  <div className='bg-white rounded-xl border-1 border-gray-200 mt-5 -mx-1'>
                      <div className=' bg-green-900 text-white rounded-xl px-5 py-2.5 md:py-3 font-medium' >
                          <p>Skema 2</p>
                      </div>
                  </div>
              </div> */}

          </div>
      </div>
  )
}

export default LandFertilizer