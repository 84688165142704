import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import TableUser from "../../components/tables/Table"

import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage"
import { finishAnalyze, getAllToAnalyze, startAnalyze } from "../../api/recommendation-api";
import ModalTwoButton from "../../components/modal/ModalTwoButton";
import moment from "moment";

const LandAnalytics = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState(false)
    const [dataTable, setDataTable] = useState([])
    const [filterData, setFilterData] = useState([])
    const [confirmStart, showConfirmStart] = useState(false)
    const [confirmFinish, showConfirmFinish] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const history = useHistory()

    const fetchLandToAnalyst = useCallback( async () => {
        showLoader(true)
        const res = await getAllToAnalyze()

        console.log('GetAllTo Analyze :', res)
        if(res.data){
            if(res.data.code === 0){
                setDataTable(res.data.data?.filter((data) => parseInt(data.status_int) !== 1))
                setFilterData(res.data.data?.filter((data) => parseInt(data.status_int) !== 1))
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
            showLoader(false)
        }else{
            alert('Connection timeout..')
            fetchLandToAnalyst()
        }
    }, [])

    useEffect(() => {
        fetchLandToAnalyst()
    }, [fetchLandToAnalyst])


    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataTable]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.land_code_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.land_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.commodity_name_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.area_var?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.analyst_name?.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    [{id: 1, status: 'Telah Dianalisa'}, {id: -10, status: 'Sedang Dianalisa'}, {id: -5, status: 'Belum Dianalisa'}].find(data => data.id === item.status_int)?.status?.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataTable)
        }
    }

    const handleStartAnalyze = (data)  => {
        setSelectedData(data)
        showConfirmStart(true)
    }

    const handleFinishAnlyze = (data) => {
        setSelectedData(data)
        showConfirmFinish(true)
    }

    const submitStartAnalyze = async () => {
        showLoader(true)
        const res = await startAnalyze(user.id_seq, selectedData.probe_code_var, selectedData.land_code_var, selectedData.data_taking_int)

        console.log('Start Analyze :', res)
        if(res.data){
            if(res.data.code === 0){
                history.push(`/analytics-detail/${selectedData.land_code_var}/${selectedData.id_seq}`)
            }else{
                setMessageErr(res.data.message)
                showConfirmStart(false)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert('Connection timeout..')
            showLoader(false)
        }
    }

    const handleAnalyzeLand = async (data) => {
        history.push(`/analytics-detail/${data.land_code_var}/${data.id_seq}`)
    }

    const submitFinishAnalyze = async () => {
        showLoader(true)
        const res = await finishAnalyze(user.id_seq, selectedData.probe_code_var, selectedData.land_code_var, selectedData.data_taking_int)

        console.log('Start Analyze :', res)
        if(res.data){
            if(res.data.code === 0){
                fetchLandToAnalyst()
                showConfirmFinish(false)
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showConfirmFinish(false)
                showLoader(false)
            }
        }else{
            alert('Connection timeout..')
            showLoader(false)
        }
    }

    const columns = [
        {
            Header: () => <div className='p-4 text-left'>Lahan</div>,
            Footer: 'Lahan',
            accessor: 'land_code_var',
            Cell: ({ value, row }) => {
                const data = row.original
                return <div className='text-left pl-2'>[{value}] {data?.land_name_var}</div>
            }  
        },
        {
            Header: 'Komoditas',
            Footer: 'Komoditas',
            accessor: 'commodity_name_var'
        },
        {
            Header: 'Luas (Ha)',
            Footer: 'Luas (Ha)',
            accessor: 'area_var'
        },
        {
            Header: 'Data',
            Footer: 'Data',
            accessor: 'data_taking_int'
        },
        {
            Header: 'Analis',
            Footer: 'Analis',
            accessor: 'analyst_name',
            Cell: ({row, value}) => {
                const data = row.original
                if(parseInt(user.id_seq)===parseInt(data?.analyst_id)){
                    return <p className='font-bold'>{value}</p>
                }else{
                    return value
                }
            }
        },
        {
            Header: 'Waktu Mulai',
            Footer: 'Waktu Mulai',
            accessor: 'start_on_dtm',
            Cell: ({value}) => value? moment(value, moment(value).creationData().format).format('YYYY-MM-DD HH:mm:ss') : null
        },
        {
            Header: 'Waktu Selesai',
            Footer: 'Waktu Selesai',
            accessor: 'end_on_dtm',
            Cell: ({value}) => value? moment(value, moment(value).creationData().format).format('YYYY-MM-DD HH:mm:ss') : null
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}) => (
                parseInt(value)===1? 
                <div className='bg-green_sidebar text-green-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Telah Dianalisa</div>
                :
                parseInt(value)===-5? 
                <div className='bg-red-100 text-red-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Belum Dianalisa</div>
                :
                parseInt(value)===-10? 
                <div className='bg-yellow-100 text-yellow-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Sedang Dianalisa</div>
                :
                <div className='bg-indigo-100 text-indigo-900 float-left px-2 py-1.5 text-center rounded-lg font-semibold text-xsm'>Unknown Status</div>
            )
        },
        // {
        //     Header: 'Action',
        //     Footer: 'Action',
        //     Cell: ({row}) => {
        //         const data = row.original
        //         const {status_int, analyst_id } = data
        //         if(parseInt(status_int) === -10){
        //             if(parseInt(user.id_seq) === parseInt(analyst_id)){
        //                 return <>
        //                         <button className='bg-lightcayn text-white px-2 py-1.5 rounded-2xl mr-0.5 md:mr-1' onClick={()=>handleAnalyzeLand(data)}>
        //                             Lanjut Analisa
        //                         </button>
        //                         <button className='bg-darkcayn text-white px-2 py-1.5 rounded-2xl' onClick={()=>handleFinishAnlyze(data)}>
        //                             Selesai Analisa
        //                         </button>
        //                     </>
        //             }
        //         }else if(parseInt(status_int) === -5){
        //             return <button className='bg-lightcayn text-white px-2 py-1.5 rounded-2xl' onClick={()=>handleStartAnalyze(data)}>
        //                         Mulai Analisa
        //                     </button>
        //         }
        //         return <span></span>
        //     }
        // },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                const {status_int, analyst_id } = data
                if(parseInt(status_int) === -10){
                    if(parseInt(user.id_seq) === parseInt(analyst_id)){
                        return <>
                                <button className='bg-lightcayn text-white px-2 py-1.5 rounded-2xl text-xsm font-medium' onClick={()=>handleAnalyzeLand(data)}>
                                    Lanjut Analisa
                                </button>
                            </>
                    }
                }else if(parseInt(status_int) === -5){
                    return <button className='bg-lightcayn text-white px-2 py-1.5 rounded-2xl text-xsm font-medium' onClick={()=>handleStartAnalyze(data)}>
                                Mulai Analisa
                            </button>
                }
                return <span></span>
            }
        },
        {
            Header: ' ',
            Footer: ' ',
            Cell: ({row}) => {
                const data = row.original
                const {status_int, analyst_id } = data
                if(parseInt(status_int) === -10){
                    if(parseInt(user.id_seq) === parseInt(analyst_id)){
                        return <>
                                <button className='bg-darkcayn text-white px-2 py-1.5 rounded-2xl text-xsm font-medium' onClick={()=>handleFinishAnlyze(data)}>
                                    Selesai Analisa
                                </button>
                            </>
                    }
                }
                return <span></span>
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-semibold'>Pilih Lahan</h1>
                <h2 className='text-base font-medium text-right'>Menu<span className='text-xs'> / </span><span className='text-sm'>Land Analytics</span></h2>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-6 mt-6'>
                <div className='mb-5'>
                    <h1 className='font-semibold'>List Lahan</h1>
                </div>
                <div className='flex justify-between items-center mb-3'>
                    <input type='search' onChange={handleSearch} placeholder='Cari Lahan' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {loader && <Loader />}
            {confirmStart && <ModalTwoButton message='Waktu analisa anda terhitung setelah anda melanjutkan proses ini, lanjut dan mulai analisa sekarang?' onOK={submitStartAnalyze} onCancel={()=>showConfirmStart(false)} />}
            {confirmFinish && <ModalTwoButton message='Konfirmasi data analisa Anda, data yang disimpan tidak dapat diubah kembali, selesai analisa sekarang?' onOK={submitFinishAnalyze} onCancel={()=>showConfirmFinish(false)} />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (LandAnalytics)