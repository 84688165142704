import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getAllCommodityRef } from "../../api/commodity-api";
import { getAllLandtypeRef } from "../../api/landtype-api";
import { getAllProject } from "../../api/project-api";
import { getAllPetani } from "../../api/user-api";
import Location from "../../helpers/location";
import ErrorField from "../ErrorField";
import { CancelButton, SaveButton } from "../button/CustomButton";
import Loader from "./Loader";
import ModalDrawMaps from "./ModalDrawMaps";
import ModalFormKordinat from "./ModalFormKordinat";
import ModalMessage from "./ModalMessage";
import ModalOptionKoordinat from "./ModalOptionLand";

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1'
const selectStyle = 'border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'

const ModalFormProjectLandManagement = ({data, currentProjectCode, onCancel, onSubmit}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')

    const [modalKordinat, showModalKordinat] = useState(false)
    const [modalDraw, showModalDraw] = useState(false)
    const [modalOption, showModalOption] = useState(false)

    const [dataUser, setDataUser] = useState([])
    const [petaniID, setPetaniID] = useState('')
    const [commodityName, setCommodityName] = useState(false)
    const [dataCommodityRef, setDataCommodityRef] = useState([1])

    const [dataProject, setDataProject] = useState([])
    const [projectCode, setProjectCode] = useState(currentProjectCode)

    const [province, setProvince] = useState([])
    const [provID, setProvID] = useState('')
    const [cities, setCities] = useState([])
    const [cityID, setCityID] = useState('')
    const [districts, setDistricts] = useState([])
    const [districtID, setDistrictID] = useState('')
    const [landTypes, setLandTypes] = useState([])
    const [landTypeID, setLandTypeID] = useState('')

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        getValues,
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback ((obj) => {
        Object.keys(obj).map(key => {
            if(key === 'start_planting_dtm'){
                return setValue(key, moment(obj[key]).format('YYYY-MM-DD'))
            }else if(key === 'prov_id'){
                setProvID(obj[key])
                return setValue('province_var', obj[key])
            }else if(key === 'city_id'){
                setCityID(obj[key])
                return setValue('city_var', obj[key])
            }else if(key === 'dis_id'){
                setDistrictID(obj[key])
                return setValue('district_var', obj[key])
            }else if(key === 'commodity_name_var'){
                setCommodityName(obj[key])
                return setValue(key, obj[key])
            }else if(key === 'user_id_int'){
                setPetaniID(obj[key])
                return setValue('user_id_int', obj[key])
            }else if(key === 'land_type_id_int'){
              setLandTypeID(obj[key])
                return setValue('land_type_id_int', obj[key])
            }else{
                return setValue(key, obj[key], { shouldValidate: true })
            }
        })
    }, [setValue])

    useEffect(() => {
        if(data) {
            setdefaultValue(data)
        } 
        setProjectCode(currentProjectCode)
        setValue('project_code_var', currentProjectCode)
    }, [data, setdefaultValue, currentProjectCode])


    useEffect(() => {
        async function fetchCommodityStandard(){
            showLoader(true)
            const res = await getAllCommodityRef()

            console.log('Fetch Commdity Ref :', res)
            if(res.data){
                if(res.data.code === 0){
                    setDataCommodityRef(res.data.data)
                }else{
                    setMessageErr(res.data.message)
                    showModalErr(true)
                }
                showLoader(false)
            }else{
                alert(`${res.config?.url} ${res.message}`)
                fetchCommodityStandard()
            }
        }

        fetchCommodityStandard()
    }, [])


    useEffect(()=> {
        async function fetchPetani(){
            showLoader(true)
            const res = await getAllPetani()

            console.log('Fetch Petani :', res)
            if(res.data){
                if(res.data.code === 0){
                    const sortData = res.data.data.sort((a,b)=>a.fullname_var.localeCompare(b.fullname_var))
                    setDataUser(sortData)
                }else{
                    setMessageErr(res.data.message)
                    showModalErr(true)
                }
                showLoader(false)
            }else{
                alert(`${res.config?.url} ${res.message}`)
                fetchPetani()
            }
        }

        fetchPetani()
    }, [])


    useEffect(()=> {
        async function fetchLandtypeRef(){
            showLoader(true)
            const res = await getAllLandtypeRef()

            console.log('Fetch LandtypeRef :', res)
            if(res.data){
                if(res.data.code === 0){
                    const sortData = res.data.data.sort((a,b)=>a.name.localeCompare(b.name))
                    setLandTypes(sortData)
                }else{
                    setMessageErr(res.data.message)
                    showModalErr(true)
                }
                showLoader(false)
            }else{
                alert(`${res.config?.url} ${res.message}`)
                fetchLandtypeRef()
            }
        }

        fetchLandtypeRef()
    }, [])


    useEffect(()=> {
        async function fetchProject(){
            showLoader(true)
            const res = await getAllProject()

            console.log('Fetch Petani :', res)
            if(res.data){
                if(res.data.code === 0){
                    setDataProject(res.data.data)
                }else{
                    setMessageErr(res.data.message)
                    showModalErr(true)
                }
                showLoader(false)
            }else{
                alert(`${res.config?.url} ${res.message}`)
                fetchProject()
            }
        }

        fetchProject()
    }, [])


    useEffect(() => {
        async function fetchLocation(){
            const prov = await Location.getProvinces()
            setProvince(prov)

            const city = await Location.allCity()
            setCities(city)

            const districts = await Location.allDistricts()
            setDistricts(districts)
        }
        fetchLocation()
    }, [])


    const onValid = (dataForm) => {
        const {
            user_id_int, 
            project_code_var, 
            land_name_var, 
            address_land_txt, 
            land_coordinates_txt, 
            area_var, 
            commodity_name_var, 
            land_type_id_int,
            probe_code_var
        } = dataForm

        const payload = { 
            user_id_int, 
            project_code_var, 
            land_name_var, 
            // province_var, 
            // city_var, 
            // district_var, 
            address_land_txt, 
            // postal_code_int, 
            land_coordinates_txt, 
            area_var, 
            commodity_name_var, 
            // commodity_type_var, 
            // start_planting_dtm, 
            // period_plant_int, 
            land_type_id_int,
            probe_code_var
        }

        onSubmit(payload)
    }

    const handleReceiveKordinat = (koor, areaLength) => {
        setValue('land_coordinates_txt', koor)
        if(areaLength){
            setValue('area_var', areaLength)
        }
        
        showModalKordinat(false)
        showModalOption(false)
        showModalDraw(false)
    }


    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-white w-10/12 md:w-7/12 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8'>{data? 'Form Ubah Lahan': 'Form Tambah Lahan Baru'}</h1>

                <form className='mt-5' onSubmit={handleSubmit(onValid)}>
                    <div className="bg-soft p-4 rounded-lg my-4 border-2 border-gray-200">
                        <h2 className='font-semibold mb-5'>Data Petani</h2>
                        <div className='flex flex-col sm:flex-row mb-7'>
                            <div className={containerInput}>
                                <label>Petani</label>
                                <select {...register("user_id_int", { required: "This field is required."})} value={petaniID} onChange={(e)=>setPetaniID(e.target.value)} className={selectStyle}>
                                    <option value=''>- Pilih Petani -</option>
                                    {dataUser?.map((data, key) => (
                                        <option key={key} value={data.id_seq}>{data.fullname_var}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='user_id_int' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Projek</label>
                                <select disabled {...register("project_code_var")} value={projectCode} onChange={(e)=>setProjectCode(e.target.value)} className={selectStyle}>
                                    <option value=''>- Pilih Projek -</option>
                                    {dataProject?.map((data, key) => (
                                        <option selected={currentProjectCode === data.project_code_var} key={key} value={data.project_code_var}>[{data.project_code_var}] {data.project_name_var}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='project_code_var' />
                            </div>
                        </div>
                    </div>
                    
                    <div className="bg-soft p-4 rounded-lg my-4 border-2 border-gray-200">
                        <h2 className='font-semibold mb-5'>Data Lahan & Komoditas</h2>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Nama Lahan</label>
                                <input type='text' className={inputText} placeholder='Lahan 1' {...register("land_name_var", { required: "This field is required."})} />
                                <ErrorField errors={errors} name='land_name_var' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Alamat Lahan</label>
                                <textarea className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' {...register("address_land_txt", { required: "Address is required."})} placeholder='jl...' />
                                <ErrorField errors={errors} name='address_land_txt' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Koordinat Lahan</label>
                                <textarea {...register("land_coordinates_txt", { required: "Coordinates is required."})} placeholder='106.90906,-3.67875;102.6334,-6.4334' className='outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1' />
                                <ErrorField errors={errors} name='land_coordinates_txt' />
                                <button type='button' className='border-1 border-gray-500 rounded-lg p-2 mt-1 md:mt-2 hover:text-white hover:bg-green_btn hover:border-transparent' onClick={() => showModalOption(true)}>
                                    <span className='mr-1'><FontAwesomeIcon icon={faPlusCircle} /></span>
                                    <span>{data?'Ubah':'Tambah'} Koordinat</span>
                                </button>
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Luas Lahan (Ha)</label>
                                <input type='text' className={inputText} placeholder='20' {...register("area_var", { required: "Land area is required."})} />
                                <ErrorField errors={errors} name='area_var' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Jenis Tanah</label>
                                <select {...register("land_type_id_int", { required: "This field is required."})} value={landTypeID} onChange={(e)=>setLandTypeID(e.target.value)} className={selectStyle}>
                                    <option value=''>- Pilih Jenis Tanah -</option>
                                    {landTypes.map((data, key) => (
                                        <option key={key} value={data.id_seq}>{data.name}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='landTypeID' />
                            </div>
                            <span className='mx-5 mb-3 sm:mb-0'></span>
                            <div className={containerInput}>
                                <label>Nama Komoditas</label>
                                <select {...register("commodity_name_var", { required: "This field is required."})} value={commodityName} onChange={(e)=>setCommodityName(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                    <option value=''>- Pilih Komoditas Standard -</option>
                                    {dataCommodityRef.map((data, key) => (
                                        <option key={key} value={data.commodity_name_var}>{data.commodity_name_var}</option>
                                    ))}
                                </select>
                                <ErrorField errors={errors} name='commodity_name_var' />
                            </div>
                        </div>
                        <div className='flex flex-col sm:flex-row mb-3'>
                            <div className={containerInput}>
                                <label>Kode Probe</label>
                                <input type='text' className={inputText} placeholder='12345' {...register("probe_code_var")} />
                            </div>
                        </div>
                    </div>
        
                <div className='flex justify-end mt-2'>
                    <CancelButton type='reset' onClick={onCancel} />
                    <SaveButton type='submit' />
                </div>
            </form>
        
                {/* End Body */}
            </div>
            {/* End Modal Content */}
            {loader && <Loader />}
            
            {modalOption && <ModalOptionKoordinat onCancel={()=>showModalOption(false)} onClickManual={()=>showModalKordinat(true)} onClickDraw={()=>showModalDraw(true)} />}
            {modalKordinat && <ModalFormKordinat onCancel={() => showModalKordinat(false)} onSubmit={handleReceiveKordinat} data={getValues('land_coordinates_txt')} />}
            {modalDraw && <ModalDrawMaps onCancel={()=>showModalDraw(false)} onSubmit={handleReceiveKordinat} data={getValues('land_coordinates_txt')} />}

            {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
        </div>
    )
}

export default ModalFormProjectLandManagement