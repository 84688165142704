import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import { createNewProbeDistrict, deleteProbeDistrict, getAllProbe, getAllProbeDistrict, updateProbeDistrict } from "../../api/probe-api"

import TableUser from "../../components/tables/Table"
import { ButtonAdd } from "../../components/button/CustomButton"
import { DropdownLand } from "../../components/dropdown/SimpleDropdown"

import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage"
import ModalFormProbeDistrict from "../../components/modal/ModalFormProbeDistrict"

const ProbeDistrict = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [dataProbe, setDataProbe] = useState([])
    const [dataMtrProbe, setDataMtrProbe] = useState([])
    const [filterData, setFilterData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const history = useHistory()

    const fetchData = useCallback(async () => {
        showLoader(true)

        const res = await getAllProbeDistrict()
        
        console.log('Get Probe District :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataProbe(res.data.data)
                setFilterData(res.data.data)
            }else if(res.data.code === 99){
                showLoader(true)
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history])

    const fetchMtrProbe = useCallback(async () => {
        const res = await getAllProbe()
        
        console.log('Get Mtr Probe :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataMtrProbe(res.data.data.filter(item => parseInt(item.probe_type_id_int) === 1))
            }else if(res.data.code === 99){
                showLoader(true)
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history])

    useEffect(() => {
        fetchData()
        fetchMtrProbe()
    }, [fetchData, fetchMtrProbe])


    const handleEdit = (selectedData) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (payload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            payload.created_by_var = user.fullname_var
            res = await createNewProbeDistrict(payload)
        }else{
            payload.updated_by_var = user.fullname_var
            res = await updateProbeDistrict(selectedData.id_seq, payload)
        }

        console.log('Create/Update Probe District :', res)
        
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }


    const handleDeleteData = async (id_seq) => {
        showLoader(true)

        const res = await deleteProbeDistrict(id_seq)

        console.log('Delete Probe Type :', res)
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else if(res.data.code === 99){
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showLoader(false)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        showLoader(false)
        setIsUpdate(false)
        showModalForm(false)
        setSelectedData(null)
    }

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataProbe]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.probe_code_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.probe_name_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.probe_type_description_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.dis_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.city_name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.prov_name.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataProbe)
        }
    }

    const columns = [
        {
            Header: () => <div className='p-4 text-left'>Code</div>,
            Footer: 'Code',
            accessor: 'probe_code_var',
            Cell: ({ value }) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Name',
            Footer: 'Name',
            accessor: 'probe_name_var'
        },
        {
            Header: 'Type',
            Footer: 'Type',
            accessor: 'probe_type_description_var',
        },
        {
            Header: 'Kecamatan',
            Footer: 'Kecamatan',
            accessor: 'dis_name'
        },
        {
            Header: 'Kabupaten/Kota',
            Footer: 'Kabupaten/Kota',
            accessor: 'city_name'
        },
        {
            Header: 'Provinsi',
            Footer: 'Provinsi',
            accessor: 'prov_name'
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEdit(data)} onDelete={handleDeleteData} />
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-semibold'>PROBE DISTRICT</h1>
                <h2 className='text-base font-medium text-right'>Admin<span className='text-xs'> / </span><span className='text-sm'>Probe District</span></h2>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-6 mt-6'>
                <div className='mb-5'>
                    <h1 className='font-semibold'>List Probe District</h1>
                </div>
                <div className='flex justify-between items-center mb-3'>
                    <ButtonAdd onClick={() => showModalForm(true)} />
                    <input type='search' onChange={handleSearch} placeholder='Cari Probe' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalFormProbeDistrict data={selectedData} mtrProbe={dataMtrProbe} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (ProbeDistrict)