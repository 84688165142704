const ModalMessage = ({onClose, message}) => {
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30 overflow-auto">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onClose}></div>

            {/* Modal Content */}
            <div className="bg-soft w-9/12 md:w-2/5 mx-auto my-auto p-5 rounded-xl shadow-2xl z-50">
                {/* Body */}
                <div className='flex flex-col justify-center items-center text-center'>
                    <h1 className='text-base md:text-lg mb-6' >{message}</h1>
                    <button className='text-base px-4 py-1 bg-lightcayn rounded-lg text-white hover:bg-darkcayn' onClick={onClose}>O K</button>
                </div>
                {/* End Body */}
            </div>
            {/* End Modal Content */}

        </div>
    )
}

export default ModalMessage