import moment from "moment"
import Helper from "../../helpers/helper"

const containerInput = 'flex flex-col w-full sm:w-1/2'

const ModalDetailAnalysis = ({data, onCancel}) => {
    
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-white w-10/12 md:w-7/12 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8'>Detail Analisis</h1>
                
                <div className='flex flex-col sm:flex-row mb-5'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Kode Projek</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.project_code_var}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Nama Projek</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.project_name_var}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Kode Lahan</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.land_code_var}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Nama Lahan</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.land_name_var}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Luas Lahan</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.area_var} (Ha)</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Alamat</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.address_land_txt}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Kecamatan</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.dis_name}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Kabupaten / Kota</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.city_name}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Provinsi</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.prov_name}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Kode POS</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.postal_code_int}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row mb-5'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Koordinat Lahan</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{Helper.getCenterMapFromStr(data.land_coordinates_txt).lat || '0'}, {Helper.getCenterMapFromStr(data.land_coordinates_txt).lng || '0'}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Kode Probe</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.probe_code_var}</p>
                    </div>
                </div>


                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Komoditas</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.commodity_name_var}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Jenis Varietas</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.commodity_type_var}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Mulai Tanam</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{moment(data.start_planting_dtm).format('DD-MM-YYYY HH:mm:ss')}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Periode Tanam</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.period_plant_int}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row mb-5'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Perhitungan Prediksi Panen (Bulan)</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.harvest_prediction_int}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Analis</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.analyst_name}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Data Pengambilan Lahan (Ke-)</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.data_taking_int}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Kendali Virus</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.virus_control_txt}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Catatan Analis</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{data.analyst_notes_txt}</p>
                    </div>
                </div>

                <div className='flex flex-col sm:flex-row'>
                    <div className={containerInput}>
                        <label className='font-semibold'>Waktu Mulai Analisa</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{moment(data.start_on_dtm).format('DD-MM-YYYY HH:mm:ss')}</p>
                    </div>
                    <span className='mx-3'></span>
                    <div className={containerInput}>
                        <label className='font-semibold'>Waktu Selesai Analisa</label>
                        <p className='ml-1.5 mt-0.5 mb-3'>{moment(data.end_on_dtm).format('DD-MM-YYYY HH:mm:ss')}</p>
                    </div>
                </div>


                <div className='flex justify-end mt-8'>
                    <button className='bg-darkcayn hover:bg-lightcayn px-4 py-2 rounded-2xl text-white' onClick={onCancel}>Tutup</button>
                </div>
        
                {/* End Body */}
            </div>
            {/* End Modal Content */}

        </div>
    )
}

export default ModalDetailAnalysis