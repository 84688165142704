import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import { createNewCommodityRef, deleteCommodityRef, getAllCommodityRef, updateCommodityRef } from "../../api/commodity-api"

import { ButtonAdd } from "../../components/button/CustomButton"
import { DropdownLand } from "../../components/dropdown/SimpleDropdown"
import TableUser from "../../components/tables/Table"

import Loader from "../../components/modal/Loader"
import ModalFormCommodityStandard from "../../components/modal/ModalFormCommodityStandard"
import ModalMessage from "../../components/modal/ModalMessage"

const CommodityStandard = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [dataCommodity, setDataCommodity] = useState([])
    const [filterData, setFilterData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const history = useHistory()

    const fetchData = useCallback(async () => {
        showLoader(true)

        const res = await getAllCommodityRef()
        
        console.log('Get Commodity :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataCommodity(res.data.data)
                setFilterData(res.data.data)
            }else if(res.data.code === 99){
                showLoader(true)
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history])

    useEffect(() => {
        fetchData()
    }, [fetchData])

    const handleEdit = (selectedData) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (payload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            payload.created_by_var = user.fullname_var
            res = await createNewCommodityRef(payload)
        }else{
            payload.updated_by_var = user.fullname_var
            res = await updateCommodityRef(selectedData.id_seq, payload)
        }

        console.log('Create/Update Commmodity :', res)
        
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }


    const handleDeleteData = async (id_seq) => {
        showLoader(true)

        const res = await deleteCommodityRef(id_seq)

        console.log('Delete commodity Ref :', res)
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else if(res.data.code === 99){
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showLoader(false)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        showLoader(false)
        setIsUpdate(false)
        showModalForm(false)
        setSelectedData(null)
    }

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataCommodity]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.commodity_name_var.toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataCommodity)
        }
    }

    const columns = [
        {
            Header: () => <div className='p-4 text-left'>Nama Komoditas</div>,
            Footer: 'Nama Komoditas',
            accessor: 'commodity_name_var',
            Cell: ({ value }) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Ketinggian',
            Footer: 'Ketinggian',
            accessor: 'elevation_var'
        },
        {
            Header: 'Curah Hujan',
            Footer: 'Curah Hujan',
            accessor: 'rainfall_var'
        },
        {
            Header: 'Topografi',
            Footer: 'Topografi',
            accessor: 'topography_var'
        },
        {
            Header: 'Suhu Udara',
            Footer: 'Suhu Udara',
            accessor: 'temperature_var'
        },
        {
            Header: 'Kelembaban',
            Footer: 'Kelembaban',
            accessor: 'humidity_var'
        },
        {
            Header: 'PH',
            Footer: 'PH',
            accessor: 'ph_value_var'
        },
        {
            Header: 'EC',
            Footer: 'EC',
            accessor: 'ec_value_var'
        },
        {
            Header: 'Nilai N',
            Footer: 'Nilai N',
            accessor: 'n_value_var'
        },
        {
            Header: 'Nilai P',
            Footer: 'Nilai P',
            accessor: 'p_value_var'
        },
        {
            Header: 'Nilai K',
            Footer: 'Nilai K',
            accessor: 'k_value_var'
        },
        {
            Header: 'Nilai ZA',
            Footer: 'Nilai ZA',
            accessor: 'za_value_var'
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEdit(data)} onDelete={handleDeleteData} />
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-semibold'>COMMODITY STANDARD</h1>
                <h2 className='text-base font-medium text-right'>Admin<span className='text-xs'> / </span><span className='text-sm'>Commdity Standard</span></h2>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-6 mt-6'>
                <div className='mb-5'>
                    <h1 className='font-semibold'>List Commodity Standard (Ref)</h1>
                </div>
                <div className='flex justify-between items-center mb-3'>
                    <ButtonAdd onClick={() => showModalForm(true)} />
                    <input type='search' onChange={handleSearch} placeholder='Cari komoditas' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalFormCommodityStandard data={selectedData} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (CommodityStandard)