import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Auth from "./components/pages/Authpage";
import Home from "./components/pages/Homepage";
import DetailAnalytics from "./containers/analyst/DetailAnalytics";

function App() {
	return (
		<BrowserRouter>
			<Switch>
				<Route path='/auth' render={props => <Auth {...props} />} />
				<Route path='/admin' render={props => <Home {...props} />} />
				<Route path='/analytics-detail/:land_code_var/:recommendation_id' component={DetailAnalytics} />
				<Redirect from="/" to="/admin" />
			</Switch>
		</BrowserRouter>
	);
}

export default App
