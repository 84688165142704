import { faAddressCard, faBook, faCalendarAlt, faCheckDouble, faDiagnoses, faDollarSign, faExpand, faFileAlt, faFileArchive, faHandHoldingUsd, faHistory, faLayerGroup, faProjectDiagram, faSatelliteDish, faSitemap, faTachometerAlt, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Dashboard from "./containers/Dashboard";
import Profile from "./containers/Profile";
import Login from "./containers/auth/Login";
import OtpPage from "./containers/auth/OtpPage";
import Register from "./containers/auth/Register";

import CommodityStandard from "./containers/admin/CommodityStandard";
import Feature from "./containers/admin/Feature";
import LandType from "./containers/admin/LandType";
import ProbeDistrict from "./containers/admin/ProbeDistrict";
import ProbeManagement from "./containers/admin/ProbeManagement";
import Project from "./containers/admin/Project";
import ProjectManagement from "./containers/admin/ProjectManagement";
import User from "./containers/admin/User";

import Commodity from "./containers/admin/user-detail/Commodity";
import Cultivation from "./containers/admin/user-detail/Cultivation";
import CustomerDetail from "./containers/admin/user-detail/CustomerDetail";
import CustomerPhoto from "./containers/admin/user-detail/CustomerPhoto";
import Land from "./containers/admin/user-detail/Land";

import LandFarmer from "./containers/petani/LandFarmer";
import ProbeActivation from "./containers/petani/ProbeActivation";

import Analytics from "./containers/Analytics";
import Schedule from "./containers/Schedule";
import LahanManagement from "./containers/admin/LahanManagement";
import TransactionAnalysis from "./containers/admin/TransactionAnalysis";
import TransactionLayanan from "./containers/admin/TransactionLayanan";
import HistoryAnalytics from "./containers/analyst/HistoryAnalytics";
import LandAnalytics from "./containers/analyst/LandAnalytics";

const routeContainer = [
    {
        path : "/index",
        layout: '/admin',
        exact: true,
        name: 'Dashboard',
        icon_path: 
            <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                <path className={`fill-current text-gray-700`} d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z" />
                <path className={`fill-current text-gray-600`} d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z" />
                <path className={`fill-current text-gray-400`} d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z" />
            </svg>,
        component: Dashboard
    },
    {
        path : "/user",
        layout: '/admin',
        name: 'User',
        icon_path: 
            // <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
            //     <path className={`fill-current text-gray-400`} d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z" />
            //     <path className={`fill-current text-gray-700 `} d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z" />
            //     <path className={`fill-current text-gray-600`} d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z" />
            // </svg>
            <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 24 24">
                <path className='fill-current text-gray-600' d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z" />
                <path className='fill-current text-gray-400' d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z" />
           </svg>,
        component: User
    },
]

export const RouteAdminRole = [
    {
        title: 'Menu',
        routes: [
            {
                path : "",
                layout: '/admin',
                exact: true,
                name: 'Dashboard',
                icon_path: <FontAwesomeIcon icon={faTachometerAlt} size='lg' className='mr-4'/>,
                component: Dashboard
            },
            // {
            //     path : "/project-list",
            //     layout: '/admin',
            //     name: 'Project',
            //     icon_path: <FontAwesomeIcon icon={faProjectDiagram} size='lg' className='mr-4'/>,
            //     component: Project
            // },
        ]
    },
    // {
    //     title: 'Transaction',
    //     routes: [
    //         {
    //             path : "/layanan",
    //             layout: '/admin',
    //             name: 'Layanan',
    //             icon_path: <FontAwesomeIcon icon={faDollarSign} size='lg' className='mr-6'/>,
    //             component: TransactionLayanan
    //         },
    //         {
    //             path : "/Analysis",
    //             layout: '/admin',
    //             name: 'Analysis',
    //             icon_path: <FontAwesomeIcon icon={faDiagnoses} size='lg' className='mr-4'/>,
    //             component: TransactionAnalysis
    //         },
    //     ]
    // },
    {
        title: 'Admin',
        routes: [
            {
                path : "/project-manage",
                layout: '/admin',
                name: 'Project Management',
                icon_path: <FontAwesomeIcon icon={faProjectDiagram} size='lg' className='mr-4'/>,
                component: ProjectManagement
            },
            {
                path : "/land-manage",
                layout: '/admin',
                name: 'Lahan Management',
                icon_path: <FontAwesomeIcon icon={faExpand} size='lg' className='mr-5'/>,
                component: LahanManagement
            },
            {
                path : "/commodity-standard",
                layout: '/admin',
                name: 'Commodity Standard',
                icon_path: <FontAwesomeIcon icon={faSitemap} size='lg' className='mr-4'/>,
                component: CommodityStandard
            },
            {
                path : "/probe-manage",
                layout: '/admin',
                name: 'Probe Management',
                icon_path: <FontAwesomeIcon icon={faSatelliteDish} size='lg' className='mr-4'/>,
                component: ProbeManagement
            },
            // {
            //     path : "/probe-district",
            //     layout: '/admin',
            //     name: 'Probe District',
            //     icon_path: <FontAwesomeIcon icon={faLayerGroup} size='lg' className='mr-4'/>,
            //     component: ProbeDistrict
            // },
            {
                path : "/land-type",
                layout: '/admin',
                name: 'Land Type',
                icon_path: <FontAwesomeIcon icon={faLayerGroup} size='lg' className='mr-4'/>,
                component: LandType
            },
            // {
            //     path : "/feature",
            //     layout: '/admin',
            //     name: 'Feature',
            //     icon_path: <FontAwesomeIcon icon={faHandHoldingUsd} size='lg' className='mr-4'/>,
            //     component: Feature
            // },
            {
                path : "/user",
                layout: '/admin',
                name: 'User',
                icon_path: <FontAwesomeIcon icon={faUsers} size='lg' className='mr-4'/>,
                component: User
            },
        ]
    },
    {
        title: 'Other',
        routes: [
            {
                path : "/profile",
                layout: '/admin',
                name: 'Profile',
                icon_path: <FontAwesomeIcon icon={faAddressCard} size='lg' className='mr-4'/>,
                component: Profile
            },
        ]
    }
]

export const RoutePetaniRole = [
    {
        title: 'Menu',
        routes: [
            {
                path : "",
                layout: '/admin',
                exact: true,
                name: 'Dashboard',
                icon_path: <FontAwesomeIcon icon={faTachometerAlt} size='lg' className='mr-4'/>,
                component: Dashboard
            },
            {
                path : "/lahan",
                layout: '/admin',
                name: 'Lahan',
                icon_path: <FontAwesomeIcon icon={faLayerGroup} size='lg' className='mr-4'/>,
                component: LandFarmer
            },
            {
                path : "/probe-activate",
                layout: '/admin',
                name: 'Probe Activation',
                icon_path: <FontAwesomeIcon icon={faCheckDouble} size='lg' className='mr-4'/>,
                component: ProbeActivation
            },
        ]
    },
    {
        title: 'Other',
        routes: [
            {
                path : "/profile",
                layout: '/admin',
                name: 'Profile',
                icon_path: <FontAwesomeIcon icon={faAddressCard} size='lg' className='mr-4'/>,
                component: Profile
            },
        ]
    }
]

export const RouteAnalisRole = [
    {
        title: 'Menu',
        routes: [
            {
                path : "",
                layout: '/admin',
                exact: true,
                name: 'Dashboard',
                icon_path: <FontAwesomeIcon icon={faTachometerAlt} size='lg' className='mr-4.5'/>,
                component: Dashboard
            },
            {
                path : "/analytics",
                layout: '/admin',
                name: 'Analytics',
                icon_path: <FontAwesomeIcon icon={faDiagnoses} size='lg' className='mr-4'/>,
                component: LandAnalytics
            },
            {
                path : "/history",
                layout: '/admin',
                name: 'History',
                icon_path: <FontAwesomeIcon icon={faHistory} size='lg' className='mr-5' />,
                component: HistoryAnalytics
            },
        ]
    },
    {
        title: 'Other',
        routes: [
            {
                path : "/profile",
                layout: '/admin',
                name: 'Profile',
                icon_path: <FontAwesomeIcon icon={faAddressCard} size='lg' className='mr-4'/>,
                component: Profile
            },
        ]
    }
]

export const RouteInvestorRole = [
    {
        title: 'Menu',
        routes: [
            {
                path : "",
                layout: '/admin',
                exact: true,
                name: 'Dashboard',
                icon_path: <FontAwesomeIcon icon={faTachometerAlt} size='lg' className='mr-4'/>,
                component: Dashboard
            },
            {
                path : "/project",
                layout: '/admin',
                name: 'Project',
                icon_path: <FontAwesomeIcon icon={faProjectDiagram} size='lg' className='mr-4'/>,
                component: Project
            },
            {
                path : "/analytics",
                layout: '/admin',
                name: 'Analytics',
                icon_path: <FontAwesomeIcon icon={faDiagnoses} size='lg' className='mr-4'/>,
                component: Analytics
            },
            {
                path : "/schedule",
                layout: '/admin',
                name: 'Schedule',
                icon_path: <FontAwesomeIcon icon={faCalendarAlt} size='lg' className='mr-4'/>,
                component: Schedule
            }
        ]
    },
    {
        title: 'Report',
        routes: [
            {
                path : "/daily",
                layout: '/admin',
                name: 'Daily',
                icon_path: <FontAwesomeIcon icon={faFileAlt} size='lg' className='mr-4'/>,
                component: Schedule
            },
            {
                path : "/monthly",
                layout: '/admin',
                name: 'Monthly',
                icon_path: <FontAwesomeIcon icon={faFileArchive} size='lg' className='mr-4'/>,
                component: Schedule
            },
            {
                path : "/yearly",
                layout: '/admin',
                name: 'Yearly',
                icon_path: <FontAwesomeIcon icon={faBook} size='lg' className='mr-4'/>,
                component: Schedule
            },
        ]
    },
    {
        title: 'Other',
        routes: [
            {
                path : "/profile",
                layout: '/admin',
                name: 'Profile',
                icon_path: <FontAwesomeIcon icon={faAddressCard} size='lg' className='mr-4'/>,
                component: Profile
            },
        ]
    }
]


export const RouteAdminUser = [
    {
        path : "",
        exact: true,
        layout: '/admin/user-detail/:id_seq',
        name: 'Data Petani',
        component: CustomerDetail
    },
    {
        path : "/land",
        layout: '/admin/user-detail/:id_seq',
        name: 'Lahan',
        component: Land
    },
    {
        path : "/commodity",
        layout: '/admin/user-detail/:id_seq',
        name: 'Komoditas',
        component: Commodity
    },
    {
        path : "/cultivation",
        layout: '/admin/user-detail/:id_seq',
        name: 'Budidaya',
        component: Cultivation
    },
    {
        path : "/photo",
        layout: '/admin/user-detail/:id_seq',
        name: 'Dokumentasi Foto',
        component: CustomerPhoto
    }
]


export const routeAuth = [
    {
        path : "/sign-in",
        layout: '/auth',
        exact: true,
        name: 'Login',
        component: Login
    },
    {
        path : "/sign-up",
        layout: '/auth',
        name: 'Register',
        component: Register
    },
    {
        path : "/otp-validate",
        layout: '/auth',
        name: 'OTP',
        component: OtpPage
    },
]

export default routeContainer