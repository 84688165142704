import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser, faUserFriends, faUsers, faUserSlash } from "@fortawesome/free-solid-svg-icons"

import Helper from "../../helpers/helper"
import { createNewUser, disableUser, getAllUser, updateUser } from "../../api/user-api"

import TableUser from "../../components/tables/Table"
import { ButtonAdd } from "../../components/button/CustomButton"

import { ModalUser } from "../../components/modal/ModalForm"
import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage"
import DropdownTableUser from "../../components/dropdown/DropdownTableUser"
import ModalFormChangePassword from "../../components/modal/ModalFormChangePassword"


const User = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalUser, showModalUser] = useState(false)
    const [modalChangePass, showModalChangePass] = useState(false)
    const [isUpdate, setIsUpdate] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    const [dataUser, setDataUser] = useState([])
    const [filterData, setFilterData] = useState([])
    const [selectedUser, setSelectedUser] = useState(null)

    const fetchUser = useCallback( async () => {
        showLoader(true)
        const res = await getAllUser(localStorage.getItem('agroo-token'))

        showLoader(false)
        console.log(res)
        if(res.data){
            if(res.data.code === 0){
                setDataUser(res.data.data)
                setFilterData(res.data.data)
            }else{
                setErrMessage(res.data.message)
                showModalErr(true)
            }
        }else{
            fetchUser()
        }
    }, [])

    useEffect(() => {
        fetchUser()
    }, [fetchUser])


    const handleEditData =  (selectedData) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalUser(true)
    }

    const handleChangePassword = (selectedData) => {
        setSelectedUser(selectedData)
        setIsUpdate(true)
        showModalChangePass(true)
    }

    const handleReceiveDataForm = async (data) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            data.created_by_var = user.fullname_var
            res = await createNewUser(data)
        }else{
            data.updated_by_var = user.fullname_var
            res = await updateUser(selectedUser.id_seq, data)
        }

        console.log('Create/Update User :', res)
        
        if(res.data){
            if(res.data.code === 0){
                // Jika user petani langsung direact ke user-detail
                // if(res.data.data.user_group_id_int === 4){
                //     history.push(`/admin/user-detail/${res.data.data.id_seq}`)
                // }else{
                    fetchUser()
                    resetForm()
                // }
            }else{
                setErrMessage(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const handleDeleteItem = async (userID) => {
        const res = await disableUser(userID)

        console.log("DELETE USER :", res)
        if(res.data){
            if(res.data.code === 0){
                fetchUser()
            }else{
                setErrMessage(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        setErrMessage('')
        setSelectedUser(null)
        setIsUpdate(false)
        showModalUser(false)
        showLoader(false)
        showModalErr(false)
        showModalChangePass(false)
    }

    const columns = [
        {
            Header: () => <span className='p-4'>Name</span>,
            Footer: 'Name',
            accessor: 'fullname_var',
            Cell: ({ value }) =>  <div className='text-left pl-4'>{value}</div>,
        },
        {
            Header: 'Phone Number',
            Footer: 'Phone Number',
            accessor: 'phone_number_int',
        },
        {
            Header: 'Email',
            Footer: 'Email',
            accessor: 'email_var'
        },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}) => (
                parseInt(value)===1? 
                <span className='bg-green_sidebar text-green-800 px-2 py-1 rounded-lg font-medium'>Active</span>
                :
                <span className='bg-red-100 text-red-800 px-2 py-1 rounded-lg font-medium'>Inactive</span>
            )
        },
        {
            Header: 'Role',
            Footer: 'Role',
            accessor: 'user_group_id_int',
            Cell: ({ value }) => {
                return Helper.getUserRole(value)
            }
        },
        {
            Header: 'Action',
            Footer: 'Action',
            Cell: ({row}) => {
                const data = row.original
                if(user?.user_group_id_int === 1){
                    if(data.user_group_id_int !== 1){
                        return <DropdownTableUser
                            onEdit={() => handleEditData(data)}
                            onDelete={() => handleDeleteItem(data.id_seq)}
                            onChangePassword={()=>handleChangePassword(data)}
                        />
                    }
                }else{
                    if(data.user_group_id_int !== 1 && data.user_group_id_int !== 2){
                        return <DropdownTableUser
                            onEdit={() => handleEditData(data)}
                            onDelete={() => handleDeleteItem(data.id_seq)}
                            onChangePassword={()=>handleChangePassword(data)}
                        />
                    }
                }

                return <span></span>
            }
        }
    ]

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataUser]
        if(event.target.value){
            console.log(event.target.value)
            const filtered = newData.filter(item => {
                return (
                    item.fullname_var.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.phone_number_int.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    item.email_var?.toLowerCase().includes(event.target.value.toLowerCase()) || 
                    Helper.getUserRole(item.user_group_id_int)?.toLowerCase().includes(event.target.value.toLowerCase()) 
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataUser)
        }
    }

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-semibold'>USER</h1>
                <h2 className='text-base font-medium'>Admin<span className='text-xs'> / </span><span className='text-sm'>User</span></h2>
            </div>

            <div className='flex flex-col md:flex-row items-center justify-between my-6'>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faUsers} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.length}</h1>
                        <p>Total User</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faUser} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.filter(data => data.status_int === 1).length}</h1>
                        <p>User Aktif</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 mb-5 md:mb-0 md:mr-6 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faUserSlash} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.filter(data => data.status_int !== 1).length}</h1>
                        <p>User Tidak Aktif</p>
                    </div>
                </div>
                <div className='w-full md:w-4/12 flex justify-evenly items-center bg-white rounded-2xl shadow-xl px-5 py-8'>
                    <div className='bg-green_sidebar rounded-lg text-green-900 w-14 h-14 flex items-center justify-center text-2xl'>
                        <FontAwesomeIcon icon={faUserFriends} />
                    </div>
                    <div className='flex flex-col'>
                        <h1 className='font-semibold text-3xl mb-1'>{dataUser?.filter(data => data.user_group_id_int === 4).length}</h1>
                        <p>Total Petani</p>
                    </div>
                </div>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-6'>
                <div className='mb-5'>
                    <h1 className='font-semibold'>Manage User</h1>
                </div>

                {/* TABLE */}
                <div className='flex justify-between items-center mb-3'>
                    <ButtonAdd onClick={() => showModalUser(true)} />
                    <input type='search' onChange={handleSearch} placeholder='Cari User' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />

            </div>

            {modalUser && <ModalUser 
                title={isUpdate? 'Form Ubah User' : 'Form Tambah User Baru'} 
                data={selectedUser}
                onCancel={resetForm}
                onSubmit={handleReceiveDataForm}
            />}
            {modalChangePass && <ModalFormChangePassword onCancel={()=>showModalChangePass(false)} onSubmit={handleReceiveDataForm} />}
            {modalErr && <ModalMessage message={errMessage} onClose={()=>showModalErr(false)} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(User)