import { useEffect, useState } from "react";
import { CancelButton, SaveButton } from "../button/CustomButton"
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import Location from "../../helpers/location";

const containerInput = 'flex flex-col w-full sm:w-1/2'

const ModalFormProbeDistrict = ({data, mtrProbe, onCancel, onSubmit}) => {

    const [selectedProbeCode, setSelectedProbeCode] = useState(1)

    const [province, setProvince] = useState([])
    const [provID, setProvID] = useState(0)
    const [cities, setCities] = useState([])
    const [cityID, setCityID] = useState(0)
    const [districts, setDistricts] = useState([])
    const [districtID, setDistrictID] = useState(0)

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({ criteriaMode: "all" });


    useEffect(() => {
        if(data) {
            setValue('probe_code_var', data.probe_code_var)
            setValue('province_var', data.prov_id)
            setValue('city_var', data.city_id)
            setValue('district_var', data.dis_id)
            setSelectedProbeCode(data.probe_code_var)
            setProvID(data.prov_id)
            setCityID(data.city_id)
            setDistrictID(data.dis_id)
        }
    }, [data, setValue])

    useEffect(() => {
        async function fetchLocation(){
            const prov = await Location.getProvinces()
            setProvince(prov)

            const city = await Location.allCity()
            setCities(city)

            const districts = await Location.allDistricts()
            setDistricts(districts)
        }
        fetchLocation()
    }, [])


    const onValid = (dataForm) => {
        const {probe_code_var, district_var, city_var, province_var} = dataForm

        const payload = { probe_code_var, probe_type_id_int: 1, district_var, city_var, province_var }

        onSubmit(payload)
    }
    
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-10/12 md:w-1/2 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8'>{data? 'Form Ubah Probe District': 'Form Tambah Probe District Baru'}</h1>
                <form onSubmit={handleSubmit(onValid)}>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Kode Probe</label>
                            <select {...register("probe_code_var", { required: "This field is required."})} value={selectedProbeCode} onChange={(e)=>setSelectedProbeCode(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                <option value=''>- Pilih Probe -</option>
                                {mtrProbe?.map((data, key) => (
                                    <option key={key} value={data.probe_code_var}>[{data.probe_code_var}] {data.probe_name_var}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='probe_code_var' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Provinsi</label>
                            <select {...register("province_var", { required: "This field is required."})} value={provID} onChange={(e)=>setProvID(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                <option value=''>- Pilih Provinsi -</option>
                                {province?.map((data, key) => (
                                    <option key={key} value={data.prov_id}>{data.prov_name}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='province_var' />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Kabupaten/Kota</label>
                            <select {...register("city_var", { required: "This field is required."})} value={cityID} onChange={(e)=>setCityID(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                <option value=''>- Pilih Kabupaten/Kota -</option>
                                {cities?.filter(({prov_id}) => parseInt(prov_id)=== parseInt(provID))?.map((data, key) => (
                                    <option key={key} value={data.city_id}>{data.city_name}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='city_var' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Kecamatan</label>
                            <select {...register("district_var", { required: "This field is required."})} value={districtID} onChange={(e)=>setDistrictID(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                <option value=''>- Pilih Kecamatan -</option>
                                {districts?.filter(({city_id}) => parseInt(city_id)===parseInt(cityID)).map((data, key) => (
                                    <option key={key} value={data.dis_id}>{data.dis_name}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='district_var' />
                        </div>
                    </div>

                    <div className='flex justify-end mt-8'>
                        <CancelButton type='reset' onClick={onCancel} />
                        <SaveButton type='submit' />
                    </div>
                </form>
        
                {/* End Body */}
            </div>
            {/* End Modal Content */}
        </div>
    )
}

export default ModalFormProbeDistrict