import { faEdit, faEllipsisV, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import ModalTwoButton from "../modal/ModalTwoButton";


export const DropdownLahan = ({data, onDetail, onEdit, onDelete}) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [confirmDelete, showConfirmDelete] = useState(false)

    const trigger = useRef(null);
    const dropdown = useRef(null);

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
        if (!dropdownOpen || dropdown.current?.contains(target) || trigger.current?.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
        if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    const handleDelete = () => {
        showConfirmDelete(false)
        onDelete(data)
    }

    return (
        <div className="relative inline-flex justify-center item-center">
            <button onClick={() => setDropdownOpen(!dropdownOpen)} className="relative blockfocus:outline-none flex justify-center items-center w-9 h-9 bg-white rounded-full text-lg text-gray-800 hover:text-lightcayn">
                <span onClick={() => setDropdownOpen(!dropdownOpen)}><FontAwesomeIcon icon={faEllipsisV} /></span>
            </button>

            {dropdownOpen &&
            <div
                className={`z-auto absolute right-10 top-0 min-w-max bg-white border border-gray-200 rounded shadow-lg overflow-hidden`}
            >
                <div ref={dropdown} onFocus={() => setDropdownOpen(true)} onBlur={() => setDropdownOpen(false)} >
                    <div className="bg-white rounded-md shadow-xl text-left z-auto">
                        <span onClick={onDetail} className="block px-2.5 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faEye} />&nbsp; Lihat Detail
                        </span>
                        <span onClick={onEdit} className="block px-2.5 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faEdit} />&nbsp; Ubah Lahan
                        </span>
                        <span onClick={() => showConfirmDelete(true)} className="block px-2.5 py-2 text-sm capitalize text-gray-700 hover:bg-lightcayn hover:text-white cursor-pointer">
                            <FontAwesomeIcon icon={faTrash} />&ensp; Hapus Lahan
                        </span>
                    </div>
                </div>
            </div>
            }

            {confirmDelete && <ModalTwoButton message='Delete this item?' onOK={handleDelete} onCancel={() => showConfirmDelete(false)} />}
        </div>
    )
}