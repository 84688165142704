import { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Polygon  } from 'react-leaflet'
import { faCalendarAlt, faExpandArrowsAlt, faMapMarkerAlt, faStreetView } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { getDetailLandListByUser } from "../../api/landarea-api";
import Helper from "../../helpers/helper";

import logo from '../../assets/img/logo.svg'
import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage";
import { activateProbe } from "../../api/trxprobe-api";

const ProbeActivation = () => {
	const [loader, showLoader] = useState(true)
	const [modalErr, showModalErr] = useState(false)
	const [messageErr, setMessageErr] = useState('')
	const [dataLand, setDataLand] = useState([])
    const [landCode, setLandCode] = useState('')
    const [probeCode, setProbeCode] = useState('')

	const user = JSON.parse(localStorage.getItem('agroo-user'))

	useEffect(() => {
		async function fetchLand(){
			const res = await getDetailLandListByUser(user.id_seq)

			console.log('get detail land lis :', res)
			if(res.data){
				if(res.data.code === 0){
					setDataLand(res.data.data)
					showLoader(false)
				}else{
					setMessageErr(res.data.message)
					showModalErr(true)
					showLoader(false)
				}
			}else{
				alert('Connection timeout...')
				fetchLand()
			}
		}

		fetchLand()
	}, [user.id_seq])

	const getZoom = (areaWidth) => {
		const width = parseFloat(areaWidth)
		if(width < 3){
			return 17
		}else if(width < 15){
			return 16
		}else if(width < 30){
			return 15
		}else if(width < 60){
			return 14
		}else if(width < 153){
			return 13
		}else if(width < 750){
			return 13
		}else if(width < 5000){
			return 11
		}else if(width < 25000){
			return 10
        }else if(width < 125000){
			return 9
		}else if(width < 1500000){
			return 8
		}else{
			return 7
		}
	}

    const handleActivateProbe = async () => {
        if(landCode ==='' || probeCode==='') return
        
        showLoader(true)
        const res = await activateProbe(landCode, probeCode, user?.fullname_var)

        console.log('Activate probe :', res)
        if(res.data){
            if(res.data.code === 0){
                setMessageErr('Aktivasi probe berhasil, silakan restart perangkat (probe) sebelum digunakan')
                setLandCode('')
                setProbeCode('')
            }else{
                setMessageErr(res.data.message)
            }
            showModalErr(true)
            showLoader(false)
        }else{
            alert('Connection timeout..')
        }
    }

    const handleActivateProbeSync = async (paramLandCode, paramProbeCode) => {
        showLoader(true)
        const res = await activateProbe(paramLandCode, paramProbeCode, user?.fullname_var)

        console.log('Activate probe :', res)
        if(res.data){
            if(res.data.code === 0){
                setMessageErr('Aktivasi probe berhasil, silakan restart perangkat (probe) sebelum digunakan')
            }else{
                setMessageErr(res.data.message)
            }
            showModalErr(true)
            showLoader(false)
        }else{
            alert('Connection timeout..')
        }
    }

    return (
        <div className='flex item-center flex-col mb-auto px-4 py-6 md:p-6'>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>AKTIVASI PROBE</h1>
                <h2 className='text-base font-medium'>Menu<span className='text-xs'> / </span><span className='text-sm'>Aktivasi Probe</span></h2>
            </div>

            <h1 className='font-medium mb-3 text-center text-sm'>Pilih Lahan</h1>
			{dataLand.length > 0?
            <div className='md:grid lg:grid-cols-3 2xl:grid-cols-4'>
            {dataLand.map((data, key) => 
                <div className={`mb-5 bg-white rounded-2xl shadow-xl w-full lg:w-80 relative`} key={key} onClick={()=>setLandCode(data.land_code_var)}>
                    <div className={`absolute inset-0 ${data.land_code_var===landCode? 'bg-green-900 bg-opacity-30 border-2 border-green-600': 'bg-none' } rounded-2xl cursor-pointer`} style={{zIndex: 1}}></div>
                    <div className='flex items-center justify-between w-full'>
						<MapContainer 
							center={Helper.getCenterMapFromStr(data.land_coordinates_txt)} 
							zoom={getZoom(data.area_var)} 
                            zoomControl={false}
							scrollWheelZoom={true} className='w-full rounded-t-2xl h-36 z-0'>
							<TileLayer
								// maxZoom={20} id='' tileSize={512} zoomOffset={-1}
								attribution='by Agroobot'
								url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
								// url='https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA'
							/>
							<Marker position={Helper.getCenterMapFromStr(data.land_coordinates_txt)} icon={Helper.getMapIcon()}>				{/* kiri atas */}
							</Marker>

							<Polygon pathOptions={{color: 'lime'}} positions={Helper.generatelatLngFromStr(data.land_coordinates_txt)} />
						</MapContainer>
                    </div>
					<div className='p-5 pt-3 pb-12'>
						<div className='flex border-t-2 border-gray-300 pt-2.5 relative'>
							<div className='bg-header rounded-full p-2 w-17 h-17 absolute'>
								<LazyLoadImage effect='blur' src={logo} />
							</div>
							<div className='ml-20'>
								<h2 className='font-semibold'>{data.land_name_var} ({data.project_name_var})</h2>
								<h3 className='font-medium my-1'><span className='mr-1'><FontAwesomeIcon icon={faMapMarkerAlt} /></span> {Helper.capitalEachWord(data.dis_name)}, {Helper.capitalEachWord(data.city_name)}, {Helper.capitalEachWord(data.prov_name)}</h3>
								<div className='flex text-tag font-semibold mt-2'>
									<p className='mr-3 bg-gray-200 rounded-md px-2 py-1'><span><FontAwesomeIcon icon={faCalendarAlt} /></span> {data.land_code_var}</p>
									<p className='bg-gray-200 rounded-md px-2 py-1'><span><FontAwesomeIcon icon={faExpandArrowsAlt} /></span> {data.area_var} Ha</p>
								</div>
								{
									data?.probe_code_var &&
									<div className='mt-5'>
										<button className='bg-yellow-100 hover:bg-yellow-300 text-black py-2 px-3 rounded-2xl absolute' style={{zIndex: 1}} onClick={()=>handleActivateProbeSync(data.land_code_var, data.probe_code_var)}>
											Sync Data <span><FontAwesomeIcon icon={faStreetView} /></span>
										</button>
									</div>
								}
							</div>
						</div>
					</div>

                </div>
            )}
            
            </div>
			:
			<div className='w-full h-100 flex flex-col justify-center items-center text-center'>
				<p>Ups... Nampaknya kamu belum memiliki lahan yang terdaftar,</p>
				<p>silakan daftarkan lahanmu pada Aplikasi Agroobot terlebih dahulu !</p>
				<button className='mt-2 border-1 border-lightcayn rounded-2xl px-3 py-2 text-lightcayn hover:bg-darkcayn hover:text-white hover:border-transparent font-medium' 
				onClick={()=>window.open('https://agroobot.com','_blank')}>
					Install Apps Sekarang
				</button>
			</div>	
			}
            
            <div className='flex flex-col justify-center items-center mt-3'>
                <h1 className='font-medium mb-2 text-sm'>Masukkan kode yang tertera pada probe</h1>
                <input type='text' className='outline-none px-5 py-2.5 rounded-xl font-semibold text-lg text-center border-2 border-gray-300' autoFocus value={probeCode} onChange={(e)=>setProbeCode(e.target.value)} />
                <button className={`px-6 py-3 rounded-xl ${landCode==='' || probeCode===''? 'bg-gray-300 cursor-default' : 'bg-lightcayn hover:bg-darkcayn' } text-white font-medium mt-7`} onClick={handleActivateProbe}>Aktivasi Sekarang</button>
            </div>


			{loader && <Loader />}
			{modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
        </div>
    )
}

export default ProbeActivation