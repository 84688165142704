import { Switch, Route, Redirect } from "react-router-dom"
import { routeAuth } from "../../routes"

const Auth = () => {
    const getRoutes = () => {
		return routeAuth.map((data, key) => {
			if (data.layout === "/auth") {
				return (
					<Route path={data.layout + data.path} component={data.component} key={key} />
				);
			} else {
				return null;
			}
		});
	}

    return (
        <div className='min-h-screen bg-soft flex justify-center items-center font-poppins'>
            <Switch>
                {getRoutes()}
                <Redirect from="*" to="/auth/sign-in" />
            </Switch>
        </div>
    )
}

export default Auth