import {
  faArrowLeft,
  faBookReader,
  faChartLine,
  faRecycle,
  faStreetView,
  faViruses,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import L from "leaflet";
import { useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import { useHistory, useParams } from "react-router-dom";

import { getDataMonitoringProbe, getDataTaking } from "../../api/trxprobe-api";
import Helper, { isLatitude, isLongitude } from "../../helpers/helper";

import { getAnalystNote, getThreatment } from "../../api/recommendation-api";
import pointLand from "../../assets/img/green-dot.ico";
import pointSampling from "../../assets/img/maps-agroobot.png";
import pointCurrent from "../../assets/img/marker-red.png";
import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage";
import AnalystNote from "./analytics/AnalystNote";
import DataGraph from "./analytics/DataGraph";
import LandTreatment from "./analytics/LandTreatment";
import VirusControl from "./analytics/VirusControl";

const icon = new L.Icon({
  iconUrl: pointLand,
  iconRetinaUrl: pointLand,
  iconSize: new L.Point(20, 20),
  // className: 'leaflet-div-icon'
});

const iconSampling = new L.Icon({
  iconUrl: pointSampling,
  iconRetinaUrl: pointSampling,
  iconSize: new L.Point(25, 30),
  // className: 'leaflet-div-icon'
});

const iconCurrent = new L.Icon({
  iconUrl: pointCurrent,
  iconRetinaUrl: pointCurrent,
  iconSize: new L.Point(25, 31),
  // className: 'leaflet-div-icon'
});

const RecommendationDetail = () => {
  const [loader, showLoader] = useState(true);
  const [modalErr, showModalErr] = useState(false);
  const [messageErr, setMessageErr] = useState("");

  const [sampling, setSampling] = useState(0);
  const [active, setActive] = useState(0);

  const [arrDataTaking, setArrDataTaking] = useState([]);
  const [dataTaking, setDataTaking] = useState(0);
  const [allData, setAllData] = useState([]);
  const [currentDataMobile, setCurrentDataMobile] = useState(null);
  const [dataStatic, setDataStatic] = useState(null);
  const [dataLand, setDataLand] = useState(null);
  const [analystNote, setAnalystNote] = useState("");
  const [virusControl, setVirusControl] = useState("");

  const [center, setCenter] = useState([-6.175372, 106.827194]);
  const [map, setMap] = useState(null);

  const history = useHistory();
  const { land_code_var } = useParams();

  useEffect(() => {
    async function fetchDataMonitoring() {
      const res = await getDataMonitoringProbe(
        `land_code_var=${land_code_var}`
      );

      console.log("MONITORING :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setDataStatic(
            res.data.data.static_probe?.sort(
              (a, b) => parseInt(a.id_seq) - parseInt(b.id_seq)
            )
          );

          setDataLand(res.data.data.land);
          showLoader(false);
        } else {
          setMessageErr(res.data.message);
          showModalErr(true);
          showLoader(false);
        }
      } else {
        alert("Connection timeout...");
        fetchDataMonitoring();
      }
    }

    fetchDataMonitoring();
  }, [land_code_var]);

  // useEffect(() => {
  //   if (dataLand) {
  //     const centerMap = Helper.getCenterMapFromStr(
  //       dataLand.land_coordinates_txt
  //     );

  //     setCenter(centerMap);

  //     setTimeout(() => {
  //       map?.flyTo([centerMap.lat, centerMap.lng], getZoom(dataLand.area_var), {
  //         animate: true,
  //       });
  //     }, 500);
  //   }
  // }, [map, dataLand]);

  useEffect(() => {
    if (currentDataMobile) {
      setCenter({ lat: currentDataMobile?.latitude_flo, lng: currentDataMobile?.longitude_flo });

      setTimeout(() => {
        map?.flyTo([currentDataMobile.latitude_flo, currentDataMobile.longitude_flo], 15, {
          animate: true,
        });
      }, 500);
    }
  }, [map, currentDataMobile]);

  useEffect(() => {
    async function fetchDataTaking() {
      const res = await getDataTaking(land_code_var);

      console.log("Get Data Taking :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setArrDataTaking(res.data.data);
          setDataTaking(
            res.data.data[res.data.data.length - 1]?.data_taking_int
          );
          showLoader(false);
        } else {
          setMessageErr(res.data.message);
          showModalErr(true);
          showLoader(false);
        }
      } else {
        alert("Connection timeout...");
        fetchDataTaking();
      }
    }

    fetchDataTaking();
  }, [land_code_var]);

  useEffect(() => {
    async function fetchThreatment() {
      const res = await getThreatment(land_code_var, dataTaking);

      console.log("Get Threatment :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setCurrentDataMobile(
            res.data.data.list_sampling[res.data.data.list_sampling.length - 1]
          );
          setAllData(res.data.data.list_sampling);
          setSampling(res.data.data.list_sampling.length);
          showLoader(false);
        } else {
          setMessageErr(res.data.message);
          showModalErr(true);
          showLoader(false);
        }
      } else {
        alert("Connection timeout...");
      }
    }

    async function fetchAnalystNote() {
      const res = await getAnalystNote(land_code_var, dataTaking);

      console.log("Get Analyst Note :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setAnalystNote(res.data.data.analyst_notes_txt);
          setVirusControl(res.data.data.virus_control_txt);
        }
      } else {
        alert("Connection timeout...");
      }
    }

    if (
      dataTaking !== 0 &&
      dataTaking !== "0" &&
      dataTaking !== "" &&
      dataTaking !== null
    ) {
      fetchThreatment();
      fetchAnalystNote();
    }
  }, [dataTaking, land_code_var]);

  const handleClickMarker = (e, data, sampling) => {
    setCurrentDataMobile(data);
    setSampling(sampling);
  };

  const getZoom = (areaWidth) => {
    return 13

    const width = parseFloat(areaWidth);
    if (width < 3) {
      return 18;
    } else if (width < 20) {
      return 17;
    } else if (width < 60) {
      return 16;
    } else if (width < 210) {
      return 15;
    } else if (width < 750) {
      return 14;
    } else if (width < 2000) {
      return 13;
    } else if (width < 10000) {
      return 12;
    } else if (width < 75000) {
      return 11;
    } else if (width < 500000) {
      return 10;
    } else if (width < 1000000) {
      return 9;
    } else {
      return 8;
    }
  };

  return (
    <>
      <div className="flex flex-col-reverse md:flex-row-reverse my-auto mb-auto p-3 md:p-6 bg-soft">
        {/* <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>DETAIL ANALYTICS</h1>
                <h2 className='text-base font-medium'>Analytics<span className='text-xs'> / </span><span className='text-xs xs:text-sm'>Detail Analytics</span></h2>
            </div> */}

        <div className="bg-white w-full md:w-5/12 rounded-2xl md:rounded-l-none shadow-xl p-5 md:pl-8 md:-ml-3 mt-5 md:mt-0 overflow-y-auto h-75vh">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="font-semibold">Rekomendasi</h1>
              <p>Hasil analisa dari ahli</p>
            </div>
            <select
              value={dataTaking}
              onChange={(e) => setDataTaking(e.target.value)}
              className="outline-none border-1 border-green-700 px-2 py-1 rounded-2xl"
            >
              <option value="">Pengambilan Data</option>
              {arrDataTaking.length > 0 &&
                arrDataTaking.map((data, key) => (
                  <option key={key} value={data.data_taking_int}>
                    Pengambilan {data.data_taking_int}
                  </option>
                ))}
            </select>
          </div>
          <div className="flex justify-center mt-5 text-xs -mx-3 md:-mx-1.5">
            <button
              className={`flex flex-col items-center justify-center text-center w-30 ${
                active === 0
                  ? "border-2 border-green-700"
                  : "border-1 border-gray-100"
              } hover:border-green-700 shadow rounded-lg p-1.5 md:py-1.5 md:px-0 mr-2 md:mr-2`}
              onClick={() => setActive(0)}
            >
              <span className="w-7 md:w-8 h-7 md:h-8 rounded-full bg-green_sidebar text-green-600 flex items-center justify-center text-base mb-2 md:mb-4">
                <FontAwesomeIcon icon={faRecycle} />
              </span>
              <p className="text-xss md:text-xsm font-medium">
                Treatment
                <br />
                Lahan
              </p>
            </button>
            <button
              className={`flex flex-col items-center justify-center text-center w-30 ${
                active === 1
                  ? "border-2 border-green-700"
                  : "border-1 border-gray-100"
              } hover:border-green-700 shadow rounded-lg p-1.5 md:py-1.5 md:px-0 mr-2 md:mr-2`}
              onClick={() => setActive(1)}
            >
              <span className="w-7 md:w-8 h-7 md:h-8 rounded-full bg-green_sidebar text-green-600 flex items-center justify-center text-base mb-2 md:mb-4">
                <FontAwesomeIcon icon={faViruses} />
              </span>
              <p className="text-xss md:text-xsm font-medium">
                Kendali
                <br />
                Virus
              </p>
            </button>
            <button
              className={`flex flex-col items-center justify-center text-center w-30 ${
                active === 2
                  ? "border-2 border-green-700"
                  : "border-1 border-gray-100"
              } hover:border-green-700 shadow rounded-lg p-1.5 md:py-1.5 md:px-0 mr-2 md:mr-2`}
              onClick={() => setActive(2)}
            >
              <span className="w-7 md:w-8 h-7 md:h-8 rounded-full bg-green_sidebar text-green-600 flex items-center justify-center text-base mb-2 md:mb-4">
                <FontAwesomeIcon icon={faBookReader} />
              </span>
              <p className="text-xss md:text-xsm font-medium">
                Catatan
                <br />
                Analis
              </p>
            </button>
            <button
              className={`flex flex-col items-center justify-center text-center w-30 ${
                active === 3
                  ? "border-2 border-green-700"
                  : "border-1 border-gray-100"
              } hover:border-green-700 shadow rounded-lg p-1.5 md:py-1.5 md:px-0 mr-2 md:mr-2`}
              onClick={() => setActive(3)}
            >
              <span className="w-7 md:w-8 h-7 md:h-8 rounded-full bg-green_sidebar text-green-600 flex items-center justify-center text-base mb-2 md:mb-4">
                <FontAwesomeIcon icon={faChartLine} />
              </span>
              <p className="text-xss md:text-xsm font-medium">
                Data
                <br />
                Grafis
              </p>
            </button>
            <button
              className="flex flex-col items-center justify-center text-center w-30 border-1 border-gray-100 hover:border-green-700 shadow rounded-lg md:rounded-2l px-1 py-1.5 md:px-0"
              onClick={() =>
                history.push(`/admin/lahan-monitoring/${land_code_var}`)
              }
            >
              <span className="w-7 md:w-8 h-7 md:h-8 rounded-full bg-green_sidebar text-green-600 flex items-center justify-center text-base mb-2 md:mb-4">
                <FontAwesomeIcon icon={faStreetView} />
              </span>
              <p className="text-xss md:text-xsm font-medium">
                Monnitoring
                <br />
                Lahan
              </p>
            </button>
          </div>

          {/* <div className='mt-5 overflow-y-auto h-2/3'> */}
          <div className="mt-5">
            {active === 0 && (
              <LandTreatment
                data={currentDataMobile}
                sampling={sampling}
                landCode={land_code_var}
                dataTaking={dataTaking}
              />
            )}
            {active === 1 && (
              <VirusControl
                commodityName={dataLand?.commodity_name_var}
                data={virusControl}
              />
            )}
            {active === 2 && (
              <AnalystNote
                commodityName={dataLand?.commodity_name_var}
                data={analystNote}
              />
            )}
            {active === 3 && (
              <DataGraph
                commodityName={dataLand?.commodity_name_var}
                data={allData}
                dataStatic={dataStatic}
              />
            )}
          </div>
        </div>

        {/* MAP SAMPLING */}
        {dataLand && (
          <div className="w-full md:w-8/12 relative">
            <button
              className="absolute top-3 left-3 w-10 h-10 rounded-full flex justify-center items-center text-lg bg-white bg-opacity-30 text-white hover:bg-opacity-100 hover:text-green-800"
              style={{ zIndex: 1 }}
              onClick={() => history.goBack()}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <div
              className="absolute bottom-3 md:bottom-5 left-3 md:left-5 text-white"
              style={{ zIndex: 1 }}
            >
              <p>
                {Helper.capitalEachWord(dataLand?.city_name)},{" "}
                {Helper.capitalEachWord(dataLand?.prov_name)} - Luas Lahan{" "}
                {dataLand.area_var} Ha
              </p>
              <h1 className="text-2xl md:text-4xl font-bold">
                {dataLand?.land_name_var}
              </h1>
            </div>
            <MapContainer
              center={center}
              whenCreated={setMap}
              scrollWheelZoom={true}
              zoom={getZoom(dataLand.area_var)}
              zoomAnimation={true}
              zoomControl={false}
              className="w-full h-50vh md:h-75vh shadow-xl rounded-2xl z-0"
            >
              <ZoomControl position="topright" />
              <TileLayer
                maxZoom={20}
                zoomOffset={-1}
                id=""
                tileSize={512}
                attribution="by Agroobot"
                url="https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA"
              />

              {/* area */}
              <Polygon
                pathOptions={{ color: "lime" }}
                positions={Helper.generatelatLngFromStr(
                  dataLand.land_coordinates_txt
                )}
              />
              {dataLand.land_coordinates_txt.split(";").map((koor, key) => (
                <Marker
                  icon={icon}
                  key={key}
                  position={[
                    isLatitude(koor.split(",")[0]) ? koor.split(",")[0] : 0,
                    isLongitude(koor.split(",")[1]) ? koor.split(",")[1] : 0,
                  ]}
                ></Marker>
              ))}

              {/* Sampling marker */}
              {allData &&
                allData.map((data, key) => (
                  <Marker
                    position={[
                      currentDataMobile?.latitude_flo || "",
                      currentDataMobile?.longitude_flo || "",
                    ]}
                    icon={iconCurrent}
                    key={key}
                  >
                    <Marker
                      position={[
                        data?.latitude_flo || 0,
                        data?.longitude_flo || 0,
                      ]}
                      icon={iconSampling}
                      eventHandlers={{
                        click: (e) => {
                          handleClickMarker(e, data, key + 1);
                        },
                      }}
                    >
                      <Popup>Selected</Popup>
                    </Marker>
                  </Marker>
                ))}
            </MapContainer>
          </div>
        )}
      </div>

      {loader && <Loader />}
      {modalErr && (
        <ModalMessage
          message={messageErr}
          onClose={() => showModalErr(false)}
        />
      )}
    </>
  );
};

export default RecommendationDetail;
