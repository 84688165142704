import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { getAllLand } from "../../api/landarea-api";
import { getAllProject } from "../../api/project-api";
import ErrorField from "../ErrorField";
import { CancelButton, SaveButton } from "../button/CustomButton";
import Loader from "./Loader";
import ModalMessage from "./ModalMessage";

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1'

const ModalFormProbe = ({data, probeType, onCancel, onSubmit}) => {
    const [dataProject, setDataProject] = useState([])
    const [selectedCodeProject, setSelectedCodeProject] = useState('')
    const [selectedCodeLahan, setSelectedCodeLahan] = useState('')
    const [selectedProbeType, setSelectedProbeType] = useState(0)
    const [dataLahan, setDataLahan] = useState([])
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback ((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if(data) {
            setdefaultValue(data)
            setSelectedProbeType(data?.probe_type_id_int)
            setSelectedCodeProject(data?.project_code_var)
            setSelectedCodeLahan(data?.land_code_var)
        }
    }, [data, setdefaultValue])


    const fetchProject = useCallback(async() => {
        const res = await getAllProject()

        if(res.data){
            if(res.data.code === 0){
                setDataProject(res.data.data)
                showLoader(false)
            }else{
                showModalErr(true)
                setMessageErr(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [])

    const fetchLahan = useCallback(async() => {
        showLoader(true)
        const res = await getAllLand()

        if(res.data){
            if(res.data.code === 0){
                setDataLahan(res.data.data)
                fetchProject()
            }else{
                showModalErr(true)
                setMessageErr(res.data.message)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [fetchProject])

    useEffect(() => {
        fetchLahan()
    }, [fetchLahan])


    const onValid = (dataForm) => {
        const {probe_code_var, probe_name_var, probe_type_id_int, ip_address_var, mac_address_var, land_code_var, project_code_var, pin_code_var, antares_hierarchical_uri, antares_access_key} = dataForm

        const payload = parseInt(selectedProbeType) === 2? 
            { probe_code_var, probe_name_var, probe_type_id_int, ip_address_var, mac_address_var, land_code_var, project_code_var, pin_code_var, antares_hierarchical_uri, antares_access_key }
            :
            { probe_code_var, probe_name_var, probe_type_id_int, ip_address_var, mac_address_var, pin_code_var, antares_hierarchical_uri, antares_access_key  }

        onSubmit(payload)
    }

    
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-10/12 md:w-1/2 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8'>{data? 'Form Ubah Probe': 'Form Tambah Probe Baru'}</h1>
                <form onSubmit={handleSubmit(onValid)}>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Code Probe</label>
                            <input type='text' className={inputText} placeholder='10406' 
                                {...register('probe_code_var', {
                                    required: 'This field is required.', minLength: {value: 4, message: 'Probe code length at least 4 characters'}
                                })}
                            />
                            <ErrorField errors={errors} name='probe_code_var' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Nama Probe</label>
                            <input type='text' className={inputText} placeholder='Probe 1' 
                                {...register('probe_name_var', {required: 'This field is required.'})} 
                            />
                            <ErrorField errors={errors} name='probe_name_var' />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3 md:mr-5'>
                        <div className={containerInput}>
                            <label>Tipe Probe</label>
                            <select {...register("probe_type_id_int", { required: "This field is required."})} value={selectedProbeType} onChange={(e)=>setSelectedProbeType(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                <option value=''>- Pilih Tipe Probe -</option>
                                {probeType?.map((data, key) => (
                                    <option key={key} value={data.id_int}>[{data.probe_type_code_var}] {data.probe_type_description_var}</option>
                                ))}
                            </select>
                            <ErrorField errors={errors} name='probe_type_id_int' />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>IP Address</label>
                            <input type='text' className={inputText} placeholder='101.101.100.111' 
                                {...register('ip_address_var', {required: 'This field is required.'})} 
                            />
                            <ErrorField errors={errors} name='ip_address_var' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>MAC Address</label>
                            <input type='text' className={inputText} placeholder='00:00:5e:00:53:af' 
                                {...register('mac_address_var', {required: 'This field is required.'})}
                            />
                            <ErrorField errors={errors} name='mac_address_var' />
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Antares - Hierarchical URI</label>
                            <input type='text' className={inputText} placeholder='https://platform.antares.id:8443/~/antares-cse/antares-id/xxxx' 
                                {...register('antares_hierarchical_uri')} 
                            />
                            <ErrorField errors={errors} name='antares_hierarchical_uri' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                        <label>Antares - Access Key</label>
                            <input type='text' className={inputText} placeholder='xxxxxxxxxx' 
                                {...register('antares_access_key')}
                            />
                            <ErrorField errors={errors} name='antares_access_key' />
                        </div>
                    </div>

                    {parseInt(selectedProbeType) === 2 &&
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Kode Project</label>
                            <select {...register("project_code_var")} value={selectedCodeProject} onChange={(e)=>{setSelectedCodeProject(e.target.value)}} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                <option value=''>- Pilih Projek -</option>
                                {dataProject?.map((data, key) => (
                                    <option key={key} value={data.project_code_var}>[{data.project_code_var}] {data.project_name_var}</option>
                                ))}
                            </select>
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Kode Lahan</label>
                            <select {...register("land_code_var")} value={selectedCodeLahan} onChange={(e)=>setSelectedCodeLahan(e.target.value)} className='border-1 border-gray-200 outline-none mt-1 rounded-lg px-3 py-2.5'>
                                <option value=''>- Pilih Lahan -</option>
                                {dataLahan.filter(data => data.project_code_var === selectedCodeProject)?.map((data, key) => (
                                    <option key={key} value={data.land_code_var}>[{data.land_code_var}] {data.land_name_var}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    }

                    <div className='flex justify-end mt-8'>
                        <CancelButton type='reset' onClick={onCancel} />
                        <SaveButton type='submit' />
                    </div>
                </form>
        
                {/* End Body */}
            </div>
            {/* End Modal Content */}
            {loader && <Loader />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
        </div>
    )
}

export default ModalFormProbe