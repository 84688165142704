const Schedule = () => {
    return (
        <div className='flex item-center flex-col overflow-y-auto p-6'>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='text-base font-semibold'>Schedule</h1>
                <h2 className='text-base font-medium'>Menu<span className='text-xs'> / </span><span className='text-sm'>Schedule</span></h2>
            </div>
            <div className='flex flex-wrap items-center justify-between xl:justify-between lg:justify-around md:justify-evenly w-full -mb-5'>
            </div>
        </div>
    )
}

export default Schedule