import { useEffect, useRef, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";
import { Link, useLocation, useHistory} from "react-router-dom";
import Helper from "../helpers/helper";
import Header from "./Header";
import agrooLogo from '../assets/img/logo-dark.png'

const Sidebar = ({user}) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    const location = useLocation()
    const history = useHistory()

	const trigger = useRef(null);
	const sidebar = useRef(null);

	// close on click outside
	useEffect(() => {
		const clickHandler = ({ target }) => {
			if (!sidebar.current || !trigger.current) return;
			if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
			setSidebarOpen(false);
			console.log('TARGET', target)
		};
		document.addEventListener('click', clickHandler);
		return () => document.removeEventListener('click', clickHandler);
	});
	
	  // close if the esc key is pressed
	useEffect(() => {
		const keyHandler = ({ keyCode }) => {
			if (!sidebarOpen || keyCode !== 27) return;
			setSidebarOpen(false);
		};
		document.addEventListener('keydown', keyHandler);
		return () => document.removeEventListener('keydown', keyHandler);
	});

    const handleClickLogo = () => {
        history.replace('/')
        window.location.reload()
    }

    const activePath = (layout, path) => {
        if(location.pathname === layout + path){
            return true
        }else if(location.pathname.includes(path)){
            if(path){
                return true
            }
        }else {
            return false
        }
        // return location.pathname === layout + path? true : false
    }

    const getRoutesPath = () => {
        return Helper.getMainRoutes(user.user_group_id_int).map(({title, routes}, key) => 
            <div key={key} className='mb-5'>
                <p className='mb-4'>{title}</p>
                <ul>
                {routes.map(({layout, path, name, icon_path}) => 
                    <li key={path}>
                        <Link to={layout + path} onClick={() => setSidebarOpen(false)}
                            className={`px-3 py-2.5 rounded-lg my-2 ${activePath(layout,path)? 'bg-green_sidebar text-gray-700': 'text-gray-700'} hover:text-gray-700 hover:bg-green_sidebar block transition duration-150`}>
                            <div className="flex items-center">
                                <span>{icon_path}</span>
                                <span>{name}</span>
                            </div>
                        </Link>
                    </li>
                )}
                </ul>
            </div>
        )
    }

    return (
        <div className='md:w-64 2xl:w-72'>
            
            {/* SIDEBAR MOBILE */}
            <div className={`fixed inset-0 bg-darkcayn bg-opacity-30 z-40 md:hidden md:z-auto transition-opacity duration-200 ${sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`} aria-hidden="true" onClick={() => setSidebarOpen(false)}></div>
            <div className='md:hidden bg-white sticky top-0 w-full h-12 py-8 px-4 sm:px-6 lg:px-8 flex items-center justify-between shadow rounded-2xl z-30'>
                <button onClick={() => setSidebarOpen(!sidebarOpen)}
                    className='inline-flex items-center justify-center p-1 rounded-md text-darkcayn hover:bg-darkcayn hover:text-white outline-none focus:ring-2 focus:ring-offset-2 focus:ring-darkcayn'
                >	
                    {sidebarOpen?
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true" >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                    </svg>
                    :
                    <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
                    </svg>
                    }
                </button>
                <Header />
            </div>
            
            {/* SIDEBAR */}
            <div id="sidebar" ref={sidebar}
                className={`absolute z-40 left-0 top-0 md:static md:left-auto md:top-auto md:translate-x-0 transform h-screen w-64 2xl:w-72 flex-shrink-0 bg-white border-r-1 transition-transform duration-200 ease-in-out ${sidebarOpen ? 'translate-x-0' : '-translate-x-64'}`}
            >
                <div className='overflow-y-auto p-4 flex flex-col items-center h-full py-4 font-poppins text-gray-700'>
                    {/* <h1 className='text-2xl'>Apps Logo</h1> */}
                    <span className='mt-2 cursor-pointer' onClick={handleClickLogo}><LazyLoadImage effect='blur' src={agrooLogo} className='w-22' /></span>
                    <div className='w-full h-full px-1 mt-8 flex flex-col'>
                    
                    {getRoutesPath()}

                        {/* <button onClick={handleLogout} className={`px-3 py-2 rounded-md my-2 text-gray-200 hover:text-white hover:bg-lightcayn`}>
                            <div to='' className='block transition duration-150'>
                                <div className="flex flex-grow">
                                    <svg className="flex-shrink-0 h-6 w-6 mr-3" viewBox="0 0 512.025 512.025" ><g><g><g>
                                        <path className='fill-current text-gray-400' d="m177.646 179.831c-3.349-29.547-26.752-51.819-54.4-51.819h-54.464c-27.648 0-51.051 22.272-54.4 51.819l-14.144 123.904c-1.173 10.368 2.027 20.651 8.768 28.224 5.803 6.528 13.547 10.304 21.909 10.773l11.776 159.403c.448 5.568 5.077 9.877 10.667 9.877h85.333c5.589 0 10.219-4.309 10.645-9.877l11.776-159.403c8.384-.469 16.107-4.267 21.909-10.773 6.741-7.552 9.941-17.856 8.768-28.224z"/>
                                        <path className='fill-current text-gray-400' d="m96.025 106.679c29.461 0 53.333-23.872 53.333-53.333s-23.893-53.333-53.333-53.333c-29.461 0-53.333 23.872-53.333 53.333-.001 29.461 23.871 53.333 53.333 53.333z"/>
                                        <path className='fill-current text-gray-400' d="m354.564 20.471c4.651-1.984 7.253-6.997 6.251-11.947-1.024-4.949-5.397-8.512-10.453-8.512h-105.004c-17.643 0-32 14.357-32 32v384c0 17.643 14.357 32 32 32h21.333c5.888 0 10.667-4.779 10.667-10.667v-339.327c0-26.987 16.043-51.307 40.853-61.952z"/>
                                        <path className='fill-current text-gray-700' d="m465.966.012c-6.293 0-12.395 1.259-18.176 3.733l-121.195 51.947c-16.939 7.253-27.904 23.872-27.904 42.325v367.936c0 25.387 20.672 46.059 46.059 46.059 6.293 0 12.395-1.259 18.176-3.733l121.195-51.947c16.939-7.253 27.904-23.872 27.904-42.325v-367.936c0-25.387-20.672-46.059-46.059-46.059zm-103.275 288c0 5.888-4.779 10.667-10.667 10.667s-10.667-4.779-10.667-10.667v-42.667c0-5.888 4.779-10.667 10.667-10.667s10.667 4.779 10.667 10.667z"/></g></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/>
                                    </svg>
                                    <span className="text-base font-medium">Logout</span>
                                </div>
                            </div>
                        </button> */}
                        
                    </div>
                </div>
            </div>
        </div>
    
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(Sidebar)