import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import ErrorField from "../ErrorField";
import { CancelButton, SaveButton } from "../button/CustomButton";

const containerInput = 'flex flex-col w-full sm:w-1/2'
const inputText = 'outline-none border-1 border-gray-200 rounded-lg py-2 px-3 sm:p-3 mt-1'

const ModalFormLandType = ({data, onCancel, onSubmit}) => {

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({ criteriaMode: "all" });

    const setdefaultValue = useCallback ((obj) => {
        Object.keys(obj).map(key => {
            return setValue(key, obj[key], { shouldValidate: true })
        })
    }, [setValue])

    useEffect(() => {
        if(data) {
            setdefaultValue(data)
        }
    }, [data, setdefaultValue])


    const onValid = (dataForm) => {
        const {name, density_top, density_bottom} = dataForm

        const payload = { name, density_top, density_bottom }

        onSubmit(payload)
    }
    
    return (
        <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-30">
            <div className="absolute w-full h-full bg-gray-900 opacity-50" onClick={onCancel}></div>

            {/* Modal Content */}
            <div className="bg-soft w-10/12 md:w-1/2 mx-auto my-auto p-6 rounded-xl shadow-2xl z-50 overflow-y-auto" style={{ maxHeight: '90vh'}}>
                {/* Body */}
                <h1 className='text-base font-medium mb-8'>{data? 'Form Ubah Jenis Tanah': 'Form Tambah Jenis Tanah'}</h1>
                <form onSubmit={handleSubmit(onValid)}>
                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Nama Jenis Tanah</label>
                            <input type='text' className={inputText} placeholder='Nama Komoditas' 
                                {...register('name', { required: 'This field is required.'})}
                            />
                            <ErrorField errors={errors} name='name' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                        </div>
                    </div>

                    <div className='flex flex-col sm:flex-row mb-3'>
                        <div className={containerInput}>
                            <label>Batas Atas (kg/dm3)</label>
                            <input type='number' className={inputText} {...register('density_top', { required: 'This field is required.'})} />
                            <ErrorField errors={errors} name='density_top' />
                        </div>
                        <span className='mx-3 mb-3 sm:mb-0'></span>
                        <div className={containerInput}>
                            <label>Batas Bawah (kg/dm3)</label>
                            <input type='number' className={inputText}  {...register('density_bottom', { required: 'This field is required.'})} />
                            <ErrorField errors={errors} name='density_bottom' />
                        </div>
                    </div>



                    <div className='flex justify-end mt-8'>
                        <CancelButton type='reset' onClick={onCancel} />
                        <SaveButton type='submit' />
                    </div>
                </form>
        
                {/* End Body */}
            </div>
            {/* End Modal Content */}
        </div>
    )
}

export default ModalFormLandType