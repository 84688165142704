import { faChartBar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import moment from "moment"
import { useState } from "react"
import ReactApexChart from "react-apexcharts"


const seriesTanah2 = (label1, dataLabel1, label2, dataLabel2, label3, dataLabel3) => [
    {
        name: label1,
        type: 'column',
        data: dataLabel1
    },
    {
        name: label2,
        type: 'column',
        data: dataLabel2
    },
    {
        name: label3,
        // type: 'line',
        type: 'column',
        data: dataLabel3
    },
]

const optionsTanah2 = (sampling) => {
    return {
        chart: {
            type: "line",
            stacked: false,
            toolbar: { show: !1 },
            zoom: {
                enabled: true,
                type: 'x',
                resetIcon: {
                    offsetX: -10,
                    offsetY: 0,
                    fillColor: '#fff',
                    strokeColor: '#37474F'
                },
                selection: {
                    background: '#90CAF9',
                    border: '#0D47A1'
                }    
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: ["#556ee6", "#f1b44c", "#8f8"],
        stroke: {
            width: [4, 4, 4]
        },
        plotOptions: {
            bar: {
                columnWidth: "60%"
            }
        },
        xaxis: {
            categories: sampling,
            labels: {
                style: {
                    colors: "#247BA0"
                }
            },
            title: {
                text: "Sampling ke-",
                style: {
                    color: '#000',
                    fontSize: '12px',
                    fontWeight: 500,
                    fontFamily: 'Poppins, Arial, sans-serif',
                },
            }
        },
        yaxis: 
        // [
            {
                // seriesName: 'Acuan',
                axisTicks: {
                    show: true
                },
                axisBorder: {
                    show: true,
                },
                labels: {
                    style: {
                        colors: "#247BA0"
                    }
                },
                forceNiceScale: true,
                decimalsInFloat: 0,
                min: 0
            },
        //     {
        //         seriesName: 'Real',
        //         show: false
        //     }, 
        //     {
        //         opposite: true,
        //         seriesName: 'Needs',
        //         axisTicks: {
        //             show: true
        //         },
        //         axisBorder: {
        //             show: true,
        //         },
        //         title: {
        //             text: "Needs"
        //         }
        //     }
        // ],
        markers: { 
            size: 3, strokeWidth: 3, hover: { size: 4, sizeOffset: 2 } 
        },
        legend: { 
            position: "top", horizontalAlign: "right" 
        }
    }
}

const seriesIklim = (label, dataLabel) => [
    { name: label, data: dataLabel }
]

const optionsIklim = (sampling, color, today) => {return {
	chart: { type: "area", toolbar: { show: !1 } },
	colors: [color],
	dataLabels: { 
        enabled: !1 
    },
	stroke: { 
        curve: "smooth", width: 2.5
    },
	fill: {
		type: "gradient",
		gradient: { 
            shadeIntensity: 1, inverseColors: !1, opacityFrom: .60, opacityTo: .05, stops: [50, 100, 100, 100] 
        }
	},
	xaxis: { 
        categories: sampling,
        labels: {
            style: {
                colors: "#247BA0"
            }
        },
        title: {
            text: "Timestamp - " + moment(today).format('DD MMM YY'),
            style: {
                color: '#000',
                fontSize: '12px',
                fontWeight: 500,
                fontFamily: 'Poppins, Arial, sans-serif',
            },
        },
    },
    yaxis: {
        axisTicks: {
            show: true
        },
        labels: {
            style: {
                colors: "#247BA0"
            }
        },
        forceNiceScale: true,
        min: 0
    },
	markers: { 
        size: 3, strokeWidth: 3, hover: { size: 4, sizeOffset: 2 } 
    },
	legend: { 
        position: "top", horizontalAlign: "right" 
    }
}}

const COLORS = ["#556ee6", "#f1b44c", "#8f8"]

const DataGraph = ({commodityName, data, dataStatic}) => {
    const [type, setType] = useState('n_value_flo')
    const [typeCom, setTypeCom] = useState('n_value_var')
    const [typeNeeds, setTypeNeeds] = useState('needs_n')
    const [typeIklim, setTypeIklim] = useState('air_temperature_flo')
    const [color, setColor] = useState("#8f8")

    const sampling = Array.from({length: data.length}, (_, i) => i + 1)

    const handleChangeTypeTanah = (e) => {
        const tipe = e.target.value?.split('|')
        setTypeCom(tipe[0] || '')
        setType(tipe[1] || '')
        setTypeNeeds(tipe[2] || '')
    }


    const handleChangeTypeIklim = (e) => {
        setTypeIklim(e.target.value)
        setColor(COLORS[Math.floor(Math.random() * COLORS.length)])
    }

    return (
        <div className='bg-white px-3 py-5 md:p-5 rounded-2xl shadow-lg'>
            <div className='flex'>
                <span className='bg-green_sidebar text-green-600 rounded-full flex justify-center items-center p-2 w-10 h-10 text-base'>
                    <FontAwesomeIcon icon={faChartBar} />
                </span>
                <div className='text-left ml-2.5'>
                    <p className='font-semibold'>Data Grafis</p>
                    <p>{commodityName || '-'}</p>
                </div>
            </div>
            
            <div className='mb-4 mt-5 bg-gray-200' style={{height: 1}}></div>

            <div className='font-medium'>
                <div className='flex justify-between items-center'>
                    <h1>Data Tanah</h1>
                    <select onChange={handleChangeTypeTanah} className='outline-none px-2 py-1 -mx-1 rounded-2xl border-1 border-green-700 text-green-700 font-semibold'>
                        <option value='n_value_var|n_value_flo|needs_n'>Nilai N</option>
                        <option value='p_value_var|p_value_flo|needs_p'>Nilai P</option>
                        <option value='k_value_var|k_value_flo|needs_k'>Nilai K</option>
                        <option value='ph_value_var|ph_flo|needs_ph'>PH</option>
                    </select>
                </div>
                <div className='mt-5 -mx-3'>
                    {/* <ReactApexChart 
                        options={optionsTanah(sampling)}
                        series={seriesTanah('Acuan', getOptionAcuan(), 'Real', dataMobile.map((item) => item[type]), 'Needs', getNeeds())}
                        type='area'
                    /> */}
                    <ReactApexChart 
                        options={optionsTanah2(sampling)}
                        series={
                            seriesTanah2(
                                'Acuan', data.map((item) => item[typeCom]), 
                                'Real', data.map((item) => item[type]), 
                                'Needs', data.map((item) => item[typeNeeds])
                            )
                        }
                    />
                </div>
            </div>

            <div className='mb-6 mt-4 bg-gray-200' style={{height: 1}}></div>

            <div className='font-medium'>
                <div className='flex justify-between items-center'>
                    <h1>Data Iklim</h1>
                    <select onChange={handleChangeTypeIklim} className='outline-none px-2 py-1 -mx-1 rounded-2xl border-1 border-green-700 text-green-700 font-semibold'>
                        <option value='air_temperature_flo'>Suhu</option>
                        <option value='air_humidity_flo'>Kelembaban</option>
                        <option value='intensity_flo'>Intensitas</option>
                        <option value='wind_velocity_flo'>Kecepatan Angin</option>
                        <option value='rainfall_flo'>Curah Hujan</option>
                    </select>
                </div>
                <div className='-ml-3 -mr-5'>
                    {dataStatic && dataStatic.length > 0 ?
                        <ReactApexChart 
                            options={optionsIklim(dataStatic.map((data) => moment(data.time_on_dtm).format('HH:mm')), color, dataStatic[0].time_on_dtm)}
                            series={seriesIklim(typeIklim, dataStatic.map((data) => data[typeIklim]))}
                            type='area'
                        />
                        :
                        <div className='flex justify-center items-center font-normal mt-10 mb-3'>
                            Belum ada data iklim
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default DataGraph