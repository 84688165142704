import {
  faAirFreshener,
  faArrowDown
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import { useHistory, useParams } from "react-router-dom";

import { deleteTrxProbe, getDataMonitoringProbe, getDataTaking } from "../../api/trxprobe-api";
import pointLand from "../../assets/img/green-dot.ico";
import pointSampling from "../../assets/img/maps-agroobot.png";
import pointCurrent from "../../assets/img/marker-red.png";
import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage";
import { isLatitude, isLongitude } from "../../helpers/helper";
import { SocketProvider, useSocket } from "../../utils/SocketProvider";
import LandFertilizer from "./analytics/LandFertilizer";
import axios from "axios";
import { usePDF } from 'react-to-pdf';

// const maxPH = 8;
// function valueToPercent(value) {
// 	return (value * 70) / maxPH
// }
const seriesLUX = [100];
var optionLUX = {
  chart: {
    offsetY: -5,
    offsetX: -45,
    // toolbar: {
    //     show: true,
    //     offsetX: 0,
    //     offsetY: 0,
    //     tools: {
    //       download: true,
    //       selection: true,
    //       zoom: true,
    //       zoomin: true,
    //       zoomout: true,
    //       pan: true,
    //       reset: true | '<img src="/static/icons/reset.png" width="20">',
    //       customIcons: []
    //     },
    //     export: {
    //       csv: {
    //         filename: undefined,
    //         columnDelimiter: ',',
    //         headerCategory: 'category',
    //         headerValue: 'value',
    //         dateFormatter(timestamp) {
    //           return new Date(timestamp).toDateString()
    //         }
    //       },
    //       svg: {
    //         filename: undefined,
    //       },
    //       png: {
    //         filename: undefined,
    //       }
    //     },
    //     autoSelected: 'zoom'
    //   },
    // sparkline: {
    //     enabled: true
    // }
  },
  plotOptions: {
    radialBar: {
      startAngle: -115,
      endAngle: 115,
      hollow: {
        margin: 0,
        size: "70%",
        background: "",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.14,
        },
      },
      track: {
        show: true,
        startAngle: -115,
        endAngle: 115,
        background: "#EFF1F2",
        strokeWidth: "100%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: false,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },

      dataLabels: {
        show: true,
        name: {
          offsetY: -5,
          show: true,
          color: "#000",
          fontSize: "17px",
        },
        value: {
          formatter: function (val) {
            return parseInt(val);
          },
          color: "#000",
          fontSize: "36px",
          show: false,
        },
      },
    },
  },
  colors: ["#152e4d"],
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#0891b2"],
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["lux"],
  noData: {
    text: "Loading...",
  },
};

const seriesHUM = [60];
var optionHUM = {
  chart: {
    offsetY: -5,
    offsetX: -40,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "70%",
        background: "",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.14,
        },
      },
      track: {
        show: true,
        background: "#EFF1F2",
        strokeWidth: "100%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: false,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.15,
        },
      },
      dataLabels: {
        show: true,
        name: {
          offsetY: 5,
          show: true,
          color: "#152e4d",
          fontSize: "17px",
        },
        value: {
          formatter: function (val) {
            return parseInt(val);
          },
          color: "#111",
          fontSize: "36px",
          show: false,
        },
      },
    },
  },
  colors: ["#0891b2"],
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#152e4d"],
      type: "horizontal",
      shade: "dark",
      shadeIntensity: 0.5,
      stops: [0, 100],
    },
  },
  stroke: {
    lineCap: "round",
  },
  labels: ["HUM"],
};

const seriesTEMP = [
  {
    name: "Now",
    data: [44],
  },
  {
    name: "",
    data: [13],
  },
];
var optionTEMP = {
  chart: {
    offsetY: -15,
    offsetX: -5,
    stacked: true,
    toolbar: {
      show: !1,
    },
    zoom: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      borderRadius: 12,
      columnWidth: "42%",
    },
  },
  dataLabels: {
    enabled: !1,
  },
  xaxis: {
    labels: {
      show: false,
    },
    categories: ["TEMP"],
  },
  yaxis: {
    labels: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  tooltip: {
    enabled: false,
  },
  colors: ["#0891b2", "#152e4d"],
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#0891b2", "#152e4d"],
      shade: "dark",
      type: "vertical",
      opacityTo: 0.7,
      stops: [0, 100],
    },
  },
};

const seriesWIND = [67];
var optionWIND = {
  chart: {
    offsetX: -48,
    offsetY: -5,
  },
  plotOptions: {
    radialBar: {
      track: {
        background: "#EFF1F2",
      },
      startAngle: -135,
      endAngle: 135,
      dataLabels: {
        name: {
          fontSize: "16px",
          color: undefined,
          offsetY: 120,
        },
        value: {
          show: false,
          offsetY: 76,
          fontSize: "22px",
          color: undefined,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    },
  },
  colors: ["#0891b2"],
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#152e4d"],
    },
  },
  stroke: {
    dashArray: 2,
  },
  labels: [""],
};

const seriesRainFall = [100];
var optionRainFall = {
  chart: {
    offsetX: -50,
    offsetY: -20,
  },
  plotOptions: {
    radialBar: {
      track: {
        background: "#EFF1F2",
      },
      hollow: {
        size: "40%",
      },
      dataLabels: {
        name: {
          fontSize: "16px",
          color: undefined,
          offsetY: 120,
        },
        value: {
          show: false,
          offsetY: 76,
          fontSize: "22px",
          color: undefined,
          formatter: function (val) {
            return val + "%";
          },
        },
      },
    },
  },
  colors: ["#0891b2"],
  fill: {
    type: "gradient",
    gradient: {
      gradientToColors: ["#152e4d"],
    },
  },
  // stroke: {
  // dashArray: 3,
  // },
  labels: [""],
};

const icon = new L.Icon({
  iconUrl: pointLand,
  iconRetinaUrl: pointLand,
  iconSize: new L.Point(20, 20),
  // className: 'leaflet-div-icon'
});

const iconSampling = new L.Icon({
  iconUrl: pointSampling,
  iconRetinaUrl: pointSampling,
  iconSize: new L.Point(25, 30),
  // className: 'leaflet-div-icon'
});

const iconCurrent = new L.Icon({
  iconUrl: pointCurrent,
  iconRetinaUrl: pointCurrent,
  iconSize: new L.Point(25, 31),
  // className: 'leaflet-div-icon'
});

const MonitoringDetail = () => {
  const { land_code_var } = useParams();

  return (
    <SocketProvider id={land_code_var}>
      <MonitoringApps />
    </SocketProvider>
  );
};

const MonitoringApps = () => {
  const [loader, showLoader] = useState(true);
  const [modalErr, showModalErr] = useState(false);
  const [messageErr, setMessageErr] = useState("");
  const [oldStatic, setOldStatic] = useState(null);
  const [latestStatic, setLatestStatic] = useState(null);
  const [dataMobile, setDataMobile] = useState([]);
  const [currentDataMobile, setCurrentDataMobile] = useState(null);
  const [arrDataTaking, setArrDataTaking] = useState([]);
  const [dataTaking, setDataTaking] = useState(0);
  const [dataLand, setDataLand] = useState(null);

  const { land_code_var } = useParams();
  const history = useHistory();
  const socket = useSocket();

  const wWidth = window.screen.width;
  const mapData =  dataMobile?.filter(v => v?.latitude_flo && v?.longitude_flo)

  useEffect(() => {
    async function fetchDataTaking() {
      const res = await getDataTaking(land_code_var);

      console.log("Get Data Taking :", res);
      if (res.data) {
        if (res.data.code === 0) {
          const lists = res.data.data
          
          setArrDataTaking(lists);
          setDataTaking(
            lists[lists.length - 1]?.date
          );
          showLoader(false);
        } else {
          setMessageErr(res.data.message);
          showModalErr(true);
          showLoader(false);
        }
      } else {
        alert("Connection timeout...");
        fetchDataTaking();
      }
    }

    fetchDataTaking();
  }, [land_code_var]);

  useEffect(() => {
    async function fetchDataMonitoring() {
      const res = await getDataMonitoringProbe(
        `land_code_var=${land_code_var}&date=${dataTaking}`
      );

      console.log("MONITORING :", res);
      if (res.data) {
        if (res.data.code === 0) {
          setLatestStatic(res.data.data.static_probe[0]);
          setOldStatic(res.data.data.static_probe[1]);

          setCurrentDataMobile(res.data.data.mobile_probe[0]);
          // setDataMobile(res.data.data.mobile_probe)
          setDataMobile(
            res.data.data.mobile_probe?.sort(
              (a, b) => parseInt(b.id_seq) - parseInt(a.id_seq)
            )
          );

          setDataLand(res.data.data.land);
          showLoader(false);
        } else {
          setMessageErr(res.data.message);
          showModalErr(true);
          showLoader(false);
        }
      } else {
        alert("Connection timeout...");
        fetchDataMonitoring();
      }
    }

    if (dataTaking !== "" && dataTaking !== null) {
      fetchDataMonitoring();
    }
  }, [land_code_var, dataTaking]);

  useEffect(() => {
    if (socket == null) return;

    socket.on("data-mobile", async (data) => {
      console.log(data);
      setCurrentDataMobile(data);
      const newArrData = [...dataMobile, data];
      const sortData = newArrData.sort(
        (b, a) =>
          moment(a.time_on_dtm, moment(a).creationData().format) -
          moment(b.time_on_dtm, moment(b).creationData().format)
      );

      setDataMobile(
        sortData.sort((a, b) => parseInt(b.id_seq) - parseInt(a.id_seq))
      );
    });

    return () => {
      socket.off("data-mobile");
    };
  }, [socket, dataMobile]);

  const getChangesDataStatic = (key) => {
    if (oldStatic && latestStatic) {
      const res = (
        parseFloat(latestStatic[key]) - parseFloat(oldStatic[key])
      ).toFixed(2);
      if (res >= 0) return "+" + res;
      return res;
    } else {
      return "+00";
    }
  };

  const handleClickMarker = async (e, data) => {
    console.log(e, data);
    const getMdpl = await axios.get(`https://api.open-elevation.com/api/v1/lookup?locations=${data?.latitude_flo},${data?.longitude_flo}`);
    console.log({getMdpl})
    data.mdpl = getMdpl.data.results[0].elevation || 0;

    setCurrentDataMobile(data);
  };
  
  const handleDeleteTrx = async (id_seq) => {
    const answer = window.confirm("Hapus data ini?")
    if (answer === false) {
      return
    }

    const res = await deleteTrxProbe(id_seq)

    console.log('Delete trx probe :', res)
    if(res.data){
        if(res.data.code === 0){
            alert("success delete data")
            window.location.reload()
        }else if(res.data.code === 99){
            history.replace('/auth')
        }else{
            setMessageErr(res.data.message)
            showLoader(false)
            showModalErr(true)
        }
    }else{
        alert(`${res.config?.url} ${res.message}`)
    }
  };
  

  const getZoom = (areaWidth) => {
    return 15

    const width = parseFloat(areaWidth);
    if (width < 3) {
      return 18;
    } else if (width < 20) {
      return 17;
    } else if (width < 60) {
      return 16;
    } else if (width < 210) {
      return 15;
    } else if (width < 750) {
      return 14;
    } else if (width < 2000) {
      return 13;
    } else if (width < 10000) {
      return 12;
    } else if (width < 75000) {
      return 11;
    } else if (width < 500000) {
      return 10;
    } else if (width < 1000000) {
      return 9;
    } else {
      return 8;
    }
  };

  const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});

  return (
    <div className="flex item-center flex-col p-6 mb-auto">
      <div className="flex items-center justify-between mb-5">
        <h1 className="text-base font-semibold">MONITORING</h1>
        <h2 className="text-base font-medium">
          Menu<span className="text-xs"> / </span>
          <span className="text-xs xs:text-sm">Monitoring</span>
        </h2>
      </div>

        <div className="flex flex-row gap-5">
          <button className='w-40 mb-5 border-2 border-green-700 text-green-700 bg-green_sidebar rounded-lg px-2 py-1.5 flex items-center -mr-2' onClick={() => toPDF()}>
            <span><FontAwesomeIcon icon={faArrowDown} /></span>
            <p className='ml-1.5'>Download PDF</p>
          </button>
          <div className="w-full mb-5 md:mb-0 font-medium flex flex-row">
            <select
              value={dataTaking}
              onChange={(e) => setDataTaking(e.target.value)}
              className="w-1/3 outline-none border-1 border-darkcayn px-5 py-2 mb-4 rounded-lg font-medium"
            >
              <option value="">Pengambilan Data</option>
              {arrDataTaking.length > 0 &&
                arrDataTaking.map((data, key) => (
                  <option key={key} value={data.date}>
                    Pengambilan {moment(data.date).format("DD MMMM YYYY")}
                  </option>
                ))}
            </select>
            {/* <div className="ml-5 w-52">
              <button
                className="bg-lightcayn hover:bg-darkcayn rounded-2xl px-4 py-3 text-white font-medium flex items-center"
                onClick={() =>
                  history.push(`/admin/lahan-recommendation/${land_code_var}`)
                }
              >
                <p className="pr-1.5">Lihat Rekomendasi</p>
                <span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </span>
              </button>
            </div> */}
          </div>
        </div>

        <div ref={targetRef}>
          <div className="flex flex-col lg:flex-row lg:space-x-3 space-y-5 mb-5">
            
            <div className="flex-1">
              <LandFertilizer
                date={dataTaking}
                landCode={land_code_var}
              />
            </div>
            <div className="flex-1">
              <div className="p-5 bg-white shadow-lg rounded-2xl font-medium mb-5 min-h-[500px]">
                <h1>Position of probe</h1>
                <div className="my-3">
                  {dataLand && mapData?.length > 0 && (
                    <MapContainer
                      // center={Helper.getCenterMapFromStr(dataLand?.land_coordinates_txt)} scrollWheelZoom={true}
                      center={{
                        lat: mapData?.at(0)?.latitude_flo,
                        lng: mapData?.at(0)?.longitude_flo,
                      }}
                      scrollWheelZoom={true}
                      zoom={getZoom(dataLand.area_var)}
                      className="w-full h-88 rounded-xl z-0"
                    >
                      <TileLayer
                        // attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom={20}
                        zoomOffset={-1}
                        id=""
                        tileSize={512}
                        attribution="by Agroobot"
                        // url="https://api.mapbox.com/styles/v1/harrywebss/cktwnhs380xs917nwskwmjjb8/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaGFycnl3ZWJzcyIsImEiOiJja2xrYWJ2ZXkzNmt3Mm90a3o1Y25vZmJlIn0.LDlgeP6gjiBvGWbmkG5sFA"
                      />
                      {dataLand.land_coordinates_txt.split(";").map((koor, key) => (
                        <Marker
                          position={[
                            isLatitude(koor.split(",")[0]) ? koor.split(",")[0] : 0,
                            isLongitude(koor.split(",")[1])
                              ? koor.split(",")[1]
                              : 0,
                          ]}
                          icon={icon}
                          key={key}
                        ></Marker>
                      ))}

                      {/* <Polygon
                        pathOptions={{ color: "lime" }}
                        positions={Helper.generatelatLngFromStr(
                          dataLand.land_coordinates_txt
                        )}
                      /> */}
                      {/* x, y */}
                      {/* <Marker position={[-6.6057710, 106.7503877]} icon={icon}></Marker> kiri bawah */}

                      {dataMobile &&
                        dataMobile?.filter(v => v?.latitude_flo && v?.longitude_flo)?.map((data, key) => (
                          // <Marker
                          //   position={[
                          //     data?.latitude_flo || 0,
                          //     data?.longitude_flo || 0,
                          //   ]}
                          //   icon={iconCurrent}
                          //   key={key}
                          //   onClick={() => alert('kliked data mobile')}
                          // ></Marker>
                            <Marker
                              position={[
                                data?.latitude_flo || 0,
                                data?.longitude_flo || 0,
                              ]}
                              icon={iconCurrent}
                              key={key}
                              eventHandlers={{
                                click: (e) => {
                                  handleClickMarker(e, data);
                                },
                              }}
                            >
                              <Popup>
                                <p>lat: {currentDataMobile?.latitude_flo}</p>
                                <p>lng: {currentDataMobile?.longitude_flo}</p>
                                <p>mdpl: {currentDataMobile?.mdpl}</p>
                              </Popup>
                            </Marker>
                        ))}
                    </MapContainer>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col-reverse lg:flex-row">
            {/* <div className="w-full h-full lg:w-1/2 md:mr-5"> */}
            <div className="w-full h-full lg:grid lg:grid-cols-2 gap-5">
              {dataMobile &&
                dataMobile.map((data, key) => (
                  <div className="mb-5 bg-white rounded-2xl shadow-lg">
                    <div className="bg-lightcayn text-white rounded-t-2xl px-5 py-3 font-medium flex justify-between">
                      <p>Sampling {dataMobile.length - key}</p>
                      <p>
                        {data?.time_on_dtm
                          ? moment(data?.time_on_dtm).format("DD-MM-YYYY HH:mm")
                          // ? moment.utc(
                          //   data?.time_on_dtm,
                          //   "YYYY-MM-DDTHH:mm:ss[Z]"
                          //   ).format("DD-MM-YYYY HH:mm")
                          : "-"}
                      </p>
                    </div>
                    <div className="grid grid-cols-3 lg:grid-cols-4 gap-1 pl-2 md:ml-10 py-5">
                      <div className="flex item-center">
                        <span className="rounded-full p-1 w-9 h-9 bg-soft flex items-center justify-center mr-2 lg:mr-4">
                          <FontAwesomeIcon icon={faAirFreshener} />
                        </span>
                        <div>
                          <h1 className="font-bold text-base md:text-xl">
                            {data?.n_value_flo || "00"}
                          </h1>
                          <p>N (Urea)</p>
                        </div>
                      </div>
                      <div className="flex item-center">
                        <span className="rounded-full p-1 w-9 h-9 bg-soft flex items-center justify-center mr-2 lg:mr-4">
                          <FontAwesomeIcon icon={faAirFreshener} />
                        </span>
                        <div>
                          <h1 className="font-bold text-base md:text-xl">
                            {data?.p_value_flo || "00"}
                          </h1>
                          <p>P (TSP)</p>
                        </div>
                      </div>
                      <div className="flex item-center">
                        <span className="rounded-full p-1 w-9 h-9 bg-soft flex items-center justify-center mr-2 lg:mr-4">
                          <FontAwesomeIcon icon={faAirFreshener} />
                        </span>
                        <div>
                          <h1 className="font-bold text-base md:text-xl">
                            {data?.k_value_flo || "00"}
                          </h1>
                          <p>K (KCI)</p>
                        </div>
                      </div>
                    </div>

                    <div className="grid grid-cols-3 lg:grid-cols-4 gap-1 pl-2 md:ml-10 pt-2 pb-5">
                      <div className="flex item-center ">
                        <span className="rounded-full p-1 w-9 h-9 bg-soft flex items-center justify-center mr-2 lg:mr-4">
                          <FontAwesomeIcon icon={faAirFreshener} />
                        </span>
                        <div>
                          <h1 className="font-bold text-base md:text-xl">
                            {data?.soil_humidity_flo || "00"}
                            <span className="text-sm">%</span>
                          </h1>
                          <p>Humidity</p>
                        </div>
                      </div>
                      <div className="flex item-center">
                        <span className="rounded-full p-1 w-9 h-9 bg-soft flex items-center justify-center mr-2 lg:mr-4">
                          <FontAwesomeIcon icon={faAirFreshener} />
                        </span>
                        <div>
                          <h1 className="font-bold text-base md:text-xl">
                            {data?.soil_temperature_flo || "00"}
                            <span className="text-sm">*C</span>
                          </h1>
                          <p>Suhu Tanah</p>
                        </div>
                      </div>
                      <div className="flex item-center">
                        <span className="rounded-full p-1 w-9 h-9 bg-soft flex items-center justify-center mr-2 lg:mr-4">
                          <FontAwesomeIcon icon={faAirFreshener} />
                        </span>
                        <div>
                          <h1 className="font-bold text-base md:text-xl">
                            {data?.ph_flo || "00"}
                          </h1>
                          <p>PH</p>
                        </div>
                      </div>
                      <div className="flex item-center">
                        <span className="rounded-full p-1 w-9 h-9 bg-soft flex items-center justify-center mr-2 lg:mr-4">
                          <FontAwesomeIcon icon={faAirFreshener} />
                        </span>
                        <div>
                          <h1 className="font-bold text-base md:text-xl">
                            {data?.ec_flo || "00"}
                          </h1>
                          <p>EC</p>
                        </div>
                      </div>
                    </div>

                    <div className="hidden px-5 py-5 justify-center items-center lg:flex">
                      <div className="text-red-500 cursor-pointer" onClick={() => handleDeleteTrx(data.id_seq)}>
                        delete
                      </div>
                    </div>
                  </div>
                ))}

              <div className="flex md:hidden items-end justify-end mt-5">
                {/* <button
                  className="bg-lightcayn hover:bg-darkcayn rounded-2xl px-3 py-2 text-white font-medium flex items-center"
                  onClick={() =>
                    history.push(`/admin/lahan-recommendation/${land_code_var}`)
                  }
                >
                  <p className="pr-1.5">Lihat Rekomendasi</p>
                  <span>
                    <FontAwesomeIcon icon={faArrowRight} />
                  </span>
                </button> */}
              </div>
            </div>

          </div>
        </div>

      {loader && <Loader />}
      {modalErr && (
        <ModalMessage
          message={messageErr}
          onClose={() => window.location.reload()}
        />
      )}
    </div>
  );
};

export default MonitoringDetail;
