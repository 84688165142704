import { faArrowAltCircleUp, faArrowRight, faEllipsisH, faUsers } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import ReactApexChart from "react-apexcharts"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { connect } from "react-redux"
import logo from '../assets/img/logo.svg'

var options = {
	chart: {
		height: 360, type: "bar", stacked: !0, 
        toolbar: { 
            show: !1 
        },
		zoom: { 
            enabled: !0 
        }
	},
    plotOptions: { 
        bar: { 
            horizontal: !1, columnWidth: "18%", endingShape: "rounded" 
        } 
    },
	dataLabels: { 
        enabled: !1 
    }, 
    xaxis: { 
        categories: ["Probolingga", "Ciomas", "Bengkulu A", "Bengkulu B", "Ciwidey", "Sukabumi"] 
    },
	colors: ["#0891b2", "#152e4d", "#34c38f"], 
    legend: { 
        position: "bottom" 
    }, 
    fill: { 
        opacity: 1 
    }
}
const series = [
    { 
        name: "Konvensional", 
        data: [44, 55, 41, 67, 22, 43,]
    }, 
    {
        name: "Agroobot",
        data: [25, 27, 25, 25, 21, 24]
    }
]

const dataTable = [
    {project_id: '#AG001', lokasi: 'Probolinggo', tanggal_project: '10 July 2021', luas_tanah: '10 Ha', total_probe: '1', status: 'Panen'},
    {project_id: '#AG002', lokasi: 'Ciomas', tanggal_project: '10 July 2021', luas_tanah: '10 Ha', total_probe: '1', status: 'Panen'},
    {project_id: '#AG003', lokasi: 'Bengkulu A', tanggal_project: '10 July 2021', luas_tanah: '10 Ha', total_probe: '1', status: 'Panen'},
    {project_id: '#AG004', lokasi: 'Bengkulu B', tanggal_project: '10 July 2021', luas_tanah: '10 Ha', total_probe: '1', status: 'Panen'},
    {project_id: '#AG005', lokasi: 'Ciwiday', tanggal_project: '10 July 2021', luas_tanah: '10 Ha', total_probe: '1', status: 'Belum'},
    {project_id: '#AG006', lokasi: 'Sukabumi', tanggal_project: '10 July 2021', luas_tanah: '10 Ha', total_probe: '1', status: 'Panen'}
]

const Dashboard = ({user}) => {
    return (
        <div className='flex flex-col p-6'>
            <div className='flex item-center flex-col'>
                {/* <FontAwesomeIcon icon={faTachometerAlt} size='lg' className='mr-2'/> */}
                <div className='flex items-center justify-between mb-5'>
                    <h1 className='text-base font-semibold'>DASHBOARD</h1>
                    <h2 className='text-base font-medium'>Menu<span className='text-xs'> / </span><span className='text-sm'>Dashboard</span></h2>
                </div>
                
                <div className='flex flex-col lg:flex-row mb-5'>

                    {/* CONTENT LEFT */}
                    <div className='flex flex-col lg:w-6/12 lg:mr-5'>
                        <div className='bg-white shadow-xl rounded-2xl mb-5'>
                            <div className='bg-darkcayn text-white p-5 pb-10 rounded-t-2xl'>
                                <h2 className='mb-1'>Welcome Back !</h2>
                                <p>{user.fullname_var}</p>
                            </div>
                            <div className='p-5 flex justify-between'>
                                <div>
                                    <div className='bg-header rounded-full p-1 w-16 h-16 -mt-12 mb-3'>
                                        <LazyLoadImage effect='blur' src={logo} />
                                    </div>
                                    <h2 className='my-2 font-medium'>Agroobot</h2>
                                    <p>Pertanian Digital</p>
                                </div>
                                <div>
                                    <h2 className='font-medium'>25</h2>
                                    <p className='my-2'>Pertanian Digital</p>
                                    <button className='bg-lightcayn text-white py-2 px-3 rounded-md'>
                                        Lihat Project <span><FontAwesomeIcon icon={faArrowRight} /></span>
                                    </button>
                                </div>
                                <div>
                                    <h2 className='font-medium'>90%</h2>
                                    <p className='mt-2'>Produktifitas</p>
                                </div>
                            </div>
                        </div>
                        <div className='p-5 bg-white shadow-xl rounded-2xl mb-5'>
                            <div className='flex items-center justify-between mb-3'>
                                <h2 className='font-semibold'>Subscribes</h2>
                                <span className='cursor-pointer'>
                                    <FontAwesomeIcon icon={faEllipsisH} />
                                </span>
                            </div>
                            <div className='flex item-center justify-between'>
                                <div className='flex flex-col'>
                                    <p>Total Subscribes</p>
                                    <h1 className='mb-3 font-semibold'>10,512</h1>
                                    <p>0.6% <span><FontAwesomeIcon icon={faArrowAltCircleUp} /></span></p>
                                </div>
                                <FontAwesomeIcon icon={faUsers} size='4x' className='opacity-30' />
                            </div>
                        </div>
                    </div>

                    {/* CONTENT RIGHT */}
                    <div className='flex flex-col md:w-full'>
                        <div className='flex flex-col md:flex-row items-center justify-between'>
                            <div className='w-full md:w-4/12 bg-white rounded-2xl shadow-xl p-5 flex justify-between mb-5'>
                                <div>
                                    <p className='mb-4'>Total Demplot</p>
                                    <h1 className='font-semibold'>15</h1>
                                </div>
                                <div className='rounded-full bg-lightcayn w-10 h-10'></div>
                            </div>
                            <div className='w-full md:w-4/12 bg-white rounded-2xl shadow-xl p-5 flex justify-between mx-5 mb-5'>
                                <div>
                                    <p className='mb-4'>Total Lahan</p>
                                    <h1 className='font-semibold'>1750 Ha</h1>
                                </div>
                                <div className='rounded-full bg-darkcayn w-10 h-10'></div>
                            </div>
                            <div className='w-full md:w-4/12 bg-white rounded-2xl shadow-xl p-5 flex justify-between mb-5'>
                                <div>
                                    <p className='mb-4'>Revenue</p>
                                    <h1 className='font-semibold'>Rp5.7 Milyar</h1>
                                </div>
                                <div className='rounded-full bg-darkcayn w-10 h-10'></div>
                            </div>
                        </div>
                        <div className='w-full bg-white rounded-2xl shadow-2xl p-5'>
                            <h1 className='font-semibold'>Produktifitas</h1>
                            <div >
                                <ReactApexChart 
                                    options={options}
                                    series={series}
                                    type='bar'
                                    // width='650'
                                />
                            </div>
                        </div>
                    </div>
                
                </div>

                {/* TABLE */}
                <div className='w-full bg-white rounded-2xl shadow-2xl p-5'>
                    <h1 className='font-semibold mb-5'>Project Terbaru</h1>
                    <div className="overflow-x-auto">
                        <table className="align-middle mb-0 w-175% md:w-full">
                            <thead className='bg-soft text-left'>
                                <tr>
                                    <th style={{width: '20px'}} className='pl-3'>
                                        <div>
                                            <input type="checkbox" id="transactionCheck01" readOnly={true} disabled={true} />
                                            <label htmlFor="transactionCheck01"></label>
                                        </div>
                                    </th>
                                    <th className="p-4">Project ID</th>
                                    <th>Lokasi</th>
                                    <th>Tanggal Project</th>
                                    <th>Luas Lahan</th>
                                    <th>Total Probe</th>
                                    <th>Status</th>
                                    <th>View Details</th>
                                </tr>
                            </thead>
                            <tbody>
                            {dataTable.map((data, key) => 
                                <tr key={key} className='border-b-2 border-soft'>
                                    <td style={{width: '20px'}} className='pl-3'>
                                        <div>
                                            <input type="checkbox" id={data.project_id} className='cursor-pointer'/>
                                            <label htmlFor={data.project_id}></label>
                                        </div>
                                    </td>
                                    <td className='p-4'>{data.project_id}</td>
                                    <td>{data.lokasi}</td>
                                    <td>{data.tanggal_project}</td>
                                    <td>{data.luas_tanah}</td>
                                    <td>{data.total_probe}</td>
                                    <td>
                                        <span className={`${data.status.toLowerCase()==='panen'? 'text-darkcayn bg-lightcayn':'text-lightcayn bg-red-500'}  bg-opacity-20 p-1 rounded-md`}>
                                                {data.status}
                                        </span>
                                    </td>
                                    <td>
                                        <button className='bg-lightcayn text-white px-2 py-1.5 rounded-2xl'>
                                            View Details
                                        </button>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null)(Dashboard)