import { useCallback, useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router"

import { createNewLandtypeRef, deleteLandtypeRef, getAllLandtypeRef, updateLandtypeRef } from "../../api/landtype-api"

import { ButtonAdd } from "../../components/button/CustomButton"
import { DropdownLand } from "../../components/dropdown/SimpleDropdown"
import TableUser from "../../components/tables/Table"

import Loader from "../../components/modal/Loader"
import ModalFormLandType from "../../components/modal/ModalFormLandType"
import ModalMessage from "../../components/modal/ModalMessage"

const LandType = ({user}) => {
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState(false)
    const [modalForm, showModalForm] = useState(false)
    const [dataLandType, setDataLandType] = useState([])
    const [filterData, setFilterData] = useState([])
    const [isUpdate, setIsUpdate] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    const history = useHistory()

    const fetchData = useCallback(async () => {
        showLoader(true)

        const res = await getAllLandtypeRef()
        
        console.log('Get LandType :', res)
        resetForm()
        if(res.data){
            if(res.data.code === 0){
                setDataLandType(res.data.data)
                setFilterData(res.data.data)
            }else if(res.data.code === 99){
                showLoader(true)
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }, [history])

    useEffect(() => {
        fetchData()
    }, [fetchData])


    const handleEdit = (selectedData) => {
        setSelectedData(selectedData)
        setIsUpdate(true)
        showModalForm(true)
    }

    const handleReceiveDataForm = async (payload) => {
        showLoader(true)
        
        let res = null
        if(!isUpdate){
            res = await createNewLandtypeRef(payload)
        }else{
            res = await updateLandtypeRef(selectedData.id_seq, payload)
        }

        console.log('Create/Update LandtypeRef :', res)
        
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else{
                setMessageErr(res.data.message)
                showModalErr(true)
                showLoader(false)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }


    const handleDeleteData = async (id_seq) => {
        showLoader(true)

        const res = await deleteLandtypeRef(id_seq)

        console.log('Get LandtypeRef :', res)
        if(res.data){
            if(res.data.code === 0){
                fetchData()
            }else if(res.data.code === 99){
                history.replace('/auth')
            }else{
                setMessageErr(res.data.message)
                showLoader(false)
                showModalErr(true)
            }
        }else{
            alert(`${res.config?.url} ${res.message}`)
        }
    }

    const resetForm = () => {
        showLoader(false)
        setIsUpdate(false)
        showModalForm(false)
        setSelectedData(null)
    }

    const handleSearch = (event) => {
        event.preventDefault()

        const newData = [...dataLandType]
        if(event.target.value){
            const filtered = newData.filter(item => {
                return (
                    item.name.toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.density_top.toString().toLowerCase().includes(event.target.value.toLowerCase()) ||
                    item.density_bottom.toString().toLowerCase().includes(event.target.value.toLowerCase())
                )
            });

            setFilterData(filtered)
        }else{
            setFilterData(dataLandType)
        }
    }

    const columns = [
        {
            Header: () => <div className='p-4 text-center'>Jenis Tanah</div>,
            Footer: 'Jenis Tanah',
            accessor: 'name',
            Cell: ({ value }) =>  <div className='pl-4'>{value}</div>,
        },
        {
            Header: 'Batas Atas (kg/dm3)',
            Footer: 'Batas Atas',
            accessor: 'density_top',
            Cell: ({value}) => Intl.NumberFormat("id-ID").format(value)

        },
        {
            Header: 'Batas Bawah (kg/dm3)',
            Footer: 'Batas Bawah',
            accessor: 'density_bottom',
            Cell: ({value}) => Intl.NumberFormat("id-ID").format(value)
      },
        {
            Header: 'Status',
            Footer: 'Status',
            accessor: 'status_int',
            Cell: ({value}) => (
                parseInt(value)===1? 
                <div className='bg-green_sidebar text-green-800 px-2 py-1 rounded-lg font-medium'>Active</div>
                :
                <div className='bg-red-100 text-red-800 px-2 py-1 rounded-lg font-medium'>Inactive</div>
            )
        },
        {
            Header: () => <div className='pr-1 text-left'>Action</div>,
            Footer: 'Action',
            accessor: 'action',
            Cell: ({row}) => {
                const data = row.original
                return <DropdownLand data={data} onEdit={() => handleEdit(data)} onDelete={handleDeleteData} />
            }
        }
    ]

    return (
        <div className='flex item-center flex-col p-6 mb-auto'>
            <div className='flex items-center justify-between'>
                <h1 className='text-base font-semibold'>LAND TYPE</h1>
                <h2 className='text-base font-medium text-right'>Admin<span className='text-xs'> / </span><span className='text-sm'>Land Type</span></h2>
            </div>

            <div className='w-full bg-white rounded-2xl shadow-2xl p-6 mt-6'>
                <div className='mb-5'>
                    <h1 className='font-semibold'>List Land Type</h1>
                </div>
                <div className='flex justify-between items-center mb-3'>
                    <ButtonAdd onClick={() => showModalForm(true)} />
                    <input type='search' onChange={handleSearch} placeholder='Cari Land Type' className='outline-none border-1 border-gray-300 rounded-2xl px-2 sm:px-3 py-2 focus:border-lightcayn focus:border-2' />
                </div>
                <TableUser dataTable={filterData} columnTable={columns} />
            </div>

            {modalForm && <ModalFormLandType data={selectedData} onCancel={resetForm} onSubmit={handleReceiveDataForm} />}
            {modalErr && <ModalMessage message={messageErr} onClose={()=>showModalErr(false)} />}
            {loader && <Loader />}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    }
}

export default connect(mapStateToProps, null) (LandType)