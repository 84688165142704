import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import OtpInput from "react-otp-input"
import { LazyLoadImage } from "react-lazy-load-image-component"

import { authRegister, authValidateOTP } from "../../api/common-api"

import Helper from "../../helpers/helper"
import Loader from "../../components/modal/Loader";
import ModalMessage from "../../components/modal/ModalMessage"
import logo from '../../assets/img/logo.svg'
import profile from '../../assets/img/verification-img.png'

const OtpPage = () => {
    const [otp, setOtp] = useState('')
    const [timeLeft, setTimeLeft] = useState(60);

    const [success, setSuccess] = useState(false)
    const [loader, showLoader] = useState(false)
    const [modalErr, showModalErr] = useState(false)
    const [messageErr, setMessageErr] = useState('')

    const history = useHistory()
    
    const phoneNumber = JSON.parse(localStorage.getItem('agroo-regist'))?.phone_number_int || '6281234567890'

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        // clear interval on re-render to avoid memory leaks
        return () => clearInterval(intervalId);
    }, [timeLeft]);

    
    const postDataOTP = useCallback(async () => {
        if(otp.length < 6){
            setMessageErr('Invalid verification code')
            showModalErr(true)
            return
        }
        showLoader(true)
        
        const dataRegist = await Helper.asyncLocalStorage.getItem('agroo-regist')
        const payload = {
            user: JSON.parse(dataRegist),
            otp: {
                otp_id: await Helper.asyncLocalStorage.getItem('agroo-otpid'),
                otp_code: otp
            }
        }

        const res = await authValidateOTP(payload)

        console.log(res)
        showLoader(false)

        if(res.data){
            if(res.data.code === 0){
                setSuccess(true)
                setTimeout(() => {
                    history.push('/auth')
                }, 4000);
            }else{
                setMessageErr(res.data.message)
                setSuccess(false)
                showModalErr(true)
                setOtp('')
            }
        }else{
            setMessageErr('Connection timeout...')
            setSuccess(false)
            showModalErr(true)
            setOtp('')
        }
    }, [history, otp])


    useEffect(() => {
        if(otp.length === 6){
            postDataOTP()
        }
    }, [history, otp, postDataOTP])


    const handleChange = (otp) => {
        setOtp(otp)
    }


    const handleResendOTP = () => {
        setTimeLeft(60)
        postResendOTP()
    }


    const postResendOTP = async () => {
        const dataRegist = await Helper.asyncLocalStorage.getItem('agroo-regist')
        const res = await authRegister(JSON.parse(dataRegist))

        console.log(res)
        showLoader(false)
        if(res.data){
            if(res.data.code === 0){
                localStorage.setItem('agroo-otpid', res.data.data?.otp?.data?.id)
            }else{
                setSuccess(false)
            }
        }else{
            setSuccess(false)
        }
    }

    const hashNumber = (phoneNumber) => {
        if(phoneNumber){
            if(phoneNumber.length === 13){
                return '****-****-' + phoneNumber.substring(phoneNumber.length-4, phoneNumber.length)
            }else if(phoneNumber.length === 12){
                return '****-****-' + phoneNumber.substring(phoneNumber.length-3, phoneNumber.length)
            }else{
                return '****-***-' + phoneNumber.substring(phoneNumber.length-3, phoneNumber.length)
            }
        }else{
            return ''
        }
    }

    return (
        <div className='w-11/12 md:w-1/3 2xl:w-1/4 py-10'>
            <div className='bg-white rounded shadow-xl'>
                <div className='bg-lightcayn bg-opacity-20 flex justify-between rounded-t w-full'>
                    <div className='p-5 rounded-t-2xl text-lightcayn mb-5'>
                        <h2 className='mb-1 font-medium'>Verification Data</h2>
                        <p className='text-sms'>Enter your verfication code to continue</p>
                    </div>
                    <div className='flex justify-end'>
                        <LazyLoadImage effect='blur' src={profile} className='h-28' />
                    </div>
                </div>
                <div className='bg-darkcayn rounded-full p-3 w-17 h-17 -mt-7 ml-5'>
                    <LazyLoadImage effect='blur' src={logo} />
                </div>
                <div className='p-6 flex flex-col items-center text-sms font-medium text-gray-600'>
                    <p className='text-center text-sms mb-8 w-9/12'>Verification code has been sent via WhatsApp to{' '}<span className='font-bold'>{hashNumber(phoneNumber)}</span></p>
                    <OtpInput 
                            onChange={handleChange}
                            value={otp}
                            numInputs={6}
                            isInputNum
                            shouldAutoFocus
                            // isInputSecure
                            containerStyle='flex justify-center'
                            inputStyle='outline-none text-3xl md:text-4xl border-b-2 border-darkcayn bg-transparent'
                            focusStyle='border-b-2 border-lightcayn'
                            separator={<span>&nbsp;&nbsp;</span>}
                        />
                    
                    {!success &&
                    <button disabled={otp.length<6?true:false}  onClick={postDataOTP}
                        className={`${otp.length<6? 'bg-gray-400': 'bg-lightcayn'} text-sms text-white mt-8 w-11/12 py-2.5 rounded`}
                    >
                        Verify
                    </button>
                    }
                    {success &&
                        <p className='text-sms mt-7 mb-2 text-center text-lightcayn'>Success verification, please login !</p>
                    }
                    {!success &&
                        <p className='text-sms mt-7 mb-2 text-center w-9/12'>Please wait for{' '}<span className='font-bold'>{timeLeft} seconds</span>{' '}to resend verification code</p>
                    }
                    {!success && timeLeft === 0 &&
                        <p className='text-lightcayn hover:text-darkcayn text-sms mt-2 mb-2 cursor-pointer' onClick={handleResendOTP}>Resend verification code</p>
                    }
                </div>
            </div>
            <div className='text-sms text-gray-600 flex flex-col items-center mt-7'>
                <p className='mt-3'>© {new Date().getFullYear()} Agroobot v2.0</p>
            </div>
            
            {loader && <Loader />}
            {modalErr && <ModalMessage message={messageErr} onClose={() => showModalErr(false)} />}
        </div>

        // <div className='w-11/12 md:w-6/12 rounded-2xl shadow-2xl flex flex-col items-center justify-center py-10 px-9 md:px-28 font-poppins'>
        //     <h1 className='font-bold text-lg md:text-xl mb-3'>Enter verification code</h1>
        //     <p className='text-center text-sm md:text-base mb-8'>Verification code has been sent via WhatsApp to{' '}<span className='font-bold'>{hashNumber(phoneNumber)}</span></p>
        //     <OtpInput 
        //         onChange={handleChange}
        //         value={otp}
        //         numInputs={6}
        //         isInputNum
        //         shouldAutoFocus
        //         // isInputSecure
        //         containerStyle='flex justify-center'
        //         inputStyle='outline-none text-3xl md:text-4xl border-b-2 border-darkcayn bg-transparent'
        //         focusStyle='border-b-2 border-lightcayn'
        //         separator={<span>&nbsp;&nbsp;</span>}
        //     />
            // {!success &&
            // <button disabled={otp.length<6?true:false}  onClick={postDataOTP}
            //     className={`${otp.length<6? 'bg-gray-500': 'bg-lightcayn'} text-base md:text-lg text-white mt-8 w-full py-2 rounded-lg`}
            // >
            //     Verify
            // </button>
            // }
            // {success &&
            //     <p className='text-sm md:text-base mt-5 text-center text-lightcayn'>Success verification, please login !</p>
            // }
            // {!success &&
            //     <p className='text-sm md:text-base mt-5 text-center'>Please wait for{' '}<span className='font-bold'>{timeLeft} seconds</span>{' '}to resend verification code</p>
            // }
            // {!success && timeLeft === 0 &&
            //     <p className='text-lightcayn hover:text-darkcayn text-sm md:text-base mt-4 cursor-pointer' onClick={handleResendOTP}>Resend verification vode</p>
            // }
        // </div>
    )
}

export default OtpPage