import { faViruses } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const VirusControl = ({commodityName, data}) => {
    return (
        <div className='bg-white px-3 py-5 md:p-5 rounded-2xl shadow-lg'>
            <div className='flex'>
                <span className='bg-green_sidebar text-green-600 rounded-full flex justify-center items-center p-2 w-10 h-10 text-base'>
                    <FontAwesomeIcon icon={faViruses} />
                </span>
                <div className='text-left ml-2.5'>
                    <p className='font-semibold'>Kendali Virus</p>
                    <p>{commodityName || '-'}</p>
                </div>
            </div>
            
            <div className='my-5 bg-gray-200' style={{height: 1}}></div>

            <div className='px-4 py-1'>
                {data?
                <p className='leading-relaxed'>{data}.</p>
                :
                <p>Data kendali virus belum tersedia</p>
                }
            </div>

        </div>
    )
}

export default VirusControl